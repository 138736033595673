import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'quiz-progressbar',
  templateUrl: './quiz-progressbar.component.html',
  styleUrls: ['./quiz-progressbar.component.css']
})
export class QuizProgressbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
