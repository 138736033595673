export const QUIZ_GRADE_SPECIAL = {
    "id": 237020,
    "directionmodeid": 1,
    "name": "Экзамен на месте 1",
    "takerfio": "Петрова Анна Александровна",
    "autorid": 4183,
    "description": "Плановая",
    "questions": [
        {
            "id": 7084,
            "name": "При попадании слезоточивых и раздражающих веществ на кожу следует:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78378,
                    "questionId": 7084,
                    "name": "Промокнуть сухой ветошью.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78379,
                    "questionId": 7084,
                    "name": "Промыть кожу холодной водой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78380,
                    "questionId": 7084,
                    "name": "Протереть последовательно тремя тампонами - с 40% раствором этилового спирта, с 3% раствором бикарбоната натрия (соды), с мыльным раствором.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7151,
            "name": "Назначение кольцевой проточки гильзы 9-мм патрона к пистолету ИЖ-71.",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78579,
                    "questionId": 7151,
                    "name": "Для зацепа выбрасывателя.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78580,
                    "questionId": 7151,
                    "name": "Для зацепа отражателя.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78581,
                    "questionId": 7151,
                    "name": "Для уменьшения веса.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6988,
            "name": "Какое из перечисленных ниже изделий относится к оружию?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78090,
                    "questionId": 6988,
                    "name": "Бейсбольная бита.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78091,
                    "questionId": 6988,
                    "name": "Кухонный нож для разделки мяса.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78092,
                    "questionId": 6988,
                    "name": "Пневматический пистолет с дульной энергией 5 Дж.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7128,
            "name": "В каком случае работники юридического лица с особыми уставными задачами вправе привести в готовность к стрельбе огнестрельное оружие?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78510,
                    "questionId": 7128,
                    "name": "Если считают, что в создавшейся обстановке могут возникнуть основания для его применения.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78511,
                    "questionId": 7128,
                    "name": "При охране материальных ценностей и служебной документации конфиденциального характера.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78512,
                    "questionId": 7128,
                    "name": "При патрулировании охраняемого периметра объекта в ночное время.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7161,
            "name": "Какова емкость магазина пистолета ПМ-9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78609,
                    "questionId": 7161,
                    "name": "10 патронов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78610,
                    "questionId": 7161,
                    "name": "16 патронов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78611,
                    "questionId": 7161,
                    "name": "8 патронов.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7052,
            "name": "Тактика действий при наличии на траектории стрельбы третьих лиц, не участвующих в нападении:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78282,
                    "questionId": 7052,
                    "name": "Обороняющийся не отвлекается на действия, направленные на обеспечение безопасности третьих лиц.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78283,
                    "questionId": 7052,
                    "name": "Обороняющийся перемещается в направлении (влево, вправо, вниз или вверх), обеспечивающем безопасную для третьих лиц траекторию выстрела.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78284,
                    "questionId": 7052,
                    "name": "Обороняющийся, сам оставаясь в неподвижности, ожидает изменения ситуации (подавая третьим лицам соответствующие команды голосом).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7091,
            "name": "Как проверяется пульс при бессознательном состоянии пострадавшего и при травмах?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78399,
                    "questionId": 7091,
                    "name": "Приложив ухо к груди прослушивается сердцебиение.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78400,
                    "questionId": 7091,
                    "name": "Пульс проверяется на запястье.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78401,
                    "questionId": 7091,
                    "name": "Пульс проверяется на сонной артерии.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7146,
            "name": "Неполная разборка пистолета, для пистолетов по конструкции сходных с пистолетом ИЖ-71 (МР-71), производится в следующем порядке:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78564,
                    "questionId": 7146,
                    "name": "Выключить предохранитель, отвести спусковую скобу вниз и влево, отделить затвор от рамки, поставить спусковую скобу на место, снять со ствола возвратную пружину.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78565,
                    "questionId": 7146,
                    "name": "Извлечь магазин из основания рукоятки, выключить предохранитель, убедится в отсутствии патрона в патроннике, отвести спусковую скобу вниз и влево, отделить затвор от рамки, поставить спусковую скобу на место, снять со ствола возвратную пружину.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78566,
                    "questionId": 7146,
                    "name": "Отделить затвор, отвинтить винт рукоятки, отделить рукоятку от рамки, снять возвратную пружину.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7065,
            "name": "Вторым действием (вторым этапом) при оказании первой помощи является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78321,
                    "questionId": 7065,
                    "name": "Правильная транспортировка пострадавшего (производимая с учетом обстановки на месте происшествия, состояния и характера повреждений пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78322,
                    "questionId": 7065,
                    "name": "Предотвращение возможных осложнений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78323,
                    "questionId": 7065,
                    "name": "Устранение состояния, угрожающего жизни и здоровью пострадавшего.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7136,
            "name": "Линией прицеливания называется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78534,
                    "questionId": 7136,
                    "name": "Линия, описываемая центром тяжести пули в полете.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78535,
                    "questionId": 7136,
                    "name": "Линия, проходящая от центра ствола в точку прицеливания.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78536,
                    "questionId": 7136,
                    "name": "Прямая линия, проходящая от глаза стрелка через середину прорези прицела (на уровне с ее краями) и вершину мушки в точку прицеливания.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7123,
            "name": "Какова последовательность действий работника юридического лица с особыми уставными задачами и порядок уведомления должностных лиц о случаях применения им специальных средств или огнестрельного оружия, в результате чего граждане получили телесные повреждения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78495,
                    "questionId": 7123,
                    "name": "Немедленно уведомить прокурора и в возможно короткий срок органы здравоохранения и внутренних дел.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78496,
                    "questionId": 7123,
                    "name": "Обеспечить лицам, получившим телесные повреждения в результате применения специальных средств или огнестрельного оружия, первую помощь. Доложить по подчиненности.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78497,
                    "questionId": 7123,
                    "name": "Обеспечить лицам, получившим телесные повреждения\r\nв результате применения специальных средств или огнестрельного оружия, первую помощь. Незамедлительно уведомить руководство охраняемого объекта и территориальное подразделение МЧС.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7035,
            "name": "Действия обороняющегося лица, если это лицо вследствие неожиданности посягательства не могло объективно оценить степень и характер опасности нападения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78231,
                    "questionId": 7035,
                    "name": "Квалифицируются как преступление, совершенное по неосторожности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78232,
                    "questionId": 7035,
                    "name": "Не являются превышением пределов необходимой обороны.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78233,
                    "questionId": 7035,
                    "name": "Считаются превышением пределов необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7023,
            "name": "Запрещается ли на территории Российской Федерации оборот кистеней, кастетов, сурикенов, бумерангов и других специально приспособленных для использования в качестве оружия предметов ударно-дробящего и метательного действия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78195,
                    "questionId": 7023,
                    "name": "На территории Российской Федерации оборот кистеней, кастетов, сурикенов, бумерангов и других, специально приспособленных для использования в качестве оружия предметов ударно-дробящего и метательного действия, допускается только при условии использования перечисленных предметов работниками юридического лица с особыми уставными задачами при исполнении ими служебных обязанностей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78196,
                    "questionId": 7023,
                    "name": "На территории Российской Федерации оборот кистеней, кастетов, сурикенов, бумерангов и других, специально приспособленных для использования в качестве оружия предметов ударно-дробящего и метательного действия, допускается, если перечисленные предметы используются в качестве гражданского и служебного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78197,
                    "questionId": 7023,
                    "name": "На территории Российской Федерации оборот кистеней, кастетов, сурикенов, бумерангов и других, специально приспособленных для использования в качестве оружия предметов ударно-дробящего и метательного действия, за исключением спортивных снарядов, запрещен.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7077,
            "name": "Действия по помощи пострадавшему при попадании инородного тела в дыхательные пути:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78357,
                    "questionId": 7077,
                    "name": "Нагнуть туловище пострадавшего вперед, нанести несколько интенсивных ударов ладонью между лопаток, при отсутствии эффекта -  провести прием «Хемлика».",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78358,
                    "questionId": 7077,
                    "name": "Нанести пострадавшему, стоящему прямо, несколько интенсивных ударов ладонью между лопаток.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78359,
                    "questionId": 7077,
                    "name": "Положить пострадавшего на бок и вызвать интенсивную рвоту.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7062,
            "name": "Входят ли в состав аптечки первой помощи медицинские препараты?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78312,
                    "questionId": 7062,
                    "name": "Входят медицинские препараты, отпускаемые в аптеках без рецепта (йод, нашатырный спирт, валидол, нитроглицерин и т.п.), а также препараты для проведения комплексной противошоковой терапии (кордиамин, дексаметазон, кеторолака трометамин или баралгин и т.п.). ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78313,
                    "questionId": 7062,
                    "name": "Входят медицинские препараты, отпускаемые в аптеках без рецепта (йод, нашатырный спирт, валидол, нитроглицерин и т.п.).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78314,
                    "questionId": 7062,
                    "name": "Не входят.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6993,
            "name": "Какое из перечисленных ниже изделий не относится к гражданскому оружию:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78105,
                    "questionId": 6993,
                    "name": "Механические распылители, снаряженные слезоточивыми или раздражающими веществами.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78106,
                    "questionId": 6993,
                    "name": "Палка резиновая.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78107,
                    "questionId": 6993,
                    "name": "Электрошоковое устройство отечественного производства.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6987,
            "name": "Какое из перечисленных ниже изделий не относится к оружию?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78087,
                    "questionId": 6987,
                    "name": "Пневматический пистолет.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78088,
                    "questionId": 6987,
                    "name": "Стартовый пистолет.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78089,
                    "questionId": 6987,
                    "name": "Топор.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6981,
            "name": "В соответствии с действующим законодательством огнестрельное оружие ограниченного поражения это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78069,
                    "questionId": 6981,
                    "name": "Короткоствольное оружие и бесствольное оружие, предназначенные для механического поражения живой цели на расстоянии метаемым снаряжением патрона травматического действия, получающим направленное движение за счет энергии порохового или иного заряда, и не предназначенные для причинения смерти человеку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78070,
                    "questionId": 6981,
                    "name": "Оружие, не предназначенное для причинения смерти человеку, в котором направленное движение метаемому травматическому патрону придается за счет энергии порохового заряда.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78071,
                    "questionId": 6981,
                    "name": "Устройство, предназначенное для механического поражения цели на коротких дистанциях патроном травматического действия, не предназначенным для причинения смерти человеку.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7024,
            "name": "Какое оружие могут иметь при себе граждане Российской Федерации, участвующие в проведении митингов, уличных шествий, демонстраций, пикетирования и других массовых публичных мероприятий?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78198,
                    "questionId": 7024,
                    "name": "При проведении митингов, уличных шествий, демонстраций, пикетирования и других массовых публичных мероприятий граждане могут иметь при себе гражданское или холодное оружие.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78199,
                    "questionId": 7024,
                    "name": "При проведении митингов, уличных шествий, демонстраций, пикетирования и других массовых публичных мероприятий граждане могут иметь при себе только холодное оружие.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78200,
                    "questionId": 7024,
                    "name": "При проведении митингов, уличных шествий, демонстраций, пикетирования и других массовых публичных мероприятий ношение гражданами оружия запрещено.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6985,
            "name": "В соответствии с действующим законодательством сигнальный патрон это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78081,
                    "questionId": 6985,
                    "name": "Устройство, предназначенное для выстрела из огнестрельного гладкоствольного оружия, огнестрельного оружия ограниченного поражения, газового или сигнального оружия, объединяющее в одно целое при помощи гильзы средства инициирования, метательный заряд и метаемое снаряжение травматического действия,  дымового или звукового сигнала и не предназначенное для причинения смерти человеку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78082,
                    "questionId": 6985,
                    "name": "Устройство, предназначенное для выстрела из огнестрельного оружия или сигнального оружия, объединяющее в одно целое при помощи гильзы средства инициирования, метательный заряд и метаемое снаряжение для подачи светового, дымового или звукового сигнала и не предназначенное для поражения живой или иной цели.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78083,
                    "questionId": 6985,
                    "name": "Устройство, предназначенное для выстрела из огнестрельного оружия ограниченного поражения, объединяющее в одно целое при помощи гильзы средства инициирования, метательный заряд и метаемое снаряжение для подачи светового или звукового сигнала и не предназначенное для поражения живой или иной цели.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7009,
            "name": "Юридические лица с особыми уставными задачами могут продавать находящееся у них на законных основаниях гражданское и служебное оружие и патроны к нему:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78153,
                    "questionId": 7009,
                    "name": "Государственным военизированным организациям.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78154,
                    "questionId": 7009,
                    "name": "Гражданам, имеющим лицензию на приобретение оружия, с предварительным уведомлением об этом органов внутренних дел по месту учета указанного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78155,
                    "questionId": 7009,
                    "name": "Юридическим лицам, имеющим лицензию на торговлю гражданским и служебным оружием, с предварительным уведомлением об этом федерального органа исполнительной власти, уполномоченного в сфере оборота оружия, или его территориального органа по месту учета указанного оружия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7102,
            "name": "В каких случаях работнику юридического лица с особыми уставными задачами не запрещается применять специальные средства в отношении женщин с видимыми признаками беременности, лиц с явными признаками инвалидности и малолетних?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78432,
                    "questionId": 7102,
                    "name": "В случае оказания указанными лицами вооруженного сопротивления, совершения нападения, угрожающего жизни или здоровью работников юридического лица с особыми уставными задачами, либо лиц, находящихся на охраняемом объекте.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78433,
                    "questionId": 7102,
                    "name": "В случае отказа указанными лицами подчиниться требованию работника юридического лица с особыми уставными задачами проследовать в помещение охраны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78434,
                    "questionId": 7102,
                    "name": "В случаях оказания указанными лицами группового сопротивления или нападения, угрожающего жизни и здоровью работников юридического лица с особыми уставными задачами или охраняемому имуществу.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7059,
            "name": "При выявлении условий, способствующих хищениям имущества, работник юридического лица с особыми уставными задачами имеет право:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78303,
                    "questionId": 7059,
                    "name": "Давать обязательные для исполнения указания материально-ответственным лицам охраняемого объекта по устранению выявленных нарушений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78304,
                    "questionId": 7059,
                    "name": "Давать рекомендации руководству охраняемого объекта по ликвидации указанных условий.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78305,
                    "questionId": 7059,
                    "name": "Принимать меры по пресечению указанных нарушений и ликвидации указанных условий.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7011,
            "name": "Относится ли ствольная коробка и рамка к основным частям огнестрельного оружия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78159,
                    "questionId": 7011,
                    "name": "К основным частям огнестрельного оружия относится только ствольная коробка.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78160,
                    "questionId": 7011,
                    "name": "Ствольная коробка и рамка к основным частям огнестрельного оружия не относятся.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78161,
                    "questionId": 7011,
                    "name": "Ствольная коробка и рамка являются основным частям огнестрельного оружия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7036,
            "name": "К уголовно наказуемым деяниям при превышении пределов необходимой обороны относится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78234,
                    "questionId": 7036,
                    "name": "Причинение тяжкого вреда здоровью по неосторожности, совершенное при превышении пределов необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78235,
                    "questionId": 7036,
                    "name": "Умышленное причинение средней тяжести вреда здоровью, совершенное при превышении пределов необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78236,
                    "questionId": 7036,
                    "name": "Умышленное причинение тяжкого вреда здоровью, совершенное при превышении пределов необходимой обороны.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7061,
            "name": "Каков порядок действий при встрече медицинских работников, прибывающих по вызову?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78309,
                    "questionId": 7061,
                    "name": "Направить кого-нибудь встречать «Скорую помощь», при сложном маршруте обозначить его дополнительными опознавательными знаками, самому ожидать у места происшествия и оказывать первую помощь.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78310,
                    "questionId": 7061,
                    "name": "Направить кого-нибудь встречать «Скорую помощь», самому ожидать у места происшествия и оказывать первую помощь.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78311,
                    "questionId": 7061,
                    "name": "Ожидать встречи «Скорой помощи» на месте происшествия, объясняя по телефону диспетчеру «03», как поехать к месту происшествия.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7154,
            "name": "Элементом какой основной части или механизма является выбрасыватель в ПМ- 9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78588,
                    "questionId": 7154,
                    "name": "Затвора.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78589,
                    "questionId": 7154,
                    "name": "Рамки со стволом и спусковой скобой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78590,
                    "questionId": 7154,
                    "name": "Ударно-спускового механизма.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7029,
            "name": "По истечении скольких лет после аннулирования разрешения на хранение и ношение оружия возможно повторное обращение за его получением?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78213,
                    "questionId": 7029,
                    "name": "Одного года со дня их аннулирования.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78214,
                    "questionId": 7029,
                    "name": "Пяти лет со дня их аннулирования.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78215,
                    "questionId": 7029,
                    "name": "Трех лет со дня их аннулирования.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7189,
            "name": "При необходимости перемещения по тиру или стрельбищу (осмотр мишеней и т.п.) в соответствии с мерами по обеспечению безопасности оружие должно находиться:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78690,
                    "questionId": 7189,
                    "name": "В кобуре стрелка или на столике стрелка - в разряженном или поставленном на предохранитель виде.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78691,
                    "questionId": 7189,
                    "name": "Какие-либо правила на этот счет отсутствуют.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78692,
                    "questionId": 7189,
                    "name": "Непосредственно в руках стрелка.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7068,
            "name": "Если пострадавший находится без сознания, в какое положение до прибытия скорой помощи он должен быть переведен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78330,
                    "questionId": 7068,
                    "name": "В положении на спине.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78331,
                    "questionId": 7068,
                    "name": "В положении полусидя.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78332,
                    "questionId": 7068,
                    "name": "В устойчивое боковое положение.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6979,
            "name": "В соответствии с действующим законодательством сигнальное оружие это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78063,
                    "questionId": 6979,
                    "name": "Оружие, конструктивно предназначенное только для подачи световых, дымовых или звуковых сигналов.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78064,
                    "questionId": 6979,
                    "name": "Предметы вооружения и метаемое снаряжение, предназначенные для создания световых, дымовых или звуковых эффектов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78065,
                    "questionId": 6979,
                    "name": "Устройство, конструктивно предназначенное для подачи сигналов бедствия в виде звука, или света.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7064,
            "name": "Первым действием (первым этапом) при оказании первой помощи является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78318,
                    "questionId": 7064,
                    "name": "Правильная транспортировка пострадавшего (производимая с учетом обстановки на месте происшествия, состояния и характера повреждений пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78319,
                    "questionId": 7064,
                    "name": "Предотвращение возможных осложнений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78320,
                    "questionId": 7064,
                    "name": "Прекращение воздействия травмирующего фактора.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7134,
            "name": "Как определяется термин «длинноствольное оружие» в соответствии с действующим государственным стандартом?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78528,
                    "questionId": 7134,
                    "name": "Оружие с длиной ствола (стволов) более 150 мм и общей длиной более 300 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78529,
                    "questionId": 7134,
                    "name": "Оружие с длиной ствола (стволов) более 200 мм и общей длиной более 400 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78530,
                    "questionId": 7134,
                    "name": "Оружие с длиной ствола (стволов) более 300 мм и общей длиной более 600 мм.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7164,
            "name": "Какова боевая скорострельность очередями ПП-91 «Кедр»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78618,
                    "questionId": 7164,
                    "name": "100 выстрелов в минуту.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78619,
                    "questionId": 7164,
                    "name": "1000 выстрелов в минуту.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78620,
                    "questionId": 7164,
                    "name": "50 выстрелов в минуту.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7021,
            "name": "Запрещается ли на территории Российской Федерации установка на гражданском и служебном оружии приспособлений для бесшумной стрельбы?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78189,
                    "questionId": 7021,
                    "name": "На территории Российской Федерации установка приспособлений для бесшумной стрельбы разрешена только на служебном оружии.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78190,
                    "questionId": 7021,
                    "name": "Установка на гражданском и служебном оружии приспособлений для бесшумной стрельбы допускается при наличии соответствующих разрешений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78191,
                    "questionId": 7021,
                    "name": "Установка на гражданском и служебном оружии приспособлений для бесшумной стрельбы запрещена.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7041,
            "name": "Подлежит ли возмещению вред, причиненный посягающему лицу в состоянии необходимой обороны, если при этом не было допущено превышения пределов необходимой обороны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78249,
                    "questionId": 7041,
                    "name": "Да, подлежит.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78250,
                    "questionId": 7041,
                    "name": "Не подлежит.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78251,
                    "questionId": 7041,
                    "name": "Подлежит частично на основании судебного решения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7069,
            "name": "Если пострадавший находится в сознании и задыхается, или у него имеется ранение грудной клетки, в какое положение до прибытия скорой помощи он должен быть переведен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78333,
                    "questionId": 7069,
                    "name": "В положении на спине.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78334,
                    "questionId": 7069,
                    "name": "В положении полусидя.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78335,
                    "questionId": 7069,
                    "name": "В устойчивое боковое положение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7140,
            "name": "По своему назначению шептало пистолета служит:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78546,
                    "questionId": 7140,
                    "name": "Для возвращения спускового крючка в крайнее переднее положение.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78547,
                    "questionId": 7140,
                    "name": "Для приведения в действие курка, рычага взвода и спусковой тяги.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78548,
                    "questionId": 7140,
                    "name": "Для удержания курка на боевом и предохранительном взводе.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7159,
            "name": "На чем основан принцип автоматики пистолета ПМ-9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78603,
                    "questionId": 7159,
                    "name": "На особенностях работы ударно-спускового механизма.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78604,
                    "questionId": 7159,
                    "name": "На отдаче свободного затвора.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78605,
                    "questionId": 7159,
                    "name": "На сжатии пороховых газов.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7137,
            "name": "Прямым выстрелом называется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78537,
                    "questionId": 7137,
                    "name": "Выстрел, при котором ствол оружия и линия плеч стрелка составляют прямой угол.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78538,
                    "questionId": 7137,
                    "name": "Выстрел, при котором траектория полета пули не поднимается над линией прицеливания выше цели на всем своем протяжении.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78539,
                    "questionId": 7137,
                    "name": "Выстрел, при котором траектория полета пули поднимается над линией прицеливания выше цели не более, чем на одной трети своего протяжения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7015,
            "name": "Должно ли гражданское огнестрельное оружие соответствовать следующим требованиям - исключать ведение огня очередями и иметь емкость магазина (барабана) не более 10 патронов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78171,
                    "questionId": 7015,
                    "name": "В случае использования гражданского оружия в качестве служебного оружия из него допускается ведение огня очередями.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78172,
                    "questionId": 7015,
                    "name": "Гражданское оружие должно соответствовать указанным в вопросе требованиям.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78173,
                    "questionId": 7015,
                    "name": "Допускается емкость магазина более 10 патронов, если гражданское оружие используется как служебное оружие.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7066,
            "name": "Третьим действием (третьим этапом) при оказании первой помощи является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78324,
                    "questionId": 7066,
                    "name": "Правильная транспортировка пострадавшего (производимая с учетом обстановки на месте происшествия, состояния и характера повреждений пострадавшего).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78325,
                    "questionId": 7066,
                    "name": "Предотвращение возможных осложнений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78326,
                    "questionId": 7066,
                    "name": "Прекращение воздействия травмирующего фактора.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7093,
            "name": "В каком объеме проводятся мероприятия при прекращении сердечной деятельности и дыхания у пострадавшего? ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78405,
                    "questionId": 7093,
                    "name": "Освобождение дыхательных путей, проведение ИВЛ (искусственной вентиляции легких) и НМС (непрямого массажа сердца).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78406,
                    "questionId": 7093,
                    "name": "Освобождение дыхательных путей, проведение ИВЛ (искусственной вентиляции легких).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78407,
                    "questionId": 7093,
                    "name": "Проведение НМС (непрямого массажа сердца).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6999,
            "name": "При каких условиях запрещён оборот гражданского оружия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78123,
                    "questionId": 6999,
                    "name": "Если емкость магазина (барабана) менее 10 патронов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78124,
                    "questionId": 6999,
                    "name": "Если общая длина оружия более 800 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78125,
                    "questionId": 6999,
                    "name": "Если огнестрельное оружие имеет форму, имитирующую другие предметы.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7183,
            "name": "Безопасное использование оружия предполагает в период непосредственного применения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78672,
                    "questionId": 7183,
                    "name": "Не ставить оружие на предохранитель после досылки патрона в патронник, даже если оружие не применяется сразу после досылки патрона.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78673,
                    "questionId": 7183,
                    "name": "Ни при каких обстоятельствах не ставить оружие на предохранитель.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78674,
                    "questionId": 7183,
                    "name": "Ставить оружие на предохранитель после досылки патрона в патронник, если оружие не применяется сразу после досылки патрона.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7163,
            "name": "Какова прицельная дальность стрельбы из ПП-91 «Кедр»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78615,
                    "questionId": 7163,
                    "name": "100 метров.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78616,
                    "questionId": 7163,
                    "name": "25 метров.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78617,
                    "questionId": 7163,
                    "name": "50 метров.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7043,
            "name": "Превышением мер, необходимых для задержания лица, совершившего преступление, признается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78255,
                    "questionId": 7043,
                    "name": "Причинение вреда здоровью при задержании лица, совершившего преступление.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78256,
                    "questionId": 7043,
                    "name": "Причинение смерти по неосторожности при задержании лица, совершившего преступление.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78257,
                    "questionId": 7043,
                    "name": "Явное несоответствие характеру и степени общественной опасности совершенного задерживаемым лицом преступления и обстоятельствам задержания, когда лицу без необходимости причиняется явно чрезмерный, не вызываемый обстановкой вред.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7126,
            "name": "В отношении кого работникам юридического лица с особыми уставными задачами запрещается применять огнестрельное оружие?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78504,
                    "questionId": 7126,
                    "name": "В отношении граждан, имеющих документ, подтверждающий наличие инвалидности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78505,
                    "questionId": 7126,
                    "name": "В отношении детей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78506,
                    "questionId": 7126,
                    "name": "В отношении женщин, лиц с явными признаками инвалидности и несовершеннолетних, когда их возраст очевиден или известен охраннику.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7196,
            "name": "Действия с пистолетом при получении стрелком в тире (на стрельбище) команды «Оружие – к осмотру»:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78711,
                    "questionId": 7196,
                    "name": "Извлечь магазин, предъявить оружие к осмотру (в положении «на затворной задержке») с приложенным сбоку (под большой палец правой руки) магазином. После осмотра оружия руководителем стрельбы (по команде «осмотрено») поставить оружие на предохранитель; магазин вставить в основание рукоятки, пистолет вложить в кобуру.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78712,
                    "questionId": 7196,
                    "name": "Извлечь магазин, предъявить оружие к осмотру (в положении «на затворной задержке») с приложенным сбоку (под большой палец правой руки) магазином. После осмотра оружия руководителем стрельбы (по команде «осмотрено») снять с затворной задержки, произвести контрольный спуск курка, поставить оружие на предохранитель.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78713,
                    "questionId": 7196,
                    "name": "Извлечь магазин, предъявить оружие к осмотру (в положении «на затворной задержке») с приложенным сбоку (под большой палец правой руки) магазином. После осмотра оружия руководителем стрельбы (по команде «осмотрено») снять с затворной задержки, произвести контрольный спуск курка, поставить оружие на предохранитель; магазин вставить в основание рукоятки, пистолет вложить в кобуру.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7076,
            "name": "При вынужденном длительном наложении кровоостанавливающий жгут необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78354,
                    "questionId": 7076,
                    "name": "Периодически ослаблять, и затем переносить ниже прежнего места наложения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78355,
                    "questionId": 7076,
                    "name": "Периодически ослаблять, применяя на это время пальцевое прижатие, затем накладывать на прежнее место.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78356,
                    "questionId": 7076,
                    "name": "Периодически ослаблять, применяя на это время пальцевое прижатие, затем переносить выше прежнего места наложения. ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7175,
            "name": "На чем основан принцип автоматики автомата АКМС?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78651,
                    "questionId": 7175,
                    "name": "На действии возвратной пружины.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78652,
                    "questionId": 7175,
                    "name": "На использовании энергии пороховых газов, отводимых из канала ствола в газовую камору.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78653,
                    "questionId": 7175,
                    "name": "На отдаче свободного затвора.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7002,
            "name": "Виды, типы, модели и количество гражданского и служебного оружия, которое имеет право приобретать юридические лица с особыми уставными задачами, устанавливаются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78132,
                    "questionId": 7002,
                    "name": "Государственной Думой Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78133,
                    "questionId": 7002,
                    "name": "Правительством Российской Федерации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78134,
                    "questionId": 7002,
                    "name": "Федеральной службой войск национальной гвардии Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7005,
            "name": "В какие сроки подлежит регистрации в соответствующих территориальных органах Росгвардии приобретенное оружие?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78141,
                    "questionId": 7005,
                    "name": "В двухнедельный срок со дня его приобретения.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78142,
                    "questionId": 7005,
                    "name": "В течение 30 дней с момента его приобретения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78143,
                    "questionId": 7005,
                    "name": "Не более чем 10 дней со дня его приобретения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7019,
            "name": "Запрещается ли на территории Российской Федерации оборот гражданского огнестрельного оружия, имитирующего другие предметы?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78183,
                    "questionId": 7019,
                    "name": "На территории Российской Федерации запрещается оборот в качестве гражданского оружия огнестрельного оружия, которое имеет форму, имитирующую другие предметы.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78184,
                    "questionId": 7019,
                    "name": "На территории Российской Федерации оборот гражданского оружия, которое имитирует другие предметы не запрещается.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78185,
                    "questionId": 7019,
                    "name": "На территории Российской Федерации разрешается оборот гражданского оружия, которое имитирует другие предметы, при условии, что это гражданское оружие состоит на вооружение юридического лица с особыми уставными задачами.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7028,
            "name": "В соответствии с Федеральным законом «Об оружии» лицензии на приобретение, а также разрешения на хранение или хранение и ношение оружия аннулируются органами, выдавшими эти лицензии или разрешения, в случаях:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78210,
                    "questionId": 7028,
                    "name": "В случае добровольного отказа от указанных лицензии и (или) разрешения, либо прекращения (отсутствия) у юридического лица права на приобретение (хранение, использование) оружия, либо ликвидации юридического лица, либо смерти собственника оружия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78211,
                    "questionId": 7028,
                    "name": "В случае если имеется представление прокуратуры Российской Федерации о необходимости принятия дополнительных мер по повышению технической укрепленности мест хранения оружия и патронов к нему.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78212,
                    "questionId": 7028,
                    "name": "В случаях если органы, имеющие право на выдачу лицензий на приобретение или разрешений на хранение или хранение и ношение оружия, приняли решение об их аннулировании.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7203,
            "name": "Произойдет ли выстрел, если работник юридического лица с особыми уставными задачами дослал патрон в патронник пистолета, передернув затвор и сразу поставил его на предохранитель (а при этом курок сорвался с боевого взвода!)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78732,
                    "questionId": 7203,
                    "name": "Не произойдет, пока не будет произведен повторный взвод и спуск курка при снятом предохранителе.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78733,
                    "questionId": 7203,
                    "name": "Произойдет, как и при любом срыве курка с боевого взвода.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78734,
                    "questionId": 7203,
                    "name": "Произойдет, но с замедлением до 30 секунд.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7014,
            "name": "Относятся ли аэрозольные устройства и механические распылители, снаряженные слезоточивым веществом, к оружию самообороны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78168,
                    "questionId": 7014,
                    "name": "Аэрозольные устройства и механические распылители, снаряженные слезоточивым веществом, относятся к оружию самообороны.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78169,
                    "questionId": 7014,
                    "name": "К оружию самообороны относятся только механические распылители, снаряженные слезоточивым веществом.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78170,
                    "questionId": 7014,
                    "name": "Механические распылители, снаряженные слезоточивым веществом, не относятся к оружию самообороны. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7143,
            "name": "По своему назначению возвратная пружина пистолета служит:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78555,
                    "questionId": 7143,
                    "name": "Для возвращения затвора в крайнее переднее положение после выстрела.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78556,
                    "questionId": 7143,
                    "name": "Для возвращения спускового крючка в крайнее переднее положение.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78557,
                    "questionId": 7143,
                    "name": "Для приведения в действие курка, рычага взвода и спусковой тяги.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7162,
            "name": "Для чего предназначен выбрасыватель в пистолете ПМ - 9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78612,
                    "questionId": 7162,
                    "name": "Для выброса пули.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78613,
                    "questionId": 7162,
                    "name": "Для запирания затвора.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78614,
                    "questionId": 7162,
                    "name": "Для экстракции гильзы.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7132,
            "name": "Эффективной мерой по обеспечению сохранности короткоствольного служебного оружия в процессе его ношения является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78522,
                    "questionId": 7132,
                    "name": "Ношение оружия с использованием пистолетного (револьверного) шнура.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78523,
                    "questionId": 7132,
                    "name": "Ношение патронов отдельно от оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78524,
                    "questionId": 7132,
                    "name": "Обматывание оружия изоляционной лентой.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7006,
            "name": "Кому не разрешается приобретение и использование охотничьего огнестрельного оружия в качестве служебного?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78144,
                    "questionId": 7006,
                    "name": "Государственным органам и государственным учреждениям, которые в соответствии с Лесным кодексом Российской Федерации осуществляют государственный лесной контроль и надзор.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78145,
                    "questionId": 7006,
                    "name": "Организациям, которые осуществляют функции охраны, контроля и регулирования использования объектов животного мира и среды их обитания.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78146,
                    "questionId": 7006,
                    "name": "Физкультурно-спортивным организациям и (или) спортивным клубам, осуществляющие свою деятельность в соответствующих видах спорта, связанных с использованием оружия (спортивные организации).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7022,
            "name": "Запрещается ли на территории Российской Федерации  использование патронов к служебному оружию с пулями бронебойного, зажигательного, разрывного или трассирующего действия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78192,
                    "questionId": 7022,
                    "name": "На территории Российской Федерации использование патронов к служебному оружию с пулями бронебойного, зажигательного, разрывного или трассирующего действия допускается только в том случае, если эти патроны используются юридическими лицами с особыми уставными задачами при пресечении массовых беспорядков.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78193,
                    "questionId": 7022,
                    "name": "На территории Российской Федерации использование патронов к служебному оружию с пулями бронебойного, зажигательного, разрывного или трассирующего действия запрещено.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78194,
                    "questionId": 7022,
                    "name": "На территории Российской Федерации использование патронов к служебному оружию с пулями бронебойного, зажигательного, разрывного или трассирующего действия разрешается при пресечении тяжкого преступления.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7030,
            "name": "В соответствии с действующим законодательством при необходимой обороне допускается причинение вреда:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78216,
                    "questionId": 7030,
                    "name": "Как посягающему лицу, так и третьим лицам.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78217,
                    "questionId": 7030,
                    "name": "Посягающему лицу.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78218,
                    "questionId": 7030,
                    "name": "Третьим лицам.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7111,
            "name": "Какая из палок резиновых, разрешенных для использования работниками юридического лица с особыми уставными задачами выпускается в варианте «телескопическая»:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78459,
                    "questionId": 7111,
                    "name": "ПР-Т.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78460,
                    "questionId": 7111,
                    "name": "ПУС-2.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78461,
                    "questionId": 7111,
                    "name": "ПУС-3.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7090,
            "name": "При каких действиях достигается наибольшая эффективность оказания помощи при выведении пострадавшего из обморока?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78396,
                    "questionId": 7090,
                    "name": "При нажатии на точку в центре носогубного треугольника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78397,
                    "questionId": 7090,
                    "name": "При поднятии ног пострадавшего выше уровня тела, при скручивающем нажатии на середину грудины, при наличии нашатырного спирта -  при поднесении к носу и смазывании висков ваткой, смоченной нашатырным спиртом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78398,
                    "questionId": 7090,
                    "name": "При укутывании пострадавшего в одеяло, приведения его в боковое устойчивое положение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7109,
            "name": "Палки резиновые ПУС-2 и ПР-Т, разрешенные для использования работниками юридического лица с особыми уставными задачами имеют в своей конструкции:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78453,
                    "questionId": 7109,
                    "name": "Боковую ручку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78454,
                    "questionId": 7109,
                    "name": "Выступающий кольцевой элемент (мини-гарду) рукоятки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78455,
                    "questionId": 7109,
                    "name": "Металлический наконечник.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7042,
            "name": "Причинение вреда при задержании лица, совершившего преступление, допускается в случаях:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78252,
                    "questionId": 7042,
                    "name": "Если лицо застигнуто на месте совершения преступления и пытается скрыться.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78253,
                    "questionId": 7042,
                    "name": "Если лицо застигнуто на месте совершения преступления.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78254,
                    "questionId": 7042,
                    "name": "При задержании лица для его доставления органам власти и пресечения возможности совершения им новых преступлений, если иными средствами задержать такое лицо не представлялось возможным, и при этом не было допущено превышения необходимых для этого мер.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7098,
            "name": "Частота вдуваний воздуха в минуту при проведении ИВЛ (искусственной вентиляции легких) составляет:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78420,
                    "questionId": 7098,
                    "name": "20-24 вдуваний в минуту для взрослых, 30-36 для детей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78421,
                    "questionId": 7098,
                    "name": "6-8 вдуваний в минуту для взрослых, 8-10 для детей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78422,
                    "questionId": 7098,
                    "name": "8-10 вдуваний в минуту для взрослых, 12-20 для детей.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6989,
            "name": "Какое из перечисленных ниже изделий не относится к оружию?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78093,
                    "questionId": 6989,
                    "name": "Изделия, сертифицированные в качестве изделий хозяйственно-бытового и производственного назначения, спортивные снаряды, конструктивно сходные с оружием.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78094,
                    "questionId": 6989,
                    "name": "Механические распылители, аэрозольные и другие устройства, снаряженные слезоточивыми или раздражающими веществами, разрешенными к применению федеральным органом исполнительной власти, осуществляющим функции по выработке государственной политики и нормативно-правовому регулированию в сфере здравоохранения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78095,
                    "questionId": 6989,
                    "name": "Пневматический пистолет с дульной энергией свыше 3 Дж.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6976,
            "name": "В соответствии с действующим законодательством газовое оружие это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78054,
                    "questionId": 6976,
                    "name": "Оружие и устройства, предназначенные для временного поражения живой цели путем применения нервно-паралитических веществ.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78055,
                    "questionId": 6976,
                    "name": "Оружие, предназначенное для временного химического поражения живой цели путем применения слезоточивых или раздражающих веществ.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78056,
                    "questionId": 6976,
                    "name": "Оружие, предназначенное для поражения живой цели путем применения слезоточивых, нервно-паралитических или раздражающих веществ.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7186,
            "name": "Меры безопасности при проведении на стрелковых объектах специальных упражнений (связанных с поворотами, разворотами, кувырками, перекатами и т.п.) предполагают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78681,
                    "questionId": 7186,
                    "name": "Надежное удержание оружия при передвижениях, без каких-либо дополнительных требований.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78682,
                    "questionId": 7186,
                    "name": "Нахождение оружия на предохранителе вплоть до момента открытия огня, направление оружия и производство выстрелов только по мишеням, установленным перед пулеприемником (пулеприемниками).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78683,
                    "questionId": 7186,
                    "name": "Обязательное применение оружия, предназначенного для подразделений специального назначения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7168,
            "name": "Какова прицельная дальность стрельбы из пистолета-пулемёта ОЦ-2 «Кипарис»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78630,
                    "questionId": 7168,
                    "name": "25 метров.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78631,
                    "questionId": 7168,
                    "name": "50 метров.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78632,
                    "questionId": 7168,
                    "name": "75 метров.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7121,
            "name": "В каком из перечисленных случаев работнику юридического лица с особыми уставными задачами разрешено использовать огнестрельное оружие?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78489,
                    "questionId": 7121,
                    "name": "При исполнении возложенных на него служебных обязанностей по охране личного транспорта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78490,
                    "questionId": 7121,
                    "name": "При исполнении возложенных на него служебных обязанностей по охране при транспортировании оружия (боеприпасов).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78491,
                    "questionId": 7121,
                    "name": "При исполнении любых поручений руководства организации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7166,
            "name": "Каков калибр пистолета-пулемета ПП-91 «Кедр»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78624,
                    "questionId": 7166,
                    "name": "5,45 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78625,
                    "questionId": 7166,
                    "name": "7,62 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78626,
                    "questionId": 7166,
                    "name": "9 мм.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7045,
            "name": "Нарушение работниками юридических лиц с особыми уставными задачами правил ношения оружия и патронов к нему влечет:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78261,
                    "questionId": 7045,
                    "name": "Административную ответственность.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78262,
                    "questionId": 7045,
                    "name": "Уголовную и административную ответственность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78263,
                    "questionId": 7045,
                    "name": "Уголовную ответственность.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7191,
            "name": "Для временного прекращения стрельбы в тире (на стрельбище) подается команда:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78696,
                    "questionId": 7191,
                    "name": "«Оружие к осмотру».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78697,
                    "questionId": 7191,
                    "name": "«Разряжай».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78698,
                    "questionId": 7191,
                    "name": "«Стой» (при стрельбе в движении - «Прекратить огонь»).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7119,
            "name": "Согласно рекомендациям предприятий-производителей, не следует применять аэрозольные устройства, снаряженные слезоточивыми веществами:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78483,
                    "questionId": 7119,
                    "name": "Во время дождя или в сырую погоду.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78484,
                    "questionId": 7119,
                    "name": "При встречном ветре, а также в замкнутом пространстве (подъездах, лифтах, транспортных средствах и т.п.).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78485,
                    "questionId": 7119,
                    "name": "При температуре окружающего воздуха ниже 0?С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7040,
            "name": "Имеют ли право на необходимую оборону лица, имеющие возможность избежать общественно опасного посягательства или обратиться за помощью к другим лицам или органам власти?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78246,
                    "questionId": 7040,
                    "name": "Да, имеют.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78247,
                    "questionId": 7040,
                    "name": "Имеют, если посягательство сопряжено с насилием, опасным для жизни обороняющегося.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78248,
                    "questionId": 7040,
                    "name": "Нет, не имеют.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6977,
            "name": "В соответствии с действующим законодательством боеприпасы это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78057,
                    "questionId": 6977,
                    "name": "Предметы вооружения и метаемое снаряжение, предназначенные для поражения цели и содержащие разрывной, метательный, пиротехнический или вышибной заряды либо их сочетание.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78058,
                    "questionId": 6977,
                    "name": "Устройства, предназначенные для выстрела из оружия и поражения цели.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78059,
                    "questionId": 6977,
                    "name": "Устройства, предназначенные для выстрела из оружия и состоящие из трех элементов - средства инициирования, метательного заряда и метаемого снаряжения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7056,
            "name": "Имеет ли право работник юридического лица с особыми уставными задачами проверять состояние инженерно-технических средств охраны, установленных на охраняемом объекте?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78294,
                    "questionId": 7056,
                    "name": "Имеет только в случае прохождения соответствующей подготовки и оформления допуска к их эксплуатации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78295,
                    "questionId": 7056,
                    "name": "Имеет.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78296,
                    "questionId": 7056,
                    "name": "Не имеет.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7044,
            "name": "За ненадлежащее исполнение своих обязанностей лицо, которому была поручена охрана огнестрельного оружия, боеприпасов, взрывчатых веществ или взрывных устройств если это повлекло их хищение или уничтожение, либо наступление иных тяжких последствий, привлекается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78258,
                    "questionId": 7044,
                    "name": "К административной ответственности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78259,
                    "questionId": 7044,
                    "name": "К дисциплинарной ответственности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78260,
                    "questionId": 7044,
                    "name": "К уголовной ответственности.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7007,
            "name": "Закрепление оружия за работниками юридических лиц с особыми уставными задачами осуществляется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78147,
                    "questionId": 7007,
                    "name": "По решению комиссии по проведению периодической проверки работников юридических лиц на пригодность к действиям в условиях, связанных с применением огнестрельного оружия и специальных средств.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78148,
                    "questionId": 7007,
                    "name": "По решению начальника органа внутренних дел, на территории обслуживания которого осуществляет свою деятельность юридическое лицо с особыми уставными задачами.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78149,
                    "questionId": 7007,
                    "name": "По решению руководителя юридического лица, после прохождения работниками соответствующей подготовки, периодической проверки на пригодность к действиям в условиях, связанных с применением огнестрельного оружия, и при отсутствии у них оснований, препятствующих получению лицензии на приобретение гражданского оружия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7027,
            "name": "Запрещается ли работнику юридического лица с особыми уставными задачами приобретение огнестрельного оружия на основании разрешения на хранение и ношение служебного оружия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78207,
                    "questionId": 7027,
                    "name": "Работник юридического лица с особыми уставными задачами на основании разрешения на хранение и ношение служебного оружия может приобрести гладкоствольного охотничьего и газовое оружие.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78208,
                    "questionId": 7027,
                    "name": "Работнику юридического лица с особыми уставными задачами на основании разрешения на хранение и ношение служебного оружия разрешается приобретать служебное оружие.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78209,
                    "questionId": 7027,
                    "name": "Работнику юридического лица с особыми уставными задачами приобретение служебного оружия на основании разрешения на хранение и ношение служебного оружия запрещено.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7200,
            "name": "Чистка и смазка оружия (пистолетов, револьверов, ружей и карабинов) после стрельбы производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78723,
                    "questionId": 7200,
                    "name": "Немедленно по окончании стрельбы (частично), по возвращении со стрельбы (окончательно), в последующие 3-4 дня ежедневно.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78724,
                    "questionId": 7200,
                    "name": "Немедленно по окончании стрельбы (частично), по возвращении со стрельбы (окончательно).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78725,
                    "questionId": 7200,
                    "name": "Однократно, по возвращении со стрельбы.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7192,
            "name": "Для полного прекращения стрельбы в тире (на стрельбище) подается команда:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78699,
                    "questionId": 7192,
                    "name": "«Оружие к осмотру».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78700,
                    "questionId": 7192,
                    "name": "«Разряжай».",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78701,
                    "questionId": 7192,
                    "name": "«Стой» (при стрельбе в движении - «Прекратить огонь»).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6978,
            "name": "В соответствии с действующим законодательством патрон это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78060,
                    "questionId": 6978,
                    "name": "Боеприпас, представляющий собой сборочную единицу, состоящую из метаемого элемента, метательного заряда, капсюля-воспламенителя и гильзы.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78061,
                    "questionId": 6978,
                    "name": "Устройство, предназначенное для выстрела из оружия и состоящее из трех элементов - средства инициирования, метательного заряда и метаемого снаряжения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78062,
                    "questionId": 6978,
                    "name": "Устройство, предназначенное для выстрела из оружия, объединяющее в одно целое при помощи гильзы средства инициирования, метательный заряд и метаемое снаряжение.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7185,
            "name": "Каков порядок действий стрелка при проведении стрельб в тирах и на стрельбищах?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78678,
                    "questionId": 7185,
                    "name": "Стрелок выходит, заряжает, стреляет, производит иные действия только по мере получения отдельных команд.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78679,
                    "questionId": 7185,
                    "name": "Стрелок по команде «на линию огня» выходит на огневой рубеж, заряжает, стреляет.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78680,
                    "questionId": 7185,
                    "name": "Стрелок самостоятельно выходит на линию огня, по команде «заряжай» заряжает оружие и по команде «огонь» ведет огонь.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7099,
            "name": "Ритм сердечно-легочной реанимации, выполняемой при оказании первой помощи:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78423,
                    "questionId": 7099,
                    "name": "15 надавливаний на грудную клетку – 2 вдувания воздуха.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78424,
                    "questionId": 7099,
                    "name": "30 надавливаний на грудную клетку – 2 вдувания воздуха. ",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78425,
                    "questionId": 7099,
                    "name": "5 надавливаний на грудную клетку – 1 вдувание воздуха.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7092,
            "name": "Что надо сделать для определения наличия дыхания при бессознательном состоянии пострадавшего?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78402,
                    "questionId": 7092,
                    "name": "Поднести зеркальце или птичье перо к носу пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78403,
                    "questionId": 7092,
                    "name": "Поднести к носу пострадавшего внутреннюю сторону своего запястья или щеку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78404,
                    "questionId": 7092,
                    "name": "Приложить ухо к груди пострадавшего и прослушать дыхание.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6991,
            "name": "Оружие в зависимости от целей его использования соответствующими субъектами, а также по основным параметрам и характеристикам подразделяется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78099,
                    "questionId": 6991,
                    "name": "На гражданское, служебное, боевое ручное стрелковое и холодное.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78100,
                    "questionId": 6991,
                    "name": "На длинноствольное, короткоствольное и бесствольное.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78101,
                    "questionId": 6991,
                    "name": "На огнестрельное, холодное, газовое и пневматическое.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6975,
            "name": "В соответствии с действующим законодательством пневматическое оружие это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78051,
                    "questionId": 6975,
                    "name": "Оружие, предназначенное для поражения цели на расстоянии снарядом, получающим направленное движение за счет энергии сжатого, сжиженного или отвержденного газа.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78052,
                    "questionId": 6975,
                    "name": "Оружие, с дульной энергией не более 25 Дж, предназначенное для поражения цели на расстоянии снарядом, получившим направленное движение за счет энергии сжатого газа.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78053,
                    "questionId": 6975,
                    "name": "Устройства, предназначенные для поражения цели на расстоянии снарядом, получающим направленное движение за счет энергии сжатой пружины.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7053,
            "name": "Должен ли работник юридического лица с особыми уставными задачами беспрепятственно допустить на охраняемый объект лиц, представившихся работниками правоохранительных органов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78285,
                    "questionId": 7053,
                    "name": "Должен в любом случае, если предъявлены удостоверения, сходные с удостоверениями работников правоохранительных органов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78286,
                    "questionId": 7053,
                    "name": "Должен в случаях, когда законом предусмотрен беспрепятственный допуск, после того как убедится, что указанные лица являются работниками правоохранительных органов.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78287,
                    "questionId": 7053,
                    "name": "Не должен.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7178,
            "name": "Дальность прямого выстрела по грудной фигуре автомата АКМС?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78660,
                    "questionId": 7178,
                    "name": "350 м.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78661,
                    "questionId": 7178,
                    "name": "450 м.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78662,
                    "questionId": 7178,
                    "name": "500 м.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7147,
            "name": "Какое действие в процессе полной разборки пистолета ИЖ-71 выполняется в первую очередь? (предполагается, что операции по неполной разборке пистолета уже выполнены).",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78567,
                    "questionId": 7147,
                    "name": "Отделить рукоятку от основания и боевую пружину.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78568,
                    "questionId": 7147,
                    "name": "Отделить спусковой крючок.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78569,
                    "questionId": 7147,
                    "name": "Отделить шептало и затворную задержку от рамки.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7000,
            "name": "Приобретение оружия и патронов к нему на территории Российской Федерации подлежит лицензированию если:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78126,
                    "questionId": 7000,
                    "name": "Оружие и патроны к нему приобретаются государственными военизированными организациями.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78127,
                    "questionId": 7000,
                    "name": "Оружие и патроны к нему приобретаются юридическим лицом с особыми уставными задачами.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78128,
                    "questionId": 7000,
                    "name": "Приобретается спортивное пневматическое оружие с дульной энергией не более 7,5 Дж и калибром до 4,5 мм включительно.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6992,
            "name": "К гражданскому оружию относится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78102,
                    "questionId": 6992,
                    "name": "Оружие, предназначенное для использования гражданами Российской Федерации в целях самообороны, для занятий спортом и охотой, а также в культурных и образовательных целях.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78103,
                    "questionId": 6992,
                    "name": "Оружие, предназначенное для использования гражданами Российской Федерации в целях: самообороны, ведения боевых действий и использования в иных целях.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78104,
                    "questionId": 6992,
                    "name": "Оружие, предназначенное для использования должностными лицами государственных органов, которым законодательством Российской Федерации разрешено ношение, хранение и применение указанного оружия, в целях исполнения возложенных на них федеральным законом обязанностей по защите жизни и здоровья граждан, собственности, по охране природы и природных ресурсов.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7188,
            "name": "При стрельбе в тире в противошумовых наушниках или защитных очках действуют следующие правила:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78687,
                    "questionId": 7188,
                    "name": "Запрещается надевать, поправлять и снимать их с оружием в руках.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78688,
                    "questionId": 7188,
                    "name": "Разрешается надевать, поправлять и снимать их с оружием в руках.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78689,
                    "questionId": 7188,
                    "name": "Следует закрепить их во избежание падения во время стрельбы.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6994,
            "name": "Юридическими лицами с особыми уставными задачами являются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78108,
                    "questionId": 6994,
                    "name": "Предприятия и организации, которые выполняют функции по защите охраняемых объектов от противоправных посягательств.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78109,
                    "questionId": 6994,
                    "name": "Предприятия и организации, которым в соответствии с действующим законодательством разрешается производство, транспортирование, экспонирование и уничтожение огнестрельного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78110,
                    "questionId": 6994,
                    "name": "Предприятия и организации, на которые законодательством Российской Федерации возложены функции, связанные с использованием и применением служебного оружия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7003,
            "name": "Кем определяются правила оборота боевого ручного стрелкового оружия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78135,
                    "questionId": 7003,
                    "name": "Правительством Российской Федерации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78136,
                    "questionId": 7003,
                    "name": "Президентом Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78137,
                    "questionId": 7003,
                    "name": "Федеральной службой войск национальной гвардии Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7130,
            "name": "Правилами оборота гражданского и служебного оружия на территории Российской Федерации предусмотрено, что досылание патрона в патронник разрешается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78516,
                    "questionId": 7130,
                    "name": "При необходимости применения оружия, а также в любых других опасных ситуациях.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78517,
                    "questionId": 7130,
                    "name": "При необходимости применения оружия, а также при охране денежных средств и ценных грузов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78518,
                    "questionId": 7130,
                    "name": "Только при необходимости применения оружия либо для защиты жизни, здоровья и собственности в состоянии необходимой обороны или крайней необходимости.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7067,
            "name": "Какие действия, предусмотренные в составе первой помощи, завершают ее оказание?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78327,
                    "questionId": 7067,
                    "name": "Передача пострадавшего бригаде скорой медицинской помощи. ",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78328,
                    "questionId": 7067,
                    "name": "Проведение мероприятий первичной медико-санитарной помощи.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78329,
                    "questionId": 7067,
                    "name": "Проведение мероприятий специализированной медицинской помощи. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7158,
            "name": "Какова боевая скорострельность пистолета ПМ-9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78600,
                    "questionId": 7158,
                    "name": "10 выстрелов в минуту.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78601,
                    "questionId": 7158,
                    "name": "30 выстрелов в минуту.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78602,
                    "questionId": 7158,
                    "name": "50 выстрелов в минуту.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7004,
            "name": "Кем определяются правила оборота патронов к боевому ручному стрелковому оружию?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78138,
                    "questionId": 7004,
                    "name": "Правительством Российской Федерации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78139,
                    "questionId": 7004,
                    "name": "Президентом Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78140,
                    "questionId": 7004,
                    "name": "Федеральной службой войск национальной гвардии Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7135,
            "name": "Начальной скоростью пули называется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78531,
                    "questionId": 7135,
                    "name": "Скорость движения пули на расстоянии одного метра от дульного среза ствола. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78532,
                    "questionId": 7135,
                    "name": "Скорость движения пули при вхождении в ствол из патронника (каморы барабана).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78533,
                    "questionId": 7135,
                    "name": "Скорость движения пули при прохождении дульного среза ствола.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7179,
            "name": "Какова емкость магазина автомата АКМС?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78663,
                    "questionId": 7179,
                    "name": "10 патронов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78664,
                    "questionId": 7179,
                    "name": "16 патронов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78665,
                    "questionId": 7179,
                    "name": "30 патронов.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7138,
            "name": "Траекторией полета пули называется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78540,
                    "questionId": 7138,
                    "name": "Кривая линия, описываемая центром тяжести пули в полете.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78541,
                    "questionId": 7138,
                    "name": "Прямая линия от центра ствола до точки попадания.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78542,
                    "questionId": 7138,
                    "name": "Прямая линия, проходящая от глаза стрелка через середину прорези прицела (на уровне с ее краями) и вершину мушки в точку прицеливания.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7001,
            "name": "Юридические лица с особыми уставными задачами имеют право в соответствии с нормативными правовыми актами Правительства Российской Федерации:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78129,
                    "questionId": 7001,
                    "name": "Приобретать боевое ручное стрелковое оружие, находящееся на законных основаниях на вооружении государственных военизированных организаций.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78130,
                    "questionId": 7001,
                    "name": "Приобретать гражданское и служебное оружие у юридических лиц-поставщиков.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78131,
                    "questionId": 7001,
                    "name": "Приобретать гражданское оружие у граждан, владеющих им на законных основаниях, после получения соответствующей лицензии в территориальных органах Росгвардии.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7157,
            "name": "Какова эффективная дальность стрельбы из ПМ-9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78597,
                    "questionId": 7157,
                    "name": "До 100 метров.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78598,
                    "questionId": 7157,
                    "name": "До 25 метров.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78599,
                    "questionId": 7157,
                    "name": "До 50 метров.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7152,
            "name": "Из чего состоит пуля 9-мм патрона пистолета ИЖ-71?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78582,
                    "questionId": 7152,
                    "name": "Пуля из свинца в биметаллической оболочке.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78583,
                    "questionId": 7152,
                    "name": "Пуля, гильза, пороховой заряд, капсюль.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78584,
                    "questionId": 7152,
                    "name": "Стальной сердечник, свинцовая рубашка, биметаллическая оболочка.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6996,
            "name": "К боевому ручному стрелковому и холодному оружию относится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78114,
                    "questionId": 6996,
                    "name": "Огнестрельное гладкоствольное и нарезное короткоствольное оружие отечественного производства с дульной энергией более 300 Дж.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78115,
                    "questionId": 6996,
                    "name": "Оружие, предназначенное для использования должностными лицами государственных органов и работниками юридических лиц с особыми уставными задачами.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78116,
                    "questionId": 6996,
                    "name": "Оружие, предназначенное для решения боевых и оперативно-служебных задач.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7031,
            "name": "При необходимой обороне субъектом посягательства, отражаемого обороняющимся, является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78219,
                    "questionId": 7031,
                    "name": "Источник повышенной опасности (оружие, автомобиль и пр.).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78220,
                    "questionId": 7031,
                    "name": "Стихия (силы природы).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78221,
                    "questionId": 7031,
                    "name": "Человек (физическое лицо).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7107,
            "name": "Перед надеванием наручников на правонарушителя необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78447,
                    "questionId": 7107,
                    "name": "Освободить запястья от одежды.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78448,
                    "questionId": 7107,
                    "name": "Подложить на запястья в тех местах, на которые будут надеваться наручники, ткань, салфетку или платок.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78449,
                    "questionId": 7107,
                    "name": "Получить на применение наручников разрешение руководителя охранной организации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7033,
            "name": "Допускается ли причинение вреда третьим лицам в состоянии необходимой обороны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78225,
                    "questionId": 7033,
                    "name": "Допускается при вооруженном нападении.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78226,
                    "questionId": 7033,
                    "name": "Допускается при групповом нападении.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78227,
                    "questionId": 7033,
                    "name": "Причинение вреда третьим лицам в состоянии необходимой обороны не допускается.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7083,
            "name": "Что надо делать при нахождении ножа или другого ранящего предмета в ране?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78375,
                    "questionId": 7083,
                    "name": "Вытащить нож и быстро, без обработки раны антисептиком, наложить повязку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78376,
                    "questionId": 7083,
                    "name": "Оставить ранящий предмет в ране, зафиксировать предмет в ране, наложив вокруг него повязку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78377,
                    "questionId": 7083,
                    "name": "Применить пальцевое прижатие, наложить жгут выше места ранения, вытащить ранящий предмет, наложить повязку.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7074,
            "name": "Техника наложения кровоостанавливающего жгута предусматривает:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78348,
                    "questionId": 7074,
                    "name": "Наложение жгута на одежду выше места кровотечения (с указанием времени наложения в записке).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78349,
                    "questionId": 7074,
                    "name": "Наложение жгута на одежду ниже места кровотечения (с указанием времени наложения в записке).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78350,
                    "questionId": 7074,
                    "name": "Наложение жгута под одежду выше места кровотечения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7097,
            "name": "Особенности проведения ИВЛ (искусственной вентиляции легких) детям:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78417,
                    "questionId": 7097,
                    "name": "Увеличивается частота вдуваний воздуха с обязательным уменьшением объема вдуваемого воздуха.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78418,
                    "questionId": 7097,
                    "name": "Уменьшается частота вдуваний воздуха с обязательным уменьшением объема вдуваемого воздуха.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78419,
                    "questionId": 7097,
                    "name": "Частота вдуваний воздуха и объем вдуваемого воздуха, по сравнению со взрослыми пострадавшими, не меняется. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7105,
            "name": "Каким дополнительным элементом не комплектуются бронешлемы (шлемы защитные)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78441,
                    "questionId": 7105,
                    "name": "Бармицей для защиты шеи.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78442,
                    "questionId": 7105,
                    "name": "Встроенной радиогарнитурой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78443,
                    "questionId": 7105,
                    "name": "Шейно-плечевой накладкой.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7060,
            "name": "Каково содержание информации, сообщаемой при вызове скорой медицинской помощи?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78306,
                    "questionId": 7060,
                    "name": "Сообщить, кто вызывает, телефон вызывающего, что случилось, кто пострадал/заболел (пол, возраст), адрес с указанием подъездного пути, дома, подъезда, этажа, кода на входной двери.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78307,
                    "questionId": 7060,
                    "name": "Сообщить, что случилось, кто вызывает, телефон вызывающего, кто пострадал/заболел (пол, возраст), адрес с указанием подъездного пути, дома, подъезда, этажа, кода на входной двери.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78308,
                    "questionId": 7060,
                    "name": "Сообщить, что случилось, кто пострадал/заболел (пол, возраст), адрес с указанием подъездных путей, дома, подъезда, этажа, кода на входной двери, кто вызывает, телефон вызывающего.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7113,
            "name": "Палки резиновые, разрешенные для использования работниками юридического лица с особыми уставными задачами имеют диаметр:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78465,
                    "questionId": 7113,
                    "name": "От 30 до 34 мм.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78466,
                    "questionId": 7113,
                    "name": "От 34 до 38 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78467,
                    "questionId": 7113,
                    "name": "От 38 до 42 мм.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7089,
            "name": "Порядок оказания первой помощи при открытых переломах.",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78393,
                    "questionId": 7089,
                    "name": "Наложить шину и обезболить (по возможности).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78394,
                    "questionId": 7089,
                    "name": "Наложить шину, наложить повязку на рану.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78395,
                    "questionId": 7089,
                    "name": "Обезболить (по возможности), наложить повязку, наложить шину.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7013,
            "name": "Какое оружие относится к гражданскому оружию самообороны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78165,
                    "questionId": 7013,
                    "name": "Искровые разрядники и электрошоковые устройства иностранного производства, имеющие соответствующий сертификат качества.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78166,
                    "questionId": 7013,
                    "name": "Искровые разрядники и электрошоковые устройства иностранного производства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78167,
                    "questionId": 7013,
                    "name": "Искровые разрядники и электрошоковые устройства отечественного производства.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7117,
            "name": "Хранение каких видов специальных средств, используемых юридическими лицами с особыми уставными задачами, допускается ближе 1 метра от отопительных приборов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78477,
                    "questionId": 7117,
                    "name": "Жилетов и шлемов защитных.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78478,
                    "questionId": 7117,
                    "name": "Наручников.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78479,
                    "questionId": 7117,
                    "name": "Палок резиновых.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7144,
            "name": "По своему назначению курок пистолета служит:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78558,
                    "questionId": 7144,
                    "name": "Для нанесения удара по капсюлю гильзы.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78559,
                    "questionId": 7144,
                    "name": "Для нанесения удара по ударнику.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78560,
                    "questionId": 7144,
                    "name": "Для приведения в действие спусковой тяги с рычагом взвода.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7131,
            "name": "На основании правил оборота гражданского и служебного оружия на территории Российской Федерации, работники юридического лица с особыми уставными задачами, осуществляющие ношение оружия при исполнении служебных обязанностей, должны иметь при себе:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78519,
                    "questionId": 7131,
                    "name": "Выданное Федеральной службой войск национальной гвардии Российской Федерации или ее территориальным органом разрешение на хранение и ношение имеющегося у них оружия, медицинские справки форм 002-О/у и 003-О/у.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78520,
                    "questionId": 7131,
                    "name": "Документы, удостоверяющие их личность, а также выданное Федеральной службой войск национальной гвардии Российской Федерации или ее территориальным органом разрешение на хранение и ношение имеющегося у них оружия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78521,
                    "questionId": 7131,
                    "name": "Документы, удостоверяющие их личность, приказ на охрану поста (маршрута).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7149,
            "name": "Какая из указанных частей входит в ударно-спусковой механизм пистолета ИЖ-71?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78573,
                    "questionId": 7149,
                    "name": "Возвратная пружина.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78574,
                    "questionId": 7149,
                    "name": "Задвижка боевой пружины.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78575,
                    "questionId": 7149,
                    "name": "Ударник.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7118,
            "name": "При ношении бронежилетов (жилетов защитных) скрытого ношения рекомендуется использовать одежду:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78480,
                    "questionId": 7118,
                    "name": "На 1-2 размера больше той, которую носит использующий бронежилет (жилет защитный) или одежду свободного покроя.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78481,
                    "questionId": 7118,
                    "name": "На 3-4 размера больше той, которую носит использующий бронежилет (жилет защитный) или одежду свободного покроя.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78482,
                    "questionId": 7118,
                    "name": "Совпадающую по размеру с той, которую носит использующий бронежилет (жилет защитный) или одежду свободного покроя.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7184,
            "name": "Безопасное использование оружия предполагает в период непосредственного применения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78675,
                    "questionId": 7184,
                    "name": "Контролировать направления ствола оружия при досылке патрона в патронник только в ситуациях близости несовершеннолетних или ценного имущества.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78676,
                    "questionId": 7184,
                    "name": "Не отвлекаться на контроль направления ствола оружия при досылке патрона в патронник.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78677,
                    "questionId": 7184,
                    "name": "Обязательный контроль направления ствола оружия при досылке патрона в патронник для исключения возможного вреда посторонним лицам.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7173,
            "name": "Какова масса пистолета-пулемёта «Бизон-2» без патронов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78645,
                    "questionId": 7173,
                    "name": "1,6 кг.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78646,
                    "questionId": 7173,
                    "name": "2,6 кг.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78647,
                    "questionId": 7173,
                    "name": "3,6 кг.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7198,
            "name": "Безопасное использование оружия при его ношении предполагает передачу оружия лицу, уполномоченному на его проверку:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78717,
                    "questionId": 7198,
                    "name": "В том состоянии, которого потребовал проверяющий.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78718,
                    "questionId": 7198,
                    "name": "С отсоединенным магазином и после проверки факта отсутствия патрона в патроннике.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78719,
                    "questionId": 7198,
                    "name": "С патроном в патроннике и присоединенным магазином.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7202,
            "name": "Смазку оружия положено производить:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78729,
                    "questionId": 7202,
                    "name": "Немедленно после чистки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78730,
                    "questionId": 7202,
                    "name": "Одновременно с чисткой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78731,
                    "questionId": 7202,
                    "name": "По истечении 10 минут после чистки.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7070,
            "name": "Что надо делать в случае, если у пострадавшего развился приступ эпилепсии (судорожный приступ)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78336,
                    "questionId": 7070,
                    "name": "Придерживать пострадавшего за голову, не давая ее разбить, по окончанию приступа перевести в устойчивое боковое положение.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78337,
                    "questionId": 7070,
                    "name": "Расслабить (расстегнуть) стягивающую одежду, убрать предметы, которые могут травмировать пострадавшего, подложить под голову мягкий, плоский предмет (например, подушку или свернутую одежду), зафиксировать время начала и конца приступа, по окончанию приступа перевести в устойчивое боковое положение.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78338,
                    "questionId": 7070,
                    "name": "Удерживать пострадавшего за руки и ноги, для предотвращения укуса языка и его западения ввести в рот ложку, по окончанию приступа перевести в устойчивое боковое положение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6983,
            "name": "В соответствии с действующим законодательством патрон газового действия это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78075,
                    "questionId": 6983,
                    "name": "Патрон, предназначенный для выстрела из газового оружия и не предназначенный для причинения смерти человеку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78076,
                    "questionId": 6983,
                    "name": "Устройство, предназначенное для выстрела из газового оружия или огнестрельного оружия ограниченного поражения, объединяющее в одно целое при помощи гильзы средства инициирования, снаряженное слезоточивыми или раздражающими веществами и не предназначенное для причинения смерти человеку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78077,
                    "questionId": 6983,
                    "name": "Устройство, предназначенное для выстрела из огнестрельного гладкоствольного оружия ограниченного поражения метательного снаряда, снаряженного слезоточивыми или раздражающими веществами и не предназначенное для причинения смерти человеку.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7170,
            "name": "Какова масса пистолета-пулемета ОЦ-2 «Кипарис» без патронов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78636,
                    "questionId": 7170,
                    "name": "0,57 кг.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78637,
                    "questionId": 7170,
                    "name": "1,57 кг.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78638,
                    "questionId": 7170,
                    "name": "2,57 кг.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6980,
            "name": "В соответствии с действующим законодательством оборот оружия и основных частей огнестрельного оружия в Российской Федерации это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78066,
                    "questionId": 6980,
                    "name": "Весь жизненный цикл оружия и основных частей огнестрельного оружия от его производства до утилизации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78067,
                    "questionId": 6980,
                    "name": "Приобретение гражданами и юридическими лицами с особыми уставными задачами оружия и основных частей огнестрельного оружия, получение разрешений на его хранение и использование, транспортирование, учет, хранение, ношение и использование.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78068,
                    "questionId": 6980,
                    "name": "Производство оружия, торговля оружием, продажа, передача, приобретение, коллекционирование, экспонирование, учет, хранение, ношение, перевозка, транспортирование, использование, изъятие, уничтожение, ввоз оружия на территорию Российской Федерации и вывоз его из Российской Федерации.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7197,
            "name": "Действия с оружием по завершении его применения работником юридического лица с особыми уставными задачами при исполнении служебных обязанностей (до прибытия правоохранительных органов):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78714,
                    "questionId": 7197,
                    "name": "Прекратить нажим на хвост спускового крючка, не разряжая оружие и не ставя оружие на предохранитель, дождаться указаний вышестоящего начальника или представителей правоохранительных органов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78715,
                    "questionId": 7197,
                    "name": "Прекратить нажим на хвост спускового крючка, не разряжая оружие, включить предохранитель (если таковой имеется), убрать оружие в кобуру (для тех видов оружия, ношение которых осуществляется в кобуре).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78716,
                    "questionId": 7197,
                    "name": "Прекратить нажим на хвост спускового крючка, разрядить оружие, включить предохранитель (если таковой имеется), убрать оружие в кобуру (для тех видов оружия, ношение которых осуществляется в кобуре).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7139,
            "name": "К основным частям огнестрельного оружия относятся:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78543,
                    "questionId": 7139,
                    "name": "Ствол, затвор, барабан, рамка, ствольная коробка.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78544,
                    "questionId": 7139,
                    "name": "Ствол, затворная рама, крышка ствольной коробки, приклад, рукоятка.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78545,
                    "questionId": 7139,
                    "name": "Ствол, магазин, барабан, рамка, ствольная коробка, патрон.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6986,
            "name": "В соответствии с действующим законодательством боек ударного механизма это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78084,
                    "questionId": 6986,
                    "name": "Деталь ударного механизма, наносящая удар по средству инициирования патрона. ",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78085,
                    "questionId": 6986,
                    "name": "Механизм оружия, обеспечивающий приведение в действие средства инициирования патрона.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78086,
                    "questionId": 6986,
                    "name": "Устройство, предназначенное для нанесения удара по капсюлю-воспламенителю.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6984,
            "name": "В соответствии с действующим законодательством патрон светозвукового действия это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78078,
                    "questionId": 6984,
                    "name": "Патрон, предназначенный для выстрела из сигнального оружия и не предназначенный для причинения смерти человеку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78079,
                    "questionId": 6984,
                    "name": "Устройство, предназначенное для выстрела из огнестрельного оружия или сигнального оружия, объединяющее в одно целое при помощи гильзы средства инициирования, метательный заряд и метаемое снаряжение для подачи светового, дымового или звукового сигнала и не предназначенное для поражения живой или иной цели.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78080,
                    "questionId": 6984,
                    "name": "Устройство, предназначенное для выстрела из огнестрельного оружия, огнестрельного оружия ограниченного поражения, газового или сигнального оружия, объединяющее в одно целое при помощи гильзы средства инициирования и снаряжение светозвукового действия и не предназначенное для поражения живой или иной цели.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6970,
            "name": "В соответствии с действующим законодательством оружие это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78036,
                    "questionId": 6970,
                    "name": "Устройства и предметы, конструктивно не предназначенные, но приспособленные для поражения живой или иной цели.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78037,
                    "questionId": 6970,
                    "name": "Устройства и предметы, конструктивно предназначенные для поражения живой или иной цели, подачи сигналов.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78038,
                    "questionId": 6970,
                    "name": "Устройства и предметы, при помощи которых производится метание пули, дроби, картечи.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7177,
            "name": "Какова прицельная дальность стрельбы из автомата АКМС?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78657,
                    "questionId": 7177,
                    "name": "1000 м.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78658,
                    "questionId": 7177,
                    "name": "500 м.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78659,
                    "questionId": 7177,
                    "name": "800 м.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7054,
            "name": "Действия работника юридического лица с особыми уставными задачами по просмотру документов посетителей объекта охраны (а также осмотру вносимого и выносимого ими имущества, регистрации и обработке их персональных данных) могут осуществляться:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78288,
                    "questionId": 7054,
                    "name": "В качестве мер принуждения, применяемых независимо от воли посетителей объекта охраны, ознакомившихся с правилами внутриобъектового и пропускного режимов на объекте.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78289,
                    "questionId": 7054,
                    "name": "На добровольной основе, когда посетители ознакомились с соответствующими правилами внутриобъектового и пропускного режимов, установленными руководством охраняемого объекта, и согласились их выполнять.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78290,
                    "questionId": 7054,
                    "name": "На основании Кодекса Российской Федерации об административных правонарушениях, независимо от наличия каких-либо правил, установленных руководством охраняемого объекта.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6995,
            "name": "К служебному оружию не относится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78111,
                    "questionId": 6995,
                    "name": "Огнестрельное гладкоствольное и нарезное короткоствольное оружие отечественного производства с дульной энергией не более 300 Дж, огнестрельное гладкоствольное длинноствольное оружие, а также огнестрельное оружие ограниченного поражения с патронами травматического действия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78112,
                    "questionId": 6995,
                    "name": "Оружие, предназначенное для использования должностными лицами государственных органов и работниками юридических лиц, которым законодательством Российской Федерации разрешено ношение, хранение и применение указанного оружия, в целях самообороны или для исполнения возложенных на них федеральным законом обязанностей по защите жизни и здоровья граждан, собственности, по охране природы и природных ресурсов, ценных и опасных грузов, специальной корреспонденции.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78113,
                    "questionId": 6995,
                    "name": "Оружие, предназначенное для решения боевых и оперативно-служебных задач.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7012,
            "name": "Верно ли, что различие оружия на гражданское, служебное, боевое ручное стрелковое и холодное, осуществляется по целям его использования, параметрам и характеристикам?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78162,
                    "questionId": 7012,
                    "name": "Оружие по целям его использования, параметрам и характеристикам различается на длинноствольное, короткоствольное и бесствольное.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78163,
                    "questionId": 7012,
                    "name": "Различие оружия на гражданское, служебное, боевое ручное стрелковое и холодное осуществляется по принадлежности его организациям, назначению и способам производства, и техническим характеристикам.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78164,
                    "questionId": 7012,
                    "name": "Различие оружия на гражданское, служебное, боевое ручное стрелковое и холодное, осуществляется по целям его использования, параметрам и характеристикам.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7194,
            "name": "При выполнении команды «Заряжай», согласно понятию о заряжании стрелкового оружия, установленному действующим государственным стандартом (с учетом требований безопасности на стрелковых объектах):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78705,
                    "questionId": 7194,
                    "name": "Пистолет направляется дульной частью в сторону мишени, снаряженный магазин вставляется в основание рукоятки пистолета (если по условиям упражнения уже не был присоединен), патрон в патронник не досылается, стрелок производит доклад о готовности «Иванов к стрельбе готов».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78706,
                    "questionId": 7194,
                    "name": "Пистолет направляется дульной частью в сторону мишени, снаряженный магазин вставляется в основание рукоятки пистолета (если по условиям упражнения уже не был присоединен), пистолет снимается с предохранителя (предохранитель выключается), патрон досылается в патронник, пистолет ставится на предохранитель (предохранитель включается), стрелок производит доклад о готовности «Иванов к стрельбе готов».",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78707,
                    "questionId": 7194,
                    "name": "Пистолет удерживается дульной частью в произвольном направлении, снаряженный магазин вставляется в основание рукоятки пистолета (если по условиям упражнения уже не был присоединен), пистолет снимается с предохранителя (предохранитель выключается), патрон досылается в патронник, пистолет ставится на предохранитель (предохранитель включается), стрелок производит доклад о готовности «Иванов к стрельбе готов».",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7195,
            "name": "Действия стрелка по полному прекращению стрельбы в тире (на стрельбище):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78708,
                    "questionId": 7195,
                    "name": "Прекратить нажим на хвост спускового крючка, включить предохранитель (если таковой имеется), разрядить оружие (согласно правилам, установленным для данного оружия), далее произвести контрольный спуск курка (в условиях безопасности по направлению возможного выстрела).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78709,
                    "questionId": 7195,
                    "name": "Прекратить нажим на хвост спускового крючка, включить предохранитель (если таковой имеется), разрядить оружие (согласно правилам, установленным для данного оружия); далее действовать по команде «Оружие – к осмотру».",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78710,
                    "questionId": 7195,
                    "name": "Прекратить нажим на хвост спускового крючка; включить предохранитель (если таковой имеется).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7165,
            "name": "Механизмы автоматики пистолета-пулемёта ПП-91 «Кедр» работают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78621,
                    "questionId": 7165,
                    "name": "За счет действия энергии возвратной пружины.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78622,
                    "questionId": 7165,
                    "name": "За счет энергии отдачи свободного затвора.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78623,
                    "questionId": 7165,
                    "name": "На использовании энергии пороховых газов, отводимых из канала ствола в газовую камору.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7046,
            "name": "Неповиновение законному распоряжению или требованию должностного лица органа, осуществляющего государственный надзор (контроль) влечет:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78264,
                    "questionId": 7046,
                    "name": "Административную ответственность.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78265,
                    "questionId": 7046,
                    "name": "Наказание за данное деяние законом не предусмотрено.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78266,
                    "questionId": 7046,
                    "name": "Уголовную ответственность.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7103,
            "name": "Как меняется время непрерывного ношения бронежилета (жилета защитного) при повышении температуры и влажности воздуха:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78435,
                    "questionId": 7103,
                    "name": "Остается неизменным.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78436,
                    "questionId": 7103,
                    "name": "Увеличивается.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78437,
                    "questionId": 7103,
                    "name": "Уменьшается.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7148,
            "name": "Какие действия при сборке пистолета после неполной разборки производятся в первую очередь?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78570,
                    "questionId": 7148,
                    "name": "Вставить магазин в основание рукоятки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78571,
                    "questionId": 7148,
                    "name": "Надеть на ствол возвратную пружину.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78572,
                    "questionId": 7148,
                    "name": "Оттянуть спусковую скобу.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7153,
            "name": "Для чего служит рамка в ПМ -9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78585,
                    "questionId": 7153,
                    "name": "Для обеспечения безопасности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78586,
                    "questionId": 7153,
                    "name": "Для производства выстрела.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78587,
                    "questionId": 7153,
                    "name": "Для соединения всех частей пистолета.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7075,
            "name": "Время наложения кровоостанавливающего жгута:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78351,
                    "questionId": 7075,
                    "name": "Летом – не более чем на 1 час, зимой – не более чем на 30 минут.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78352,
                    "questionId": 7075,
                    "name": "Летом – не более чем на 30 минут, зимой – не более чем на 1 час.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78353,
                    "questionId": 7075,
                    "name": "Не более чем на 30 минут, независимо от окружающей температуры.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7049,
            "name": "Сохраняет ли право на получение оружия работник юридического лица с особыми уставными задачами, не прошедший периодическую проверку на пригодность к действиям в условиях, связанных с применением огнестрельного оружия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78273,
                    "questionId": 7049,
                    "name": "Не сохраняет.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78274,
                    "questionId": 7049,
                    "name": "Сохраняет, на основании приказа руководителя учреждения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78275,
                    "questionId": 7049,
                    "name": "Сохраняет.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7160,
            "name": "Каков калибр пистолета ПМ-9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78606,
                    "questionId": 7160,
                    "name": "5,45 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78607,
                    "questionId": 7160,
                    "name": "7,62 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78608,
                    "questionId": 7160,
                    "name": "9 мм.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7025,
            "name": "Запрещается ли на территории Российской Федерации ношение аэрозольных устройств, снаряженных слезоточивыми веществами при проведении уличных шествий, демонстраций, митингов и других массовых публичных мероприятий?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78201,
                    "questionId": 7025,
                    "name": "На территории Российской Федерации ношение аэрозольных устройств, снаряженных слезоточивыми веществами при проведении уличных шествий, демонстраций, митингов и других массовых публичных мероприятий запрещается.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78202,
                    "questionId": 7025,
                    "name": "На территории Российской Федерации ношение аэрозольных устройств, снаряженных слезоточивыми веществами при проведении уличных шествий, демонстраций, митингов и других массовых публичных мероприятий разрешается при условии возможных массовых беспорядков.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78203,
                    "questionId": 7025,
                    "name": "На территории Российской Федерации ношение аэрозольных устройств, снаряженных слезоточивыми веществами при проведении уличных шествий, демонстраций, митингов и других массовых публичных мероприятий разрешается работникам юридического лица с особыми уставными задачами, если они привлекаются органами внутренних дел к охране общественного порядка при проведении указанных мероприятий.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7204,
            "name": "Как следует производить перезарядку служебного (гладкоствольного длинноствольного) ружья с помповым механизмом?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78735,
                    "questionId": 7204,
                    "name": "Быстро назад и медленно вперед.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78736,
                    "questionId": 7204,
                    "name": "Быстрым движением цевья назад, и не задерживая в заднем положении, быстрым вперед.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78737,
                    "questionId": 7204,
                    "name": "Медленно назад и быстро вперед.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7106,
            "name": "Каким способом проверяется фиксация замков наручников, не угрожающая нормальному кровообращению у правонарушителя?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78444,
                    "questionId": 7106,
                    "name": "Визуальным осмотром конечностей правонарушителя на предмет посинения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78445,
                    "questionId": 7106,
                    "name": "Периодическим открытием и закрытием замка наручников.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78446,
                    "questionId": 7106,
                    "name": "Проверкой возможности браслетов наручников без затруднений поворачиваться на конечностях правонарушителя.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7176,
            "name": "Каков калибр автомата АКМС?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78654,
                    "questionId": 7176,
                    "name": "5,45 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78655,
                    "questionId": 7176,
                    "name": "7,62 мм.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78656,
                    "questionId": 7176,
                    "name": "9 мм.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7156,
            "name": "Из скольких основных частей и механизмов состоит ПМ-9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78594,
                    "questionId": 7156,
                    "name": "18 частей и механизмов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78595,
                    "questionId": 7156,
                    "name": "5 частей и механизмов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78596,
                    "questionId": 7156,
                    "name": "7 частей и механизмов.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7048,
            "name": "Какое оружие разрешается использовать работнику юридического лица с особыми уставными задачами?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78270,
                    "questionId": 7048,
                    "name": "Боевое оружие, не зарегистрированное в территориальных органах Федеральной службы войск национальной гвардии Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78271,
                    "questionId": 7048,
                    "name": "Закрепленное за ним боевое оружие при наличии соответствующего разрешения на хранение и ношение данного оружия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78272,
                    "questionId": 7048,
                    "name": "Огнестрельное оружие, величина дульной энергии которого не превышает 3,5 Дж. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7017,
            "name": "Возможно ли из служебного оружия, при емкости магазина не более 5 патронов, ведение огня очередями?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78177,
                    "questionId": 7017,
                    "name": "Допускается ведение огня очередями только из нарезного служебного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78178,
                    "questionId": 7017,
                    "name": "Из служебного оружия, возможно ведение огня очередями только при емкости магазина более 10 патронов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78179,
                    "questionId": 7017,
                    "name": "Служебное оружие должно исключать ведение огня очередями.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7120,
            "name": "При эксплуатации электрошоковых устройств (ЭШУ) необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78486,
                    "questionId": 7120,
                    "name": "Еженедельно зачищать рабочие электроды наждачной бумагой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78487,
                    "questionId": 7120,
                    "name": "Избегать контакта рабочих электродов в области низа живота, поясницы и ягодиц объекта воздействия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78488,
                    "questionId": 7120,
                    "name": "Избегать контакта рабочих электродов в области сердца, головы, шеи и солнечного сплетения объекта воздействия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7108,
            "name": "Палка резиновая ПУС-3, разрешенная для использования работниками юридического лица с особыми уставными задачами выпускается в следующих вариантах:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78450,
                    "questionId": 7108,
                    "name": "Произвольная и штатная.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78451,
                    "questionId": 7108,
                    "name": "Прямая и с боковой ручкой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78452,
                    "questionId": 7108,
                    "name": "Раскладная и телескопическая.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7055,
            "name": "Работники юридического лица с особыми уставными задачами имеют право требовать от персонала и посетителей объектов охраны соблюдения внутриобъектового и пропускного режимов:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78291,
                    "questionId": 7055,
                    "name": "При обеспечении внутриобъектового и пропускного режимов, а также при транспортировке охраняемых грузов, денежных средств и иного имущества в пределах объекта охраны.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78292,
                    "questionId": 7055,
                    "name": "При обеспечении любых охранных мероприятий, предусмотренных законодательством.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78293,
                    "questionId": 7055,
                    "name": "При осуществлении обязанностей по защите жизни и здоровья граждан.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7094,
            "name": "Положение пострадавшего при проведении сердечно-легочной реанимации:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78408,
                    "questionId": 7094,
                    "name": "В том положении, в котором был обнаружен пострадавший (колени реанимирующего выше уровня спины пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78409,
                    "questionId": 7094,
                    "name": "На спине на кровати (колени реанимирующего ниже уровня спины пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78410,
                    "questionId": 7094,
                    "name": "На спине, на ровной жесткой поверхности (колени реанимирующего на уровне спины пострадавшего). ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7079,
            "name": "Что в первую очередь может помочь при возникновении не проходящих в покое острых болей за грудиной (в области сердца)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78363,
                    "questionId": 7079,
                    "name": "Измерение давления и частоты пульса.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78364,
                    "questionId": 7079,
                    "name": "Обеспечение физической нагрузки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78365,
                    "questionId": 7079,
                    "name": "Прием нитроглицерина под язык (только если пострадавший знает о своей болезни и имеет его при себе). ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7050,
            "name": "Какая особенность рикошетирования пули может использоваться и должна учитываться участником огневого контакта в его интересах?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78276,
                    "questionId": 7050,
                    "name": "Возможность избежать поражения противником из-за самой способности пули рикошетировать от препятствий.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78277,
                    "questionId": 7050,
                    "name": "Возможность поражения с помощью рикошета цели, находящейся за укрытием.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78278,
                    "questionId": 7050,
                    "name": "Потеря пулей опасности после рикошета.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7141,
            "name": "По своему назначению выбрасыватель пистолета служит:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78549,
                    "questionId": 7141,
                    "name": "Для извлечения магазина из рукоятки пистолета.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78550,
                    "questionId": 7141,
                    "name": "Для отвода неизрасходованной части пороховых газов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78551,
                    "questionId": 7141,
                    "name": "Для удержания гильзы (патрона) в чашечке затвора до встречи с отражателем.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7155,
            "name": "Элементом какой основной части или механизма является шептало в ПМ- 9 мм?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78591,
                    "questionId": 7155,
                    "name": "Затвора.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78592,
                    "questionId": 7155,
                    "name": "Рамки со стволом.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78593,
                    "questionId": 7155,
                    "name": "Ударно-спускового механизма.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7081,
            "name": "Какие действия проводятся при проникающем ранении грудной клетки (с выходом воздуха в плевральную полость)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78369,
                    "questionId": 7081,
                    "name": "Придание возвышенного положения, первоначальное закрытие раны ладонью, закрытие раны повязкой, обеспечивающей фиксацию грудной клетки пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78370,
                    "questionId": 7081,
                    "name": "Придание возвышенного положения, первоначальное закрытие раны ладонью, затем закрытие раны повязкой, не пропускающей воздух - с использованием индивидуального перевязочного пакета, иного полиэтиленового пакета и т.п.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78371,
                    "questionId": 7081,
                    "name": "Придание пострадавшему положения «на спине» первоначальное закрытие раны ладонью, затем закрытие раны повязкой, не пропускающей воздух - с использованием индивидуального перевязочного пакета, иного полиэтиленового пакета и т.п.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6974,
            "name": "В соответствии с действующим законодательством метательное оружие это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78048,
                    "questionId": 6974,
                    "name": "Оружие, предназначенное для поражения цели на расстоянии, метаемое с использованием мускульной силы человека (метательное бросковое оружие), а также оружие, предназначенное для поражения цели на расстоянии снарядами, метаемыми с использованием механической энергии.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78049,
                    "questionId": 6974,
                    "name": "Устройства, предназначенные для поражения цели на расстоянии снарядом, получающим направленное движение за счет энергии сжатого, сжиженного или отвержденного газа.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78050,
                    "questionId": 6974,
                    "name": "Устройства, предназначенные для поражения цели на расстоянии снарядом, получающим направленное движение за счет энергии сжатой пружины.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7008,
            "name": "В организациях с особыми уставными задачами должности, связанные с учетом, хранением, выдачей или использованием огнестрельного оружия, боеприпасов и патронов к огнестрельному оружию, не могут замещать:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78150,
                    "questionId": 7008,
                    "name": "Лица, которые имеют неснятую или непогашенную судимость за преступление, совершенное умышленно, либо в отношении которых судом установлен запрет замещать такие должности или заниматься определенной профессиональной или предпринимательской деятельностью в сфере оборота оружия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78151,
                    "questionId": 7008,
                    "name": "Лица, которые по своему состоянию здоровья требуют ежегодной реабилитации в учреждениях санаторно-курортного лечения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78152,
                    "questionId": 7008,
                    "name": "Лица, уволенные с государственной службы.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6972,
            "name": "В соответствии с действующим законодательством к основным частям огнестрельного оружия относятся:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78042,
                    "questionId": 6972,
                    "name": "Курок, ударник, спусковой механизм, прицельное приспособление.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78043,
                    "questionId": 6972,
                    "name": "Совокупность механизмов и деталей, обеспечивающих производство выстрела.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78044,
                    "questionId": 6972,
                    "name": "Ствол, затвор, барабан, рамка, ствольная коробка.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7026,
            "name": "Разрешается ли на территории Российской Федерации ношение электрошоковых устройств отечественного производства при проведении демонстраций, пикетирования, уличных шествий или иных массовых публичных мероприятий?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78204,
                    "questionId": 7026,
                    "name": "На территории Российской Федерации ношение электрошоковых устройств отечественного производства при проведении уличных шествий, демонстраций, митингов и других массовых публичных мероприятий запрещается.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78205,
                    "questionId": 7026,
                    "name": "На территории Российской Федерации ношение электрошоковых устройств отечественного производства при проведении уличных шествий, демонстраций, митингов и других массовых публичных мероприятий разрешается при условии возможного возникновения массовых беспорядков.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78206,
                    "questionId": 7026,
                    "name": "На территории Российской Федерации при проведении уличных шествий, демонстраций, митингов и других массовых публичных мероприятий разрешается ношение электрошоковых устройств импортного производства только при условии возможного возникновения массовых беспорядков и предварительного согласования с органами внутренних дел.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7100,
            "name": "Промывание желудка при отравлении в порядке первой помощи (немедицинским персоналом и без желудочного зонда) запрещено:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78426,
                    "questionId": 7100,
                    "name": "При отравлениях кислотами, щелочами, нефтепродуктами, при судорогах, в случае потери сознания пострадавшим.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78427,
                    "questionId": 7100,
                    "name": "При отравлениях у лиц, не имеющих при себе документов, удостоверяющих личность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78428,
                    "questionId": 7100,
                    "name": "При отравлениях у несовершеннолетних детей.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7038,
            "name": "Причинение вреда, менее значительного, чем предотвращенный вред, является обязательным условием правомерности действий:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78240,
                    "questionId": 7038,
                    "name": "В состоянии крайней необходимости.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78241,
                    "questionId": 7038,
                    "name": "В состоянии необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78242,
                    "questionId": 7038,
                    "name": "Как в состоянии необходимой обороны, так и в состоянии крайней необходимости.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7133,
            "name": "Как определяется термин «короткоствольное оружие» в соответствии с действующим государственным стандартом?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78525,
                    "questionId": 7133,
                    "name": "Оружие с длиной ствола (стволов) не более 150 мм и общей длиной не более 300 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78526,
                    "questionId": 7133,
                    "name": "Оружие с длиной ствола (стволов) не более 200 мм и общей длиной не более 400 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78527,
                    "questionId": 7133,
                    "name": "Оружие с длиной ствола (стволов) не более 300 мм и общей длиной не более 600 мм.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7047,
            "name": "Нарушение правил производства, продажи, хранения или учета оружия и патронов к нему - влечет наложение административного штрафа:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78267,
                    "questionId": 7047,
                    "name": "Административному взысканию подвергается должностное и (или) юридическое лицо.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78268,
                    "questionId": 7047,
                    "name": "К ответственности привлекаются только должностные лица.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78269,
                    "questionId": 7047,
                    "name": "На любого работника организации, допустившего правонарушение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7051,
            "name": "Для эффективного поражения цели предполагается ведение огня (в зависимости от дистанции):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78279,
                    "questionId": 7051,
                    "name": "На дистанции, не превышающей максимальную дальность полета пули из данного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78280,
                    "questionId": 7051,
                    "name": "На дистанции, не превышающей рекомендуемую для данного оружия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78281,
                    "questionId": 7051,
                    "name": "На любой дистанции (в том числе и превышающей рекомендуемую для данного оружия).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7125,
            "name": "Обязан ли работник юридического лица с особыми уставными задачами незамедлительно информировать органы внутренних дел в случае, если он произвел выстрел в воздух для предупреждения о намерении применить оружие?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78501,
                    "questionId": 7125,
                    "name": "Не обязан.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78502,
                    "questionId": 7125,
                    "name": "Обязан информировать орган внутренних дел по месту применения оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78503,
                    "questionId": 7125,
                    "name": "Обязан информировать органы внутренних дел по месту нахождения юридического лица с особыми уставными задачами.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7073,
            "name": "Какова правильная последовательность действий при остановке артериального кровотечения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78345,
                    "questionId": 7073,
                    "name": "Накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78346,
                    "questionId": 7073,
                    "name": "Проводится пальцевая остановка кровотечения, накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78347,
                    "questionId": 7073,
                    "name": "Проводится пальцевая остановка кровотечения, накладывается чистая повязка, накладывается жгут (скрутка, ремень), указывается время наложения жгута",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7187,
            "name": "В случае задержки при стрельбе из пистолета в тире необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78684,
                    "questionId": 7187,
                    "name": "Не производить никаких действий с оружием и удерживая его в направлении мишени, доложить руководителю стрельб (инструктору) о задержке и действовать по его команде.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78685,
                    "questionId": 7187,
                    "name": "Осторожно вынуть магазин из основания рукоятки, устранить причину задержки, продолжить выполнение упражнения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78686,
                    "questionId": 7187,
                    "name": "Поставить оружие на предохранитель, вынуть магазин из основания рукоятки, сдать оружие руководителю стрельб (инструктору).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7020,
            "name": "Запрещается ли на территории Российской Федерации оборот газового оружия, снаряженного нервно-паралитическими и отравляющими веществами?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78186,
                    "questionId": 7020,
                    "name": "На территории Российской Федерации запрещен оборот газового оружия только снаряженного нервно-паралитическими и отравляющими веществами, которые запрещаются к применению федеральным органом исполнительной власти в сфере внутренних дел.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78187,
                    "questionId": 7020,
                    "name": "На территории Российской Федерации оборот газового оружия, снаряженного нервно-паралитическими и другими отравляющими веществами разрешен в том случае, если это оружие состоит на вооружении юридического лица с особыми уставными задачами.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78188,
                    "questionId": 7020,
                    "name": "На территории Российской Федерации оборот газового оружия, снаряженного нервно-паралитическими, отравляющими, а также другими веществами, не разрешенными к применению федеральным органом исполнительной власти, осуществляющим функции по выработке государственной политики и нормативно-правовому регулированию в сфере здравоохранения запрещен.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7181,
            "name": "Безопасное использование оружия предполагает в период непосредственного применения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78666,
                    "questionId": 7181,
                    "name": "Держать указательный палец вдоль спусковой скобы, переставляя его на спусковой крючок только перед выстрелом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78667,
                    "questionId": 7181,
                    "name": "Держать указательный палец всегда на спусковом крючке.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78668,
                    "questionId": 7181,
                    "name": "Удерживая пистолет двумя руками, всегда держать указательные пальцы (один на другом) на спусковом крючке.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6997,
            "name": "Дульная энергия при выстреле из служебного огнестрельного оружия ограниченного поражения патронами травматического действия не должна превышать:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78117,
                    "questionId": 6997,
                    "name": "150 Дж.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78118,
                    "questionId": 6997,
                    "name": "300 Дж.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78119,
                    "questionId": 6997,
                    "name": "450 Дж.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7086,
            "name": "Что необходимо сделать при ожоговой ране?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78384,
                    "questionId": 7086,
                    "name": "Наложить чистую увлажненную повязку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78385,
                    "questionId": 7086,
                    "name": "Очистить рану и промыть ее холодной водой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78386,
                    "questionId": 7086,
                    "name": "Смазать рану маслом, наложить повязку.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7122,
            "name": "В каких случаях работник юридического лица с особыми уставными задачами может не предупреждать о намерении применить огнестрельное оружие?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78492,
                    "questionId": 7122,
                    "name": "В любом случае, независимо от создавшейся ситуации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78493,
                    "questionId": 7122,
                    "name": "В случае если есть предпосылки или догадки о том, что может сложиться ситуация, которая повлечет собой угрозу для жизни.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78494,
                    "questionId": 7122,
                    "name": "В случае, если промедление в применении оружия создает непосредственную опасность для жизни.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6982,
            "name": "В соответствии с действующим законодательством патрон травматического действия это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78072,
                    "questionId": 6982,
                    "name": "Патрон, предназначенный для выстрела из травматического оружия и не предназначенный для причинения смерти человеку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78073,
                    "questionId": 6982,
                    "name": "Устройство, предназначенное для выстрела из огнестрельного гладкоствольного или бесствольного оружия, содержащее метаемое снаряжение травматического действия, не предназначенное для причинения смерти человеку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78074,
                    "questionId": 6982,
                    "name": "Устройство, предназначенное для выстрела из огнестрельного гладкоствольного оружия или огнестрельного оружия ограниченного поражения, объединяющее в одно целое при помощи гильзы средства инициирования, метательный заряд и метаемое снаряжение травматического действия и не предназначенное для причинения смерти человеку.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7182,
            "name": "Безопасное использование оружия предполагает в период непосредственного применения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78669,
                    "questionId": 7182,
                    "name": "Не отвлекаться на расчет траектории выстрела (в части исключения вреда посторонним лицам и/или вреда их имуществу).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78670,
                    "questionId": 7182,
                    "name": "Обязательно рассчитывать траекторию выстрела для исключения вреда посторонним лицам, а по возможности и их имуществу.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78671,
                    "questionId": 7182,
                    "name": "Обязательно рассчитывать траекторию выстрела для исключения вреда посторонним лицам.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7142,
            "name": "По своему назначению боевая пружина пистолета служит:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78552,
                    "questionId": 7142,
                    "name": "Для возвращения затвора в крайнее переднее положение после выстрела.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78553,
                    "questionId": 7142,
                    "name": "Для досылания патрона в патронник.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78554,
                    "questionId": 7142,
                    "name": "Для приведения в действие курка, рычага взвода и спусковой тяги.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7080,
            "name": "В каком порядке проводятся мероприятия первой помощи при ранении?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78366,
                    "questionId": 7080,
                    "name": "Обеззараживание раны (при возможности), наложение повязки, остановка кровотечения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78367,
                    "questionId": 7080,
                    "name": "Остановка кровотечения, наложение повязки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78368,
                    "questionId": 7080,
                    "name": "Остановка кровотечения, обеззараживание раны (при возможности), наложение повязки.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7172,
            "name": "Какова ёмкость шнекового магазина пистолета-пулемёта «Бизон-2»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78642,
                    "questionId": 7172,
                    "name": "20 патронов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78643,
                    "questionId": 7172,
                    "name": "30 патронов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78644,
                    "questionId": 7172,
                    "name": "64 патрона.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6973,
            "name": "В соответствии с действующим законодательством холодное оружие это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78045,
                    "questionId": 6973,
                    "name": "Оружие, предназначенное для поражения цели на расстоянии снарядом, получающим направленное движение при помощи мускульной силы человека.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78046,
                    "questionId": 6973,
                    "name": "Оружие, предназначенное для поражения цели при помощи механического устройства при непосредственном контакте с объектом поражения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78047,
                    "questionId": 6973,
                    "name": "Оружие, предназначенное для поражения цели при помощи мускульной силы человека при непосредственном контакте с объектом поражения.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7063,
            "name": "Какие из мероприятий по оценке обстановки и обеспечению безопасных условий для оказания первой помощи совершаются в порядке осмотра места происшествия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78315,
                    "questionId": 7063,
                    "name": "Определение угрожающих факторов для собственной жизни и здоровья; определение угрожающих факторов для жизни и здоровья пострадавшего; оценка количества пострадавших.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78316,
                    "questionId": 7063,
                    "name": "Придание правильного транспортного положения и организация транспортировки пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78317,
                    "questionId": 7063,
                    "name": "Устранение угрожающих факторов для жизни и здоровья; прекращение действия повреждающих факторов на пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7088,
            "name": "При повреждении костей плеча или бедра шину накладывают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78390,
                    "questionId": 7088,
                    "name": "С захватом только верхнего (по отношению к месту перелома) сустава.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78391,
                    "questionId": 7088,
                    "name": "С захватом только двух суставов (выше и ниже места перелома).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78392,
                    "questionId": 7088,
                    "name": "С захватом трех суставов (двух ниже и одного выше места перелома).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7034,
            "name": "Защита от посягательства, не сопряженного с насилием, опасным для жизни обороняющегося или другого лица, либо с непосредственной угрозой применения такого насилия:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78228,
                    "questionId": 7034,
                    "name": "Квалифицируется как умышленное преступление.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78229,
                    "questionId": 7034,
                    "name": "Считается неправомерной и расценивается как превышение пределов необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78230,
                    "questionId": 7034,
                    "name": "Считается правомерной, если при этом не было допущено превышения пределов необходимой обороны, то есть умышленных действий, явно несоответствующих характеру и опасности посягательства.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7101,
            "name": "Предусмотрено ли нормативными правовыми актами Российской Федерации применение работником юридического лица с особыми уставными задачами специальных средств для отражения нападения, непосредственно угрожающего жизни и здоровью лиц, находящихся на объекте?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78429,
                    "questionId": 7101,
                    "name": "Да, предусмотрено.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78430,
                    "questionId": 7101,
                    "name": "Нет, не предусмотрено.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78431,
                    "questionId": 7101,
                    "name": "Предусмотрено только в состоянии необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7082,
            "name": "Какие правила оказания первой помощи соблюдаются при проникающем ранении в брюшную полость?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78372,
                    "questionId": 7082,
                    "name": "Не давать пострадавшему пить жидкость, извлечь инородное тело, накрыть рану стерильной салфеткой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78373,
                    "questionId": 7082,
                    "name": "Не давать пострадавшему пить жидкость, не извлекать инородное тело, прикрыть рану стерильным перевязочным материалом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78374,
                    "questionId": 7082,
                    "name": "Приподнять голову, дать сладкое теплое питье, накрыть стерильной салфеткой и положить холод на рану.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7110,
            "name": "Палка резиновая ПР-73М, разрешенная для использования работниками юридического лица с особыми уставными задачами имеет в своей конструкции:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78456,
                    "questionId": 7110,
                    "name": "Боковую ручку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78457,
                    "questionId": 7110,
                    "name": "Выступающий кольцевой элемент (мини-гарду) рукоятки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78458,
                    "questionId": 7110,
                    "name": "Металлический наконечник.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7127,
            "name": "В каких случаях работнику юридического лица с особыми уставными задачами не запрещается применять огнестрельное оружие в отношении женщин, лиц с явными признаками инвалидности и несовершеннолетних, возраст которых ему очевиден или известен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78507,
                    "questionId": 7127,
                    "name": "В случае оказания ими группового сопротивления или нападения угрожающего работникам юридического лица с особыми уставными задачами или охраняемому имуществу.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78508,
                    "questionId": 7127,
                    "name": "В случае оказания указанными лицами вооруженного или группового сопротивления работникам юридического лица с особыми уставными задачами, совершения вооруженного или группового нападения на охраняемые объекты, угрожающего жизни или здоровью работников юридического лица с особыми уставными задачами либо лиц, находящихся на охраняемых объектах. ",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78509,
                    "questionId": 7127,
                    "name": "В случае отказа выполнить требование работника юридического лица с особыми уставными задачами проследовать в помещение охраны.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7078,
            "name": "Что надо сделать при возникновении не проходящих в покое острых болей за грудиной (в области сердца)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78360,
                    "questionId": 7078,
                    "name": "Немедленно вызвать «Скорую помощь», обеспечить пострадавшему полный покой в полусидячем положении, обеспечить приток воздуха.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78361,
                    "questionId": 7078,
                    "name": "Положить пострадавшего на спину, укутать одеялом, вызвать «Скорую помощь».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78362,
                    "questionId": 7078,
                    "name": "Посадить пострадавшего, обеспечить приток свежего воздуха, положить на грудь холод, вызвать «Скорую помощь».",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7018,
            "name": "Может ли служебное оружие, в котором исключена возможность ведения огня очередями, иметь магазин емкостью в 15 патронов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78180,
                    "questionId": 7018,
                    "name": "Емкость магазина служебного оружия более 10 патронов допускается.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78181,
                    "questionId": 7018,
                    "name": "Емкость магазина служебного оружия должна быть не более 10 патронов.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78182,
                    "questionId": 7018,
                    "name": "Служебное оружие должно иметь магазин не более 5 патронов.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7129,
            "name": "Правилами оборота гражданского и служебного оружия на территории Российской Федерации установлен следующий порядок ношения огнестрельного короткоствольного оружия:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78513,
                    "questionId": 7129,
                    "name": "В кобуре, с патроном в патроннике, поставленным на предохранитель (при наличии).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78514,
                    "questionId": 7129,
                    "name": "В кобуре, с патроном в патроннике, со взведенным курком.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78515,
                    "questionId": 7129,
                    "name": "В кобуре, со снаряженным магазином или барабаном, поставленным на предохранитель (при наличии).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7193,
            "name": "Контрольный спуск курка оружия (в тире, на стрельбище, после его применения при исполнении служебных обязанностей) производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78702,
                    "questionId": 7193,
                    "name": "После осмотра оружия руководителем стрельбы (вышестоящим начальником, иным уполномоченным лицом).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78703,
                    "questionId": 7193,
                    "name": "После разряжания оружия (до процедуры его осмотра).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78704,
                    "questionId": 7193,
                    "name": "Сразу после временного прекращения стрельбы.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6971,
            "name": "В соответствии с действующим законодательством огнестрельное оружие это:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78039,
                    "questionId": 6971,
                    "name": "Оружие, в котором для метания поражающего элемента используется энергия газов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78040,
                    "questionId": 6971,
                    "name": "Оружие, предназначенное для механического поражения цели на расстоянии метаемым снаряжением, получающим направленное движение за счет энергии порохового или иного заряда.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78041,
                    "questionId": 6971,
                    "name": "Устройства, предназначенные для механического поражения цели на расстоянии снарядом, получившим направленное движение за счет мускульной силы человека.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7016,
            "name": "Должно ли служебное оружие отличаться от боевого ручного стрелкового оружия по типам патронов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78174,
                    "questionId": 7016,
                    "name": "Для служебного и боевого ручного стрелкового оружия могут использоваться патроны одного типа.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78175,
                    "questionId": 7016,
                    "name": "Нарезное служебное оружие должно иметь отличия от боевого ручного стрелкового оружия по типам и размерам патрона.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78176,
                    "questionId": 7016,
                    "name": "При стрельбе из служебного оружия и боевого ручного стрелкового оружия могут использоваться патроны одного типа, но при этом они должны различаться по следообразованию на гильзе.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7072,
            "name": "Способы временной остановки кровотечения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78342,
                    "questionId": 7072,
                    "name": "Пальцевое прижатие, максимальное сгибание конечности, наложение жгута (закрутки), наложение давящей повязки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78343,
                    "questionId": 7072,
                    "name": "Придание возвышенного положения конечности, наложение асептической повязки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78344,
                    "questionId": 7072,
                    "name": "Частичное сгибание конечности, наложение пластыря, наложение давящей повязки.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7032,
            "name": "Могут ли действия работника юридического лица с особыми уставными задачами по защите жизни и здоровья другого лица расцениваться как действия в состоянии необходимой обороны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78222,
                    "questionId": 7032,
                    "name": "Могут, если соблюдены условия необходимой обороны, предусмотренные законом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78223,
                    "questionId": 7032,
                    "name": "Могут, только если при указанном лице находилось охраняемое имущество.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78224,
                    "questionId": 7032,
                    "name": "Не могут ни при каких условиях.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7095,
            "name": "При проведении ИВЛ (искусственной вентиляции легких) методом «рот ко рту» необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78411,
                    "questionId": 7095,
                    "name": "Зажимать нос пострадавшего только в случае, если носовые ходы свободны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78412,
                    "questionId": 7095,
                    "name": "Нос пострадавшему не зажимать.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78413,
                    "questionId": 7095,
                    "name": "Свободной рукой плотно зажимать нос пострадавшего.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7174,
            "name": "Каков калибр пистолета-пулемета ПП-93?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78648,
                    "questionId": 7174,
                    "name": "5,45 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78649,
                    "questionId": 7174,
                    "name": "7,62 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78650,
                    "questionId": 7174,
                    "name": "9 мм.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7169,
            "name": "Какова боевая скорострельность очередями пистолета-пулемёта ОЦ-2 «Кипарис»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78633,
                    "questionId": 7169,
                    "name": "1000 выстрелов в минуту.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78634,
                    "questionId": 7169,
                    "name": "50 выстрелов в минуту.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78635,
                    "questionId": 7169,
                    "name": "800 выстрелов в минуту.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7104,
            "name": "Как меняется время непрерывного ношения бронежилета (жилета защитного) при понижении температуры воздуха:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78438,
                    "questionId": 7104,
                    "name": "Остается неизменным.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78439,
                    "questionId": 7104,
                    "name": "Увеличивается.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78440,
                    "questionId": 7104,
                    "name": "Уменьшается.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7010,
            "name": "Требования к условиям хранения различных видов гражданского и служебного оружия и патронов к нему определяются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78156,
                    "questionId": 7010,
                    "name": "Правительством Российской Федерации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78157,
                    "questionId": 7010,
                    "name": "Президентом Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78158,
                    "questionId": 7010,
                    "name": "Федеральной службой войск национальной гвардии Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7058,
            "name": "При выявлении нарушений, создающих на охраняемых объектах угрозу возникновения пожаров и безопасности людей, работник юридического лица с особыми уставными задачами имеет право:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78300,
                    "questionId": 7058,
                    "name": "Давать обязательные для исполнения указания руководству охраняемого объекта по устранению выявленных нарушений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78301,
                    "questionId": 7058,
                    "name": "Покинуть охраняемый объект, удалившись на безопасное расстояние.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78302,
                    "questionId": 7058,
                    "name": "Принимать меры по пресечению указанных нарушений.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7057,
            "name": "Работникам юридического лица с особыми уставными задачами запрещается препятствовать:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78297,
                    "questionId": 7057,
                    "name": "Действиям должностных лиц правоохранительных и контролирующих органов, независимо от того, осуществляют ли они свою служебную деятельность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78298,
                    "questionId": 7057,
                    "name": "Законным действиям должностных лиц правоохранительных и контролирующих органов при осуществлении указанными лицами своих должностных обязанностей.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78299,
                    "questionId": 7057,
                    "name": "Любым действиям должностных лиц правоохранительных и контролирующих органов при осуществлении указанными должностными лицами своей деятельности.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6990,
            "name": "Какое из перечисленных ниже изделий не относится к оружию?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78096,
                    "questionId": 6990,
                    "name": "Монтажные пистолеты производственного назначения.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78097,
                    "questionId": 6990,
                    "name": "Спортивное холодное клинковое оружие.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78098,
                    "questionId": 6990,
                    "name": "Электрошоковые устройства и искровые разрядники отечественного производства, имеющие выходные параметры, соответствующие требованиям государственных стандартов Российской Федерации и нормам федерального органа исполнительной власти, осуществляющего функции по выработке государственной политики и нормативно-правовому регулированию в сфере здравоохранения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7171,
            "name": "Каков калибр пистолета-пулемёта «Бизон-2»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78639,
                    "questionId": 7171,
                    "name": "5,45 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78640,
                    "questionId": 7171,
                    "name": "7,62 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78641,
                    "questionId": 7171,
                    "name": "9 мм.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7116,
            "name": "Какие вещества (материалы) запрещается хранить совместно с бронежилетами и бронешлемами (жилетами и шлемами защитными)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78474,
                    "questionId": 7116,
                    "name": "Гидросорбенты (влагопоглотители).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78475,
                    "questionId": 7116,
                    "name": "Масла и кислоты.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78476,
                    "questionId": 7116,
                    "name": "Резиновые изделия (резину).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7190,
            "name": "Действия по временному прекращению стрельбы в тире, на стрельбище и при исполнении служебных обязанностей работника юридического лица с особыми уставными задачами:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78693,
                    "questionId": 7190,
                    "name": "Прекратить нажим на хвост спускового крючка; включить предохранитель (если таковой имеется); при необходимости – перезарядить оружие.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78694,
                    "questionId": 7190,
                    "name": "Прекратить нажим на хвост спускового крючка; извлечь магазин, произвести контрольный спуск курка (в условиях безопасности по направлению возможного выстрела), включить предохранитель (если таковой имеется).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78695,
                    "questionId": 7190,
                    "name": "Прекратить нажим на хвост спускового крючка; извлечь магазин, произвести контрольный спуск курка (в условиях безопасности по направлению возможного выстрела).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7145,
            "name": "По своему назначению затвор пистолета служит:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78561,
                    "questionId": 7145,
                    "name": "Для подачи патрона из магазина в патронник, запирания канала ствола при выстреле, удержания гильзы (извлечения патрона) и постановки курка на боевой взвод.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78562,
                    "questionId": 7145,
                    "name": "Для подачи патрона из магазина в патронник, фиксации ствола при выстреле, отвода неизрасходованных пороховых газов и извлечения гильзы после выстрела, для постановки курка на предохранительный взвод.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78563,
                    "questionId": 7145,
                    "name": "Для соединения всех частей пистолета.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7071,
            "name": "Правильная транспортировка пострадавшего, находящегося без сознания (за исключением случаев, когда в связи с подозрением на травму позвоночника менять положение тела не рекомендуется) производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78339,
                    "questionId": 7071,
                    "name": "В положении на боку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78340,
                    "questionId": 7071,
                    "name": "В положении на спине.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78341,
                    "questionId": 7071,
                    "name": "В положении с приподнятыми нижними конечностями.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7037,
            "name": "Вред, причиненный в состоянии крайней необходимости:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78237,
                    "questionId": 7037,
                    "name": "Во всех случаях подлежит возмещению в полном объеме лицом, причинившим вред.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78238,
                    "questionId": 7037,
                    "name": "Не подлежит возмещению.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78239,
                    "questionId": 7037,
                    "name": "Подлежит возмещению по решению суда.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7124,
            "name": "Разрешается ли работнику юридического лица с особыми уставными задачами применять, находящееся на законных основаниях, боевое оружие для подачи сигнала тревоги?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78498,
                    "questionId": 7124,
                    "name": "Не разрешается.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78499,
                    "questionId": 7124,
                    "name": "Разрешается, если указанное лицо находится в условиях плохой видимости.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78500,
                    "questionId": 7124,
                    "name": "Разрешается.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7096,
            "name": "При проведении ИВЛ (искусственной вентиляции легких) методом «рот к носу» необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78414,
                    "questionId": 7096,
                    "name": "Не проводить никаких манипуляций с нижней челюстью пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78415,
                    "questionId": 7096,
                    "name": "Свободной рукой открывать рот пострадавшего для обеспечения выдоха.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78416,
                    "questionId": 7096,
                    "name": "Свободной рукой плотно удерживать нижнюю челюсть пострадавшего, чтобы его рот был закрыт.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7087,
            "name": "При повреждении костей предплечья или голени шину накладывают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78387,
                    "questionId": 7087,
                    "name": "С захватом двух суставов (выше и ниже места перелома).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78388,
                    "questionId": 7087,
                    "name": "С захватом только верхнего (по отношению к месту перелома) сустава.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78389,
                    "questionId": 7087,
                    "name": "С захватом трех суставов.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7150,
            "name": "Какая из названных частей входит в основные части механизма пистолета ИЖ-71?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78576,
                    "questionId": 7150,
                    "name": "Досылатель.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78577,
                    "questionId": 7150,
                    "name": "Затворная задержка.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78578,
                    "questionId": 7150,
                    "name": "Предохранитель.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7167,
            "name": "Какова масса пистолета-пулемета ПП-91 «Кедр» без патронов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78627,
                    "questionId": 7167,
                    "name": "0,54 кг.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78628,
                    "questionId": 7167,
                    "name": "1,54 кг.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78629,
                    "questionId": 7167,
                    "name": "2,54 кг.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7199,
            "name": "Чистка и смазка оружия (пистолетов, револьверов, ружей и карабинов), находящегося без употребления, производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78720,
                    "questionId": 7199,
                    "name": "Ежедневно.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78721,
                    "questionId": 7199,
                    "name": "Не реже одного раза в месяц.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78722,
                    "questionId": 7199,
                    "name": "Не реже одного раза в неделю.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7115,
            "name": "Бронежилеты и бронешлемы (жилеты и шлемы защитные), за исключением изготовленных специально для особых условий эксплуатации, могут терять свои свойства:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78471,
                    "questionId": 7115,
                    "name": "При воздействии ультрафиолетового излучения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78472,
                    "questionId": 7115,
                    "name": "При намокании.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78473,
                    "questionId": 7115,
                    "name": "При температуре +30°С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7114,
            "name": "Какая из палок резиновых, разрешенных для использования работниками юридического лица с особыми уставными задачами имеет наибольший вес (850 гр):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78468,
                    "questionId": 7114,
                    "name": "ПР-73М.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78469,
                    "questionId": 7114,
                    "name": "ПР-К.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78470,
                    "questionId": 7114,
                    "name": "ПР-Т.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7201,
            "name": "Чистка и смазка оружия (пистолетов, револьверов, ружей и карабинов), внесенного с мороза в теплое помещение:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78726,
                    "questionId": 7201,
                    "name": "Производится после того, как оружие «отпотеет» (появятся капли влаги) и влага высохнет.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78727,
                    "questionId": 7201,
                    "name": "Производится, когда оружие «отпотеет» - появятся капли влаги (после этого сразу протирается насухо; начинается его чистка).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78728,
                    "questionId": 7201,
                    "name": "Производится, не дожидаясь, пока оружие начнет «отпотевать» (оружие сразу протирается насухо; начинается его чистка). ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6998,
            "name": "При каких условиях разрешен оборот гражданского огнестрельного длинноствольного оружия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78120,
                    "questionId": 6998,
                    "name": "Если длина ствола со ствольной коробкой 450 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78121,
                    "questionId": 6998,
                    "name": "Если общая длина оружия 750 мм.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78122,
                    "questionId": 6998,
                    "name": "Если общая длина оружия 810 мм.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7112,
            "name": "Какие из палок резиновых, разрешенных для использования работниками юридического лица с особыми уставными задачами имеют наибольшую длину (650 мм):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78462,
                    "questionId": 7112,
                    "name": "ПР-Т и ПР-К.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78463,
                    "questionId": 7112,
                    "name": "ПУС-1 и ПУС-2.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78464,
                    "questionId": 7112,
                    "name": "ПУС-3.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7085,
            "name": "При попадании слезоточивых и раздражающих веществ в глаза необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78381,
                    "questionId": 7085,
                    "name": "Промыть глаза обильной струей теплой воды, затем 2% раствором бикарбоната натрия (соды).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 78382,
                    "questionId": 7085,
                    "name": "Протереть глаза масляным тампоном.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78383,
                    "questionId": 7085,
                    "name": "Протереть глаза сухой ветошью.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7039,
            "name": "Не является преступлением причинение вреда посягающему лицу в состоянии необходимой обороны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 78243,
                    "questionId": 7039,
                    "name": "В случае группового посягательства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78244,
                    "questionId": 7039,
                    "name": "Если посягательство сопряжено с насилием, опасным для здоровья обороняющегося.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 78245,
                    "questionId": 7039,
                    "name": "Если это посягательство было сопряжено с насилием, опасным для жизни обороняющегося или другого лица, либо с непосредственной угрозой применения такого насилия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        }
    ],
    "periodicnypename": "Плановая",
    "guardcatname": "Лица с особыми уставными задачами"
}