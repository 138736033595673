export const QUIZ_GRADE4 = {
    "id": 237020,
    "directionmodeid": 1,
    "name": "Экзамен на месте 1",
    "takerfio": "Петрова Анна Александровна",
    "autorid": 4183,
    "description": "Плановая",
    "questions": [
        {
            "id": 6778,
            "name": "Имеют ли право на необходимую оборону лица, имеющие возможность избежать общественно опасного посягательства или обратиться за помощью к другим лицам или органам власти?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79722,
                    "questionId": 6778,
                    "name": "Да, имеют.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79723,
                    "questionId": 6778,
                    "name": "Имеют, если посягательство сопряжено с насилием, опасным для жизни обороняющегося.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79724,
                    "questionId": 6778,
                    "name": "Нет, не имеют.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6795,
            "name": "Действия по охране места происшествия, связанные с ограничением передвижения людей и транспортных средств, могут производиться частным охранником:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79752,
                    "questionId": 6795,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (для действий на месте совершения любого правонарушения).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79753,
                    "questionId": 6795,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (для действий на месте совершения тяжкого преступления).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79754,
                    "questionId": 6795,
                    "name": "При отсутствии права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в условиях крайней необходимости, когда иным способом невозможно устранить опасность охраняемым законом интересам, а также сохранить следы преступления до прибытия сотрудников правоохранительных органов).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6805,
            "name": "Согласно Типовым упражнениям практического применения специальных средств положительным результатом при выполнении упражнения «Применение наручников» признается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79776,
                    "questionId": 6805,
                    "name": "Правильное надевание наручников (независимо от установленного времени).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79777,
                    "questionId": 6805,
                    "name": "Правильное надевание наручников в пределах установленного времени (независимо от того, смог ли проверяемый снять наручники).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79778,
                    "questionId": 6805,
                    "name": "Правильное надевание наручников в пределах установленного времени и последующее их снятие.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6804,
            "name": "Согласно Типовым упражнениям практического применения специальных средств при выполнении упражнения «Применение наручников» снятие наручников производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79773,
                    "questionId": 6804,
                    "name": "В пределах времени, установленного для выполнения упражнения (25 секунд).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79774,
                    "questionId": 6804,
                    "name": "В пределах времени, установленного для выполнения упражнения или за его пределами (по усмотрению проверяющего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79775,
                    "questionId": 6804,
                    "name": "За пределами времени, установленного для выполнения упражнения (после завершения надевания наручников, доклада проверяемого «Наручники надеты» и проверки правильности надевания наручников проверяющим).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6796,
            "name": "Действия по временному изъятию орудия преступления (до прибытия на место происшествия сотрудников правоохранительных органов) могут производиться частным охранником:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79755,
                    "questionId": 6796,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в случае совершения любого преступления).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79756,
                    "questionId": 6796,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в случае совершения тяжкого преступления).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79757,
                    "questionId": 6796,
                    "name": "При отсутствии права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в условиях крайней необходимости, когда иным способом невозможно устранить опасность охраняемым законом интересам).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6789,
            "name": "В каких случаях частному охраннику не запрещается применять специальные средства в отношении женщин с видимыми признаками беременности, лиц с явными признаками инвалидности и несовершеннолетних, возраст которых охраннику очевиден или известен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79743,
                    "questionId": 6789,
                    "name": "В случае оказания ими вооруженного сопротивления, совершения группового либо иного нападения, угрожающего жизни и здоровью частного охранника или охраняемому имуществу.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79744,
                    "questionId": 6789,
                    "name": "В случае отказа нарушителя подчиниться требованию охранника проследовать в помещение охраны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79745,
                    "questionId": 6789,
                    "name": "В случаях оказания указанными лицами группового сопротивления.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6779,
            "name": "Подлежит ли возмещению вред, причиненный посягающему лицу в состоянии необходимой обороны, если при этом не было допущено превышения пределов необходимой обороны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79725,
                    "questionId": 6779,
                    "name": "Да, подлежит.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79726,
                    "questionId": 6779,
                    "name": "Не подлежит.   ",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79727,
                    "questionId": 6779,
                    "name": "Подлежит частично на основании судебного решения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6777,
            "name": "При необходимой обороне причинение посягающему лицу любого вреда правомерно:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79719,
                    "questionId": 6777,
                    "name": "В случае группового посягательства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79720,
                    "questionId": 6777,
                    "name": "Если посягательство сопряжено с насилием, опасным для здоровья обороняющегося.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79721,
                    "questionId": 6777,
                    "name": "Если это посягательство сопряжено с насилием, опасным для жизни обороняющегося или другого лица, либо с непосредственной угрозой применения такого насилия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6773,
            "name": "Могут ли действия охранника по защите жизни и здоровья другого лица расцениваться как действия в состоянии необходимой обороны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79707,
                    "questionId": 6773,
                    "name": "Могут, если соблюдены условия необходимой обороны, предусмотренные законом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79708,
                    "questionId": 6773,
                    "name": "Могут, только если при указанном лице находилось охраняемое имущество.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79709,
                    "questionId": 6773,
                    "name": "Не могут ни при каких условиях.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6788,
            "name": "Кого и в какой срок в соответствии с законом обязан уведомить частный охранник в случаях, когда при применении специальных средств и огнестрельного оружия граждане получили телесные повреждения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79740,
                    "questionId": 6788,
                    "name": "Незамедлительно уведомить заказчика частной охранной услуги.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79741,
                    "questionId": 6788,
                    "name": "Немедленно уведомить органы здравоохранения, орган внутренних дел и руководителя охранной организации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79742,
                    "questionId": 6788,
                    "name": "Немедленно уведомить прокурора и в возможно короткий срок органы здравоохранения и внутренних дел, территориальный орган федерального органа исполнительной власти, уполномоченного в сфере частной охранной деятельности ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6775,
            "name": "Вред, причиненный в состоянии крайней необходимости:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79713,
                    "questionId": 6775,
                    "name": "Во всех случаях подлежит возмещению в полном объеме лицом, причинившим вред.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79714,
                    "questionId": 6775,
                    "name": "Не подлежит возмещению.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79715,
                    "questionId": 6775,
                    "name": "Подлежит возмещению по решению суда.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6785,
            "name": "Нарушение охранниками правил ношения оружия и патронов к нему влечет:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79737,
                    "questionId": 6785,
                    "name": "Административную ответственность.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79738,
                    "questionId": 6785,
                    "name": "Уголовную и административную ответственность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79739,
                    "questionId": 6785,
                    "name": "Уголовную ответственность.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6776,
            "name": "Причинение вреда, менее значительного, чем предотвращенный вред, является обязательным условием правомерности действий:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79716,
                    "questionId": 6776,
                    "name": "В состоянии крайней необходимости.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79717,
                    "questionId": 6776,
                    "name": "В состоянии необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79718,
                    "questionId": 6776,
                    "name": "Как в состоянии необходимой обороны, так и в состоянии крайней необходимости.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6801,
            "name": "В каких случаях ненадлежащее исполнение обязанностей лицом, которому была поручена охрана огнестрельного оружия, боеприпасов, взрывчатых веществ или взрывных устройств влечет уголовную ответственность:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79764,
                    "questionId": 6801,
                    "name": "Если это повлекло их хищение или уничтожение либо наступление иных тяжких последствий.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79765,
                    "questionId": 6801,
                    "name": "Независимо от последствий неисполнения указанных обязанностей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79766,
                    "questionId": 6801,
                    "name": "Только в случае их хищения или уничтожения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6800,
            "name": "На какой срок частный охранник признается непригодным к действиям в условиях, связанных с применением огнестрельного оружия и (или) специальных средств (в связи с непрохождением им повторной периодической проверки либо неявкой без уважительных причин на повторную периодическую проверку)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79761,
                    "questionId": 6800,
                    "name": "До даты очередного прохождения периодической проверки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79762,
                    "questionId": 6800,
                    "name": "На срок не более трех месяцев.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79763,
                    "questionId": 6800,
                    "name": "На срок не более шести месяцев.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6771,
            "name": "При необходимой обороне субъектом посягательства, отражаемого обороняющимся, является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79701,
                    "questionId": 6771,
                    "name": "Источник повышенной опасности (оружие, автомобиль и пр.).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79702,
                    "questionId": 6771,
                    "name": "Стихия (силы природы).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79703,
                    "questionId": 6771,
                    "name": "Человек (физическое лицо).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6781,
            "name": "К уголовно наказуемым деяниям относится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79731,
                    "questionId": 6781,
                    "name": "Умышленное причинение средней тяжести вреда здоровью, совершенное при превышении пределов необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79732,
                    "questionId": 6781,
                    "name": "Причинение тяжкого вреда здоровью по неосторожности, совершенное при превышении пределов необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79733,
                    "questionId": 6781,
                    "name": "Умышленное причинение тяжкого вреда здоровью, совершенное при превышении пределов необходимой обороны.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6782,
            "name": "К уголовно наказуемым деяниям относится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79734,
                    "questionId": 6782,
                    "name": "Причинение тяжкого или средней тяжести вреда здоровью по неосторожности, совершенное при превышении мер, необходимых для задержания лица, совершившего преступление.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79735,
                    "questionId": 6782,
                    "name": "Умышленное причинение легкого вреда здоровью, совершенное при превышении мер, необходимых для задержания лица, совершившего преступление.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79736,
                    "questionId": 6782,
                    "name": "Умышленное причинение тяжкого или средней тяжести вреда здоровью, совершенное при превышении мер, необходимых для задержания лица, совершившего преступление.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6794,
            "name": "В каких случаях охраннику дозволяется не предупреждать о намерении использовать физическую силу, специальные средства и огнестрельное оружие?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79749,
                    "questionId": 6794,
                    "name": "Когда имеется угроза применения насилия, опасного для жизни охранника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79750,
                    "questionId": 6794,
                    "name": "Когда может возникнуть угроза жизни и здоровью охраняемых граждан.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79751,
                    "questionId": 6794,
                    "name": "Когда промедление в применении специальных средств или огнестрельного оружия создает непосредственную опасность его жизни и здоровью или может повлечь за собой иные тяжкие последствия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6808,
            "name": "Согласно Типовым упражнениям практического применения специальных средств наручники считаются надетыми правильно:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79782,
                    "questionId": 6808,
                    "name": "Если в надетом состоянии наручники не могут проворачиваться на конечности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79783,
                    "questionId": 6808,
                    "name": "Если в надетом состоянии наручники свободно проворачиваются (каких-либо требований о надежности фиксации конечности не предъявляется).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79784,
                    "questionId": 6808,
                    "name": "Если в надетом состоянии наручники свободно проворачиваются и надежно фиксируют конечность.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6807,
            "name": "При прибытии частного охранника на периодическую проверку без документа, удостоверяющий личность гражданина Российской Федерации на территории Российской Федерации, удостоверения частного охранника и оригинала или заверенной печатью (штампом) ЧОО либо подписью уполномоченного лица ЧОО копии уведомления о проверке комиссией принимается решение о переносе даты периодической проверки:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79779,
                    "questionId": 6807,
                    "name": "На срок не более 14 календарных дней с учетом графика работы комиссии без изменения места проведения периодической проверки; в случае повторного в течение 1 года непредставления названных документов частный охранник признается не явившимся на периодическую проверку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79780,
                    "questionId": 6807,
                    "name": "На срок не более 14 календарных дней с учетом графика работы комиссии с возможностью изменения места проведения периодической проверки; в случае повторного в течение 1 года непредставления названных документов частный охранник признается не явившимся на периодическую проверку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79781,
                    "questionId": 6807,
                    "name": "На срок не более 30 календарных дней с учетом графика работы комиссии без изменения места проведения периодической проверки; количество случаев переноса даты периодической проверки не ограничено.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6798,
            "name": "Согласно нормативным правовым актам Правительства Российской Федерации ношение специальных средств на каждом объекте охраны осуществляется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79758,
                    "questionId": 6798,
                    "name": "В соответствии с должностной инструкцией частного охранника.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79759,
                    "questionId": 6798,
                    "name": "В соответствии с инструкцией предприятия-производителя соответствующего специального средства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79760,
                    "questionId": 6798,
                    "name": "В соответствии с Положением о пропускном и внутриобъектовом режимах, утвержденным Заказчиком охранных услуг.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6772,
            "name": "В соответствии с действующим законодательством при необходимой обороне допускается причинение вреда:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79704,
                    "questionId": 6772,
                    "name": "Любым лицам.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79705,
                    "questionId": 6772,
                    "name": "Посягающему лицу.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79706,
                    "questionId": 6772,
                    "name": "Третьим лицам.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6780,
            "name": "Превышение мер, необходимых для задержания лица, совершившего преступление (их явное несоответствие характеру и степени общественной опасности совершенного задерживаемым лицом преступления и обстоятельствам задержания), влечет за собой уголовную ответственность:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79728,
                    "questionId": 6780,
                    "name": "Во всех случаях причинения вреда здоровью задерживаемого (независимо от наличия или отсутствия умысла).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79729,
                    "questionId": 6780,
                    "name": "Только в случаях умышленного причинения смерти, тяжкого или средней тяжести вреда здоровью задерживаемого.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79730,
                    "questionId": 6780,
                    "name": "Только в случаях умышленного причинения смерти, тяжкого, средней тяжести или легкого вреда здоровью задерживаемого.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6790,
            "name": "Федеральным законом «О полиции» установлено следующее ограничение на применение специальных средств - не допускается нанесение человеку ударов палкой специальной:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79746,
                    "questionId": 6790,
                    "name": "По голове, шее, ключичной области, животу, половым органам, в область проекции сердца.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79747,
                    "questionId": 6790,
                    "name": "По рукам, ногам, ягодицам, по спине в области проекции почек и печени.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79748,
                    "questionId": 6790,
                    "name": "Только по голове, шее, ключичной области, животу.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6774,
            "name": "Допускается ли причинение вреда третьим лицам в состоянии необходимой обороны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79710,
                    "questionId": 6774,
                    "name": "Да, при вооруженном нападении.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79711,
                    "questionId": 6774,
                    "name": "Да, при групповом нападении.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79712,
                    "questionId": 6774,
                    "name": "Нет.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6765,
            "name": "Какие меры принуждения могут применять частные охранники?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79695,
                    "questionId": 6765,
                    "name": "Задержание на месте правонарушения лиц, совершивших противоправное посягательство на охраняемое имущество либо нарушающих внутриобъектовый и (или) пропускной режимы, применение физической силы, специальных средств и огнестрельного оружия, разрешенных в частной охранной деятельности.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79696,
                    "questionId": 6765,
                    "name": "Изъятие предметов, досмотр транспорта, применение огнестрельного и холодного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79697,
                    "questionId": 6765,
                    "name": "Проверка документов, досмотр переносимых вещей, применение физической силы, специальных средств и огнестрельного оружия, разрешенных в частной охранной деятельности.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6766,
            "name": "Какие виды специальных средств разрешается использовать в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79698,
                    "questionId": 6766,
                    "name": "Защитные шлемы, защитные жилеты, наручники и резиновые палки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79699,
                    "questionId": 6766,
                    "name": "Резиновые палки, наручники, средства для принудительной остановки транспорта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79700,
                    "questionId": 6766,
                    "name": "Резиновые палки, слезоточивые вещества, служебных собак.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6802,
            "name": "В каких случаях небрежное хранение огнестрельного оружия, создавшее условия для его использования другим лицом, не влечет уголовную ответственность:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79767,
                    "questionId": 6802,
                    "name": "Если это не повлекло тяжких последствий.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79768,
                    "questionId": 6802,
                    "name": "Если это повлекло смерть двух или более лиц.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79769,
                    "questionId": 6802,
                    "name": "Если это повлекло смерть человека или иные тяжкие последствия.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6803,
            "name": "Частные охранники имеют право применять физическую силу:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79770,
                    "questionId": 6803,
                    "name": "В случаях, если Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» им разрешено применение специальных средств или огнестрельного оружия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79771,
                    "questionId": 6803,
                    "name": "Только в случаях, если Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» им разрешено применение огнестрельного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79772,
                    "questionId": 6803,
                    "name": "Только в случаях, если Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» им разрешено применение специальных средств.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6816,
            "name": "Охранник, находящийся на посту в офисном помещении, услышал звуки выстрелов в соседней комнате. Какой из вариантов действий ему следует избрать?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79791,
                    "questionId": 6816,
                    "name": "Открыть дверь и войти в соседнюю комнату, чтобы оценить обстановку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79792,
                    "questionId": 6816,
                    "name": "Принять меры к оповещению правоохранительных органов, приготовить к применению имеющееся оружие (специальные средства), и далее используя обстановку офиса для укрытия, выяснить причину стрельбы.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79793,
                    "questionId": 6816,
                    "name": "Укрыться и, не производя других действий, ждать развития ситуации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6818,
            "name": "Какие действия охранника, вынужденного передвигаться под огнем противника, не помогают избежать поражения противником:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79794,
                    "questionId": 6818,
                    "name": "Передвигаться кратчайшим путем, не меняя направление движения.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79795,
                    "questionId": 6818,
                    "name": "Передвигаться, каждые 3-5 секунд производя выстрелы в направлении противника (если охранник вооружен и противник виден охраннику). ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79796,
                    "questionId": 6818,
                    "name": "Передвигаться, каждые 3-5 секунд укрываясь за имеющимися укрытиями; при отсутствии укрытий - каждые 3-5 секунд резко менять направление движения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6823,
            "name": "Входят ли в состав аптечки первой помощи медицинские препараты?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79803,
                    "questionId": 6823,
                    "name": "Входят медицинские препараты, отпускаемые в аптеках без рецепта (йод, нашатырный спирт, валидол, нитроглицерин и т.п.), а также препараты для проведения комплексной противошоковой терапии (кордиамин, дексаметазон, кеторолака трометамин или баралгин и т.п.).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79804,
                    "questionId": 6823,
                    "name": "Входят медицинские препараты, отпускаемые в аптеках без рецепта (йод, нашатырный спирт, валидол, нитроглицерин и т.п.).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79805,
                    "questionId": 6823,
                    "name": "Не входят.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6825,
            "name": "Первым действием (первым этапом) при оказании первой помощи является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79809,
                    "questionId": 6825,
                    "name": "Правильная транспортировка пострадавшего (производимая с учетом обстановки на месте происшествия, состояния и характера повреждений пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79810,
                    "questionId": 6825,
                    "name": "Предотвращение возможных осложнений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79811,
                    "questionId": 6825,
                    "name": "Прекращение воздействия травмирующего фактора.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6853,
            "name": "Что надо сделать для определения наличия дыхания при бессознательном состоянии пострадавшего?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79893,
                    "questionId": 6853,
                    "name": "Поднести зеркальце или птичье перо к носу пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79894,
                    "questionId": 6853,
                    "name": "Поднести к носу пострадавшего внутреннюю сторону своего запястья или щеку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79895,
                    "questionId": 6853,
                    "name": "Приложить ухо к груди пострадавшего и прослушать дыхание.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6838,
            "name": "Действия по помощи пострадавшему при попадании инородного тела в дыхательные пути:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79848,
                    "questionId": 6838,
                    "name": "Нагнуть туловище пострадавшего вперед, нанести несколько интенсивных ударов ладонью между лопаток, при отсутствии эффекта -  провести прием «Хемлика».",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79849,
                    "questionId": 6838,
                    "name": "Нанести пострадавшему, стоящему прямо, несколько интенсивных ударов ладонью между лопаток.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79850,
                    "questionId": 6838,
                    "name": "Положить пострадавшего на бок и вызвать интенсивную рвоту.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6839,
            "name": "Что надо сделать при возникновении не проходящих в покое острых болей за грудиной (в области сердца)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79851,
                    "questionId": 6839,
                    "name": "Немедленно вызвать «Скорую помощь», обеспечить пострадавшему полный покой в полусидячем положении, обеспечить приток воздуха.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79852,
                    "questionId": 6839,
                    "name": "Положить пострадавшего на спину, укутать одеялом, вызвать «Скорую помощь».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79853,
                    "questionId": 6839,
                    "name": "Посадить пострадавшего, обеспечить приток свежего воздуха, положить на грудь холод, вызвать «Скорую помощь».",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6851,
            "name": "При каких действиях достигается наибольшая эффективность оказания помощи при выведении пострадавшего из обморока?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79887,
                    "questionId": 6851,
                    "name": "При нажатии на точку в центре носогубного треугольника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79888,
                    "questionId": 6851,
                    "name": "При поднятии ног пострадавшего выше уровня тела, при скручивающем нажатии на середину грудины, при наличии нашатырного спирта -  при поднесении к носу и смазывании висков ваткой, смоченной нашатырным спиртом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79889,
                    "questionId": 6851,
                    "name": "При укутывании пострадавшего в одеяло, приведения его в боковое устойчивое положение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6855,
            "name": "Положение пострадавшего при проведении сердечно-легочной реанимации:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79899,
                    "questionId": 6855,
                    "name": "В том положении, в котором был обнаружен пострадавший (колени реанимирующего выше уровня спины пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79900,
                    "questionId": 6855,
                    "name": "На спине на кровати (колени реанимирующего ниже уровня спины пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79901,
                    "questionId": 6855,
                    "name": "На спине, на ровной жесткой поверхности (колени реанимирующего на уровне спины пострадавшего). ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6846,
            "name": "При попадании слезоточивых и раздражающих веществ в глаза необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79872,
                    "questionId": 6846,
                    "name": "Промыть глаза обильной струей теплой воды, затем 2% раствором бикарбоната натрия (соды).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79873,
                    "questionId": 6846,
                    "name": "Протереть глаза масляным тампоном.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79874,
                    "questionId": 6846,
                    "name": "Протереть глаза сухой ветошью.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6831,
            "name": "Что надо делать в случае, если у пострадавшего развился приступ эпилепсии (судорожный приступ)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79827,
                    "questionId": 6831,
                    "name": "Придерживать пострадавшего за голову, не давая ее разбить, по окончанию приступа перевести в устойчивое боковое положение.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79828,
                    "questionId": 6831,
                    "name": "Расслабить (расстегнуть) стягивающую одежду, убрать предметы, которые могут травмировать пострадавшего, подложить под голову мягкий, плоский предмет (например, подушку или свернутую одежду), зафиксировать время начала и конца приступа, по окончанию приступа перевести в устойчивое боковое положение.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79829,
                    "questionId": 6831,
                    "name": "Удерживать пострадавшего за руки и ноги, для предотвращения укуса языка и его западения ввести в рот ложку, по окончанию приступа перевести в устойчивое боковое положение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6836,
            "name": "Время наложения кровоостанавливающего жгута:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79842,
                    "questionId": 6836,
                    "name": "Летом – не более чем на 1 час, зимой – не более чем на 30 минут.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79843,
                    "questionId": 6836,
                    "name": "Летом – не более чем на 30 минут, зимой – не более чем на 1 час.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79844,
                    "questionId": 6836,
                    "name": "Не более чем на 30 минут, независимо от окружающей",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6857,
            "name": "При проведении ИВЛ (искусственной вентиляции легких) методом «рот к носу» необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79905,
                    "questionId": 6857,
                    "name": "Не проводить никаких манипуляций с нижней челюстью пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79906,
                    "questionId": 6857,
                    "name": "Свободной рукой открывать рот пострадавшего для обеспечения выдоха.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79907,
                    "questionId": 6857,
                    "name": "Свободной рукой плотно удерживать нижнюю челюсть пострадавшего, чтобы его рот был закрыт.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6842,
            "name": "Какие действия проводятся при проникающем ранении грудной клетки (с выходом воздуха в плевральную полость)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79860,
                    "questionId": 6842,
                    "name": "Придание возвышенного положения, первоначальное закрытие раны ладонью, закрытие раны повязкой, обеспечивающей фиксацию грудной клетки пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79861,
                    "questionId": 6842,
                    "name": "Придание возвышенного положения, первоначальное закрытие раны ладонью, затем закрытие раны повязкой, не пропускающей воздух - с использованием индивидуального перевязочного пакета, иного полиэтиленового пакета и т.п.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79862,
                    "questionId": 6842,
                    "name": "Придание пострадавшему положения «на спине» первоначальное закрытие раны ладонью, затем закрытие раны повязкой, не пропускающей воздух - с использованием индивидуального перевязочного пакета, иного полиэтиленового пакета и т.п.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6844,
            "name": "Что надо делать при нахождении ножа или другого ранящего предмета в ране?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79866,
                    "questionId": 6844,
                    "name": "Вытащить нож и быстро, без обработки раны антисептиком, наложить повязку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79867,
                    "questionId": 6844,
                    "name": "Оставить ранящий предмет в ране, зафиксировать предмет в ране, наложив вокруг него повязку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79868,
                    "questionId": 6844,
                    "name": "Применить пальцевое прижатие, наложить жгут выше места ранения, вытащить ранящий предмет, наложить повязку.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6837,
            "name": "При вынужденном длительном наложении кровоостанавливающий жгут необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79845,
                    "questionId": 6837,
                    "name": "Периодически ослаблять, и затем переносить ниже прежнего места наложения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79846,
                    "questionId": 6837,
                    "name": "Периодически ослаблять, применяя на это время пальцевое прижатие, затем накладывать на прежнее место.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79847,
                    "questionId": 6837,
                    "name": "Периодически ослаблять, применяя на это время пальцевое прижатие, затем переносить выше прежнего места наложения.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6834,
            "name": "Какова правильная последовательность действий при остановке артериального кровотечения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79836,
                    "questionId": 6834,
                    "name": "Накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79837,
                    "questionId": 6834,
                    "name": "Проводится пальцевая остановка кровотечения, накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79838,
                    "questionId": 6834,
                    "name": "Проводится пальцевая остановка кровотечения, накладывается чистая повязка, накладывается жгут (скрутка, ремень), указывается время наложения жгута",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6859,
            "name": "Частота вдуваний воздуха в минуту при проведении ИВЛ (искусственной вентиляции легких) составляет:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79911,
                    "questionId": 6859,
                    "name": "20-24 вдуваний в минуту для взрослых, 30-36 для детей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79912,
                    "questionId": 6859,
                    "name": "6-8 вдуваний в минуту для взрослых, 8-10 для детей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79913,
                    "questionId": 6859,
                    "name": "8-10 вдуваний в минуту для взрослых, 12-20 для детей.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6843,
            "name": "Какие правила оказания первой помощи соблюдаются при проникающем ранении в брюшную полость?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79863,
                    "questionId": 6843,
                    "name": "Не давать пострадавшему пить жидкость, извлечь инородное тело, накрыть рану стерильной салфеткой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79864,
                    "questionId": 6843,
                    "name": "Не давать пострадавшему пить жидкость, не извлекать инородное тело, прикрыть рану стерильным перевязочным материалом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79865,
                    "questionId": 6843,
                    "name": "Приподнять голову, дать сладкое теплое питье, накрыть стерильной салфеткой и положить холод на рану.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6852,
            "name": "Как проверяется пульс при бессознательном состоянии пострадавшего и при травмах?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79890,
                    "questionId": 6852,
                    "name": "Приложив ухо к груди прослушивается сердцебиение.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79891,
                    "questionId": 6852,
                    "name": "Пульс проверяется на запястье.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79892,
                    "questionId": 6852,
                    "name": "Пульс проверяется на сонной артерии.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6845,
            "name": "При попадании слезоточивых и раздражающих веществ на кожу следует:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79869,
                    "questionId": 6845,
                    "name": "Промокнуть сухой ветошью.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79870,
                    "questionId": 6845,
                    "name": "Промыть кожу холодной водой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79871,
                    "questionId": 6845,
                    "name": "Протереть последовательно тремя тампонами - с 40% раствором этилового спирта, с 3% раствором бикарбоната натрия (соды), с мыльным раствором.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6835,
            "name": "Техника наложения кровоостанавливающего жгута предусматривает:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79839,
                    "questionId": 6835,
                    "name": "Наложение жгута на одежду выше места кровотечения (с указанием времени наложения в записке).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79840,
                    "questionId": 6835,
                    "name": "Наложение жгута на одежду ниже места кровотечения (с указанием времени наложения в записке).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79841,
                    "questionId": 6835,
                    "name": "Наложение жгута под одежду выше места кровотечения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6830,
            "name": "Если пострадавший находится в сознании и задыхается, или у него имеется ранение грудной клетки, в какое положение до прибытия скорой помощи он должен быть переведен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79824,
                    "questionId": 6830,
                    "name": "В положении на спине.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79825,
                    "questionId": 6830,
                    "name": "В положении полусидя.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79826,
                    "questionId": 6830,
                    "name": "В устойчивое боковое положение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6841,
            "name": "В каком порядке проводятся мероприятия первой помощи при ранении?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79857,
                    "questionId": 6841,
                    "name": "Обеззараживание раны (при возможности), наложение повязки, остановка кровотечения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79858,
                    "questionId": 6841,
                    "name": "Остановка кровотечения, наложение повязки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79859,
                    "questionId": 6841,
                    "name": "Остановка кровотечения, обеззараживание раны (при возможности), наложение повязки.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6832,
            "name": "Правильная транспортировка пострадавшего, находящегося без сознания (за исключением случаев, когда в связи с подозрением на травму позвоночника менять положение тела не рекомендуется) производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79830,
                    "questionId": 6832,
                    "name": "В положении на боку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79831,
                    "questionId": 6832,
                    "name": "В положении на спине.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79832,
                    "questionId": 6832,
                    "name": "В положении с приподнятыми нижними конечностями.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6833,
            "name": "Способы временной остановки кровотечения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79833,
                    "questionId": 6833,
                    "name": "Пальцевое прижатие, максимальное сгибание конечности, наложение жгута (закрутки), наложение давящей повязки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79834,
                    "questionId": 6833,
                    "name": "Придание возвышенного положения конечности, наложение асептической повязки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79835,
                    "questionId": 6833,
                    "name": "Частичное сгибание конечности, наложение пластыря, наложение давящей повязки.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6850,
            "name": "Порядок оказания первой помощи при открытых переломах.",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79884,
                    "questionId": 6850,
                    "name": "Наложить шину и обезболить (по возможности).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79885,
                    "questionId": 6850,
                    "name": "Наложить шину, наложить повязку на рану.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79886,
                    "questionId": 6850,
                    "name": "Обезболить (по возможности), наложить повязку, наложить шину.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6856,
            "name": "При проведении ИВЛ (искусственной вентиляции легких) методом «рот ко рту» необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79902,
                    "questionId": 6856,
                    "name": "Зажимать нос пострадавшего только в случае, если носовые ходы свободны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79903,
                    "questionId": 6856,
                    "name": "Нос пострадавшему не зажимать.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79904,
                    "questionId": 6856,
                    "name": "Свободной рукой плотно зажимать нос пострадавшего.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6822,
            "name": "Каков порядок действий при встрече медицинских работников, прибывающих по вызову?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79800,
                    "questionId": 6822,
                    "name": "Направить кого-нибудь встречать «Скорую помощь», при сложном маршруте обозначить его дополнительными опознавательными знаками, самому ожидать у места происшествия и оказывать первую помощь.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79801,
                    "questionId": 6822,
                    "name": "Направить кого-нибудь встречать «Скорую помощь», самому ожидать у места происшествия и оказывать первую помощь.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79802,
                    "questionId": 6822,
                    "name": "Ожидать встречи «Скорой помощи» на месте происшествия, объясняя по телефону диспетчеру «03», как поехать к месту происшествия.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6828,
            "name": "Какие действия, предусмотренные в составе первой помощи, завершают ее оказание?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79818,
                    "questionId": 6828,
                    "name": "Передача пострадавшего бригаде скорой медицинской помощи.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79819,
                    "questionId": 6828,
                    "name": "Проведение мероприятий первичной медико-санитарной помощи.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79820,
                    "questionId": 6828,
                    "name": "Проведение мероприятий специализированной медицинской помощи.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6849,
            "name": "При повреждении костей плеча или бедра шину накладывают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79881,
                    "questionId": 6849,
                    "name": "С захватом только верхнего (по отношению к месту перелома) сустава.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79882,
                    "questionId": 6849,
                    "name": "С захватом только двух суставов (выше и ниже места перелома).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79883,
                    "questionId": 6849,
                    "name": "С захватом трех суставов (двух ниже и одного выше места перелома).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6858,
            "name": "Особенности проведения ИВЛ (искусственной вентиляции легких) детям:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79908,
                    "questionId": 6858,
                    "name": "Увеличивается частота вдуваний воздуха с обязательным уменьшением объема вдуваемого воздуха.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79909,
                    "questionId": 6858,
                    "name": "Уменьшается частота вдуваний воздуха с обязательным уменьшением объема вдуваемого воздуха.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79910,
                    "questionId": 6858,
                    "name": "Частота вдуваний воздуха и объем вдуваемого воздуха, по сравнению со взрослыми пострадавшими, не меняется. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6821,
            "name": "Каково содержание информации, сообщаемой при вызове скорой медицинской помощи?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79797,
                    "questionId": 6821,
                    "name": "Сообщить, кто вызывает, телефон вызывающего, что случилось, кто пострадал/заболел (пол, возраст), адрес с указанием подъездного пути, дома, подъезда, этажа, кода на входной двери.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79798,
                    "questionId": 6821,
                    "name": "Сообщить, что случилось, кто вызывает, телефон вызывающего, кто пострадал/заболел (пол, возраст), адрес с указанием подъездного пути, дома, подъезда, этажа, кода на входной двери.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79799,
                    "questionId": 6821,
                    "name": "Сообщить, что случилось, кто пострадал/заболел (пол, возраст), адрес с указанием подъездных путей, дома, подъезда, этажа, кода на входной двери, кто вызывает, телефон вызывающего.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6860,
            "name": "Ритм сердечно-легочной реанимации, выполняемой при оказании первой помощи:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79914,
                    "questionId": 6860,
                    "name": "15 надавливаний на грудную клетку – 2 вдувания воздуха.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79915,
                    "questionId": 6860,
                    "name": "30 надавливаний на грудную клетку – 2 вдувания воздуха.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79916,
                    "questionId": 6860,
                    "name": "5 надавливаний на грудную клетку – 1 вдувание воздуха.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6829,
            "name": "Если пострадавший находится без сознания, в какое положение до прибытия скорой помощи он должен быть переведен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79821,
                    "questionId": 6829,
                    "name": "В положении на спине.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79822,
                    "questionId": 6829,
                    "name": "В положении полусидя.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79823,
                    "questionId": 6829,
                    "name": "В устойчивое боковое положение.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6827,
            "name": "Третьим действием (третьим этапом) при оказании первой помощи является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79815,
                    "questionId": 6827,
                    "name": "Правильная транспортировка пострадавшего (производимая с учетом обстановки на месте происшествия, состояния и характера повреждений пострадавшего).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79816,
                    "questionId": 6827,
                    "name": "Предотвращение возможных осложнений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79817,
                    "questionId": 6827,
                    "name": "Прекращение воздействия травмирующего фактора.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6861,
            "name": "Промывание желудка при отравлении в порядке первой помощи (немедицинским персоналом и без желудочного зонда) запрещено:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79917,
                    "questionId": 6861,
                    "name": "При отравлениях кислотами, щелочами, нефтепродуктами, при судорогах, в случае потери сознания пострадавшим.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79918,
                    "questionId": 6861,
                    "name": "При отравлениях у лиц, не имеющих при себе документов, удостоверяющих личность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79919,
                    "questionId": 6861,
                    "name": "При отравлениях у несовершеннолетних детей.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6854,
            "name": "В каком объеме проводятся мероприятия при прекращении сердечной деятельности и дыхания у пострадавшего?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79896,
                    "questionId": 6854,
                    "name": "Освобождение дыхательных путей, проведение ИВЛ (искусственной вентиляции легких) и НМС (непрямого массажа сердца).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79897,
                    "questionId": 6854,
                    "name": "Освобождение дыхательных путей, проведение ИВЛ (искусственной вентиляции легких).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79898,
                    "questionId": 6854,
                    "name": "Проведение НМС (непрямого массажа сердца).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6848,
            "name": "При повреждении костей предплечья или голени шину накладывают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79878,
                    "questionId": 6848,
                    "name": "С захватом двух суставов (выше и ниже места перелома).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79879,
                    "questionId": 6848,
                    "name": "С захватом только верхнего (по отношению к месту перелома) сустава.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79880,
                    "questionId": 6848,
                    "name": "С захватом трех суставов.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6824,
            "name": "Какие из мероприятий по оценке обстановки и обеспечению безопасных условий для оказания первой помощи совершаются в порядке осмотра места происшествия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79806,
                    "questionId": 6824,
                    "name": "Определение угрожающих факторов для собственной жизни и здоровья; определение угрожающих факторов для жизни и здоровья пострадавшего; оценка количества пострадавших.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79807,
                    "questionId": 6824,
                    "name": "Придание правильного транспортного положения и организация транспортировки пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79808,
                    "questionId": 6824,
                    "name": "Устранение угрожающих факторов для жизни и здоровья; прекращение действия повреждающих факторов на пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6847,
            "name": "Что необходимо сделать при ожоговой ране?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79875,
                    "questionId": 6847,
                    "name": "Наложить чистую увлажненную повязку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79876,
                    "questionId": 6847,
                    "name": "Очистить рану и промыть ее холодной водой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79877,
                    "questionId": 6847,
                    "name": "Смазать рану маслом, наложить повязку.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6826,
            "name": "Вторым действием (вторым этапом) при оказании первой помощи является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79812,
                    "questionId": 6826,
                    "name": "Правильная транспортировка пострадавшего (производимая с учетом обстановки на месте происшествия, состояния и характера повреждений пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79813,
                    "questionId": 6826,
                    "name": "Предотвращение возможных осложнений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79814,
                    "questionId": 6826,
                    "name": "Устранение состояния, угрожающего жизни и здоровью пострадавшего.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6840,
            "name": "Что в первую очередь может помочь при возникновении не проходящих в покое острых болей за грудиной (в области сердца)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79854,
                    "questionId": 6840,
                    "name": "Измерение давления и частоты пульса.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79855,
                    "questionId": 6840,
                    "name": "Обеспечение физической нагрузки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79856,
                    "questionId": 6840,
                    "name": "Прием нитроглицерина под язык (только если пострадавший знает о своей болезни и имеет его при себе).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6874,
            "name": "Каким способом проверяется фиксация замков наручников, не угрожающая нормальному кровообращению у правонарушителя?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79956,
                    "questionId": 6874,
                    "name": "Визуальным осмотром конечностей правонарушителя на предмет посинения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79957,
                    "questionId": 6874,
                    "name": "Периодическим открытием и закрытием замка наручников.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79958,
                    "questionId": 6874,
                    "name": "Проверкой возможности браслетов наручников без затруднений проворачиваться на конечностях правонарушителя (и в то же время надежно фиксировать их).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6893,
            "name": "Хранение каких видов специальных средств, используемых в частной охранной деятельности, допускается ближе 1 метра от отопительных приборов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80013,
                    "questionId": 6893,
                    "name": "Жилетов и шлемов защитных.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80014,
                    "questionId": 6893,
                    "name": "Наручников.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80015,
                    "questionId": 6893,
                    "name": "Палок резиновых.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6863,
            "name": "К основному назначению специального средства «шлем защитный», используемого в частной охранной деятельности, можно отнести:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79923,
                    "questionId": 6863,
                    "name": "Обеспечение индивидуальной защиты головы человека от средств поражения (пуль, осколков, холодного оружия) и контузий вследствие ударов.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79924,
                    "questionId": 6863,
                    "name": "Обеспечение индивидуальной защиты головы человека от средств поражения (пуль, осколков, холодного оружия) и нанесения оборонительных ударов правонарушителю.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79925,
                    "questionId": 6863,
                    "name": "Обеспечение индивидуальной защиты головы, шеи и плеч человека от средств поражения (пуль, осколков, холодного оружия) и контузий вследствие ударов.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6875,
            "name": "Какая модель наручников, используемых в частной охранной деятельности, обладает жесткой системой крепления браслетов между собой?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79959,
                    "questionId": 6875,
                    "name": "БКС-1.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79960,
                    "questionId": 6875,
                    "name": "БОС.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79961,
                    "questionId": 6875,
                    "name": "БР-С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6864,
            "name": "К основному назначению специального средства «жилет защитный», используемого в частной охранной деятельности, можно отнести:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79926,
                    "questionId": 6864,
                    "name": "Обеспечение индивидуальной защиты туловища человека от средств поражения (пуль, осколков, холодного оружия).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79927,
                    "questionId": 6864,
                    "name": "Обеспечение индивидуальной защиты туловища, конечностей, стоп ног и кистей рук человека от средств поражения (пуль, осколков, холодного оружия).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79928,
                    "questionId": 6864,
                    "name": "Прекращение буйства и бесчинства задержанных лиц.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6866,
            "name": "Какой класс защитной структуры бронеодежды (жилетов защитных) является минимально достаточным для защиты от огня из пистолетов АПС, ПМ и револьвера системы «Наган»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79932,
                    "questionId": 6866,
                    "name": "Основной класс защиты Бр1 (класс защиты 1 по старой классификации).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79933,
                    "questionId": 6866,
                    "name": "Основной класс защиты Бр2 (класс защиты 2 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79934,
                    "questionId": 6866,
                    "name": "Основной класс защиты Бр3 (класс защиты 3 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6881,
            "name": "Палки резиновые ПУС-2 и ПР-Т, разрешенные для использования в частной охранной деятельности, имеют в своей конструкции:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79977,
                    "questionId": 6881,
                    "name": "Боковую ручку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79978,
                    "questionId": 6881,
                    "name": "Выступающий кольцевой элемент (мини-гарду) рукоятки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79979,
                    "questionId": 6881,
                    "name": "Металлический наконечник.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6872,
            "name": "Защита от какого оружия не обеспечивается бронешлемами (шлемами защитными) 1-3 классов защиты?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79950,
                    "questionId": 6872,
                    "name": "АПС.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79951,
                    "questionId": 6872,
                    "name": "СВД.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79952,
                    "questionId": 6872,
                    "name": "ТТ, ПММ, ПСМ.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6898,
            "name": "Какие из перечисленных ниже наручников не используются в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80028,
                    "questionId": 6898,
                    "name": "Наручники конвойные с соединительной цепочкой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80029,
                    "questionId": 6898,
                    "name": "Наручники, предназначенные для стационарного крепления к стенам зданий. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80030,
                    "questionId": 6898,
                    "name": "Пальцевые наручники.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6886,
            "name": "Каков максимальный предел температуры окружающего воздуха, установленный в качестве допустимого при эксплуатации палок резиновых ПУС-1, ПУС-2, ПУС-3 (изготовленных из высокомолекулярного полиэтилена), используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79992,
                    "questionId": 6886,
                    "name": "+30°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79993,
                    "questionId": 6886,
                    "name": "+40°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79994,
                    "questionId": 6886,
                    "name": "+50°С.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6868,
            "name": "Защита от какого оружия не обеспечивается используемыми в частной охранной деятельности жилетами защитными 1-5 классов защиты (Бр1, Бр2, С1, Бр3, Бр4 по новой классификации)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79938,
                    "questionId": 6868,
                    "name": "АКМ с боеприпасом, имеющим стальной термоупрочненный сердечник.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79939,
                    "questionId": 6868,
                    "name": "СВД с боеприпасом, имеющим легкоплавкий сердечник.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79940,
                    "questionId": 6868,
                    "name": "СВД с боеприпасом, имеющим стальной термоупрочненный сердечник.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6897,
            "name": "Какая особенность не характерна для применения наручников БОС, имеющих жесткую систему крепления браслетов между собой?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80025,
                    "questionId": 6897,
                    "name": "Возможность мгновенной стыковки и расстыковки браслетов наручников между собой с помощью электромагнитного затвора.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80026,
                    "questionId": 6897,
                    "name": "Снижение вероятности доступа к замкам браслетов и перевода зафиксированных рук правонарушителя из положения «сзади» в положение «спереди».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80027,
                    "questionId": 6897,
                    "name": "Сравнительно малое время надевания браслетов на оказывающего сопротивление правонарушителя. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6887,
            "name": "Каков максимальный предел температуры окружающего воздуха, установленный в качестве допустимого при эксплуатации палок резиновых ПР-73М, ПР-К, ПР-Т (изготовленных из резиновой смеси), используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79995,
                    "questionId": 6887,
                    "name": "+30°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79996,
                    "questionId": 6887,
                    "name": "+40°С.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79997,
                    "questionId": 6887,
                    "name": "+50°С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6880,
            "name": "Палка резиновая ПУС-3, разрешенная для использования в частной охранной деятельности, выпускается в следующих вариантах:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79974,
                    "questionId": 6880,
                    "name": "Произвольная и штатная.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79975,
                    "questionId": 6880,
                    "name": "Прямая и с боковой ручкой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79976,
                    "questionId": 6880,
                    "name": "Раскладная и телескопическая.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6878,
            "name": "Каков максимальный предел температуры окружающего воздуха, при котором обеспечивается надлежащее техническое состояние (исправность) наручников, используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79968,
                    "questionId": 6878,
                    "name": "+35°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79969,
                    "questionId": 6878,
                    "name": "+40°С.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79970,
                    "questionId": 6878,
                    "name": "+45°С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6869,
            "name": "Как меняется время непрерывного ношения бронежилета (жилета защитного) при повышении температуры и влажности воздуха:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79941,
                    "questionId": 6869,
                    "name": "Остается неизменным.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79942,
                    "questionId": 6869,
                    "name": "Увеличивается.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79943,
                    "questionId": 6869,
                    "name": "Уменьшается.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6862,
            "name": "К основному назначению специального средства «наручники», используемого в частной охранной деятельности, можно отнести:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79920,
                    "questionId": 6862,
                    "name": "Ограничение физической возможности правонарушителя по оказанию сопротивления.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79921,
                    "questionId": 6862,
                    "name": "Оказание психологического воздействия на правонарушителя.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79922,
                    "questionId": 6862,
                    "name": "Причинение физического ущерба правонарушителю.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6883,
            "name": "Какой из перечисленных способов надевания одной пары наручников может быть применен и при этом является наиболее эффективным для затруднения сопротивления и предотвращения побега двух задержанных:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79983,
                    "questionId": 6883,
                    "name": "На правую руку одного задержанного и левую руку другого.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79984,
                    "questionId": 6883,
                    "name": "На правую руку одного задержанного и правую руку другого.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79985,
                    "questionId": 6883,
                    "name": "На руку одного задержанного и на ногу другого.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6873,
            "name": "Каким дополнительным элементом не комплектуются бронешлемы (шлемы защитные)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79953,
                    "questionId": 6873,
                    "name": "Бармицей для защиты шеи.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79954,
                    "questionId": 6873,
                    "name": "Встроенной радиогарнитурой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79955,
                    "questionId": 6873,
                    "name": "Шейно-плечевой накладкой.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6884,
            "name": "Каков минимальный предел температуры окружающего воздуха, установленный в качестве допустимого при эксплуатации палок резиновых ПУС-1, ПУС-2, ПУС-3 (изготовленных из высокомолекулярного полиэтилена), используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79986,
                    "questionId": 6884,
                    "name": "-30°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79987,
                    "questionId": 6884,
                    "name": "-45°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79988,
                    "questionId": 6884,
                    "name": "-60°С.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6901,
            "name": "При ношении бронежилетов (жилетов защитных) скрытого ношения рекомендуется использовать одежду:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80037,
                    "questionId": 6901,
                    "name": "На 1-2 размера больше той, которую носит использующий бронежилет (жилет защитный) или одежду свободного покроя.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80038,
                    "questionId": 6901,
                    "name": "На 3-4 размера больше той, которую носит использующий бронежилет (жилет защитный) или одежду свободного покроя.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80039,
                    "questionId": 6901,
                    "name": "Совпадающую по размеру с той, которую носит использующий бронежилет (жилет защитный) или одежду свободного покроя.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6894,
            "name": "К дополнительным (съемным) элементам бронезащиты, которыми могут комплектоваться все типы жилетов защитных (за исключением некоторых моделей скрытого ношения) относятся: ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80016,
                    "questionId": 6894,
                    "name": "Сменные жесткие позвоночные накладки, маски защитные, перчатки защитные, локтевые (кольцевые) защитные накладки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80017,
                    "questionId": 6894,
                    "name": "Спецрадиостанции бронированные, планшеты защитные (бронированные), сапоги специальные защитные.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80018,
                    "questionId": 6894,
                    "name": "Шейно-плечевые накладки, паховые накладки, сменные жесткие защитные элементы (бронепластины).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6885,
            "name": "Каков минимальный предел температуры окружающего воздуха, установленный в качестве допустимого при эксплуатации палок резиновых ПР-73М, ПР-К, ПР-Т (изготовленных из резиновой смеси), используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79989,
                    "questionId": 6885,
                    "name": "-30°С.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79990,
                    "questionId": 6885,
                    "name": "-45°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79991,
                    "questionId": 6885,
                    "name": "-60°С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6900,
            "name": "Какой класс защитной структуры бронеодежды (жилетов защитных) является минимально достаточным для защиты от огня из охотничьего ружья 12-го калибра 18,5 мм охотничьим патроном со свинцовым сердечником?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80034,
                    "questionId": 6900,
                    "name": "Основной класс защиты Бр1 (класс защиты 1 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80035,
                    "questionId": 6900,
                    "name": "Основной класс защиты Бр2 (класс защиты 2 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80036,
                    "questionId": 6900,
                    "name": "Специальный класс защиты С1 (класс защиты 2а по старой классификации).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6892,
            "name": "Что необходимо сделать для выключения (разблокировки) фиксатора, имеющегося в конструкции браслетов наручников, используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80010,
                    "questionId": 6892,
                    "name": "Вставить ключ от наручников в замочную скважину и повернуть его в необходимую для разблокировки сторону.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80011,
                    "questionId": 6892,
                    "name": "Вставить ключ от наручников в отверстие на боковой стороне браслета и повернуть его в необходимую для разблокировки сторону.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80012,
                    "questionId": 6892,
                    "name": "Резко потянуть запирающую дужку браслета наручников в сторону отпирания.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6876,
            "name": "Какая модель наручников, используемых в частной охранной деятельности, имеет вариант изготовления, предназначенный для стационарного крепления к стенам зданий?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79962,
                    "questionId": 6876,
                    "name": "БКС-1.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79963,
                    "questionId": 6876,
                    "name": "БОС.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79964,
                    "questionId": 6876,
                    "name": "БР-С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6867,
            "name": "Какой класс защиты бронежилета (жилета защитного) позволяет защититься от огня из автоматов АК-74, АКМ патронами, имеющими стальной термоупрочненный сердечник?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79935,
                    "questionId": 6867,
                    "name": "Основной класс защиты Бр2 (класс защиты 2 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79936,
                    "questionId": 6867,
                    "name": "Основной класс защиты Бр3 (класс защиты 3 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79937,
                    "questionId": 6867,
                    "name": "Основной класс защиты Бр4 (класс защиты 4 и 5 по старой классификации).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6896,
            "name": "Какой класс защитной структуры бронеодежды (жилетов защитных) является минимально достаточным для защиты от огня из пистолета СР-1 (9-мм пистолета Сердюкова) и пистолетов ТТ, ПММ, ПСМ?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80022,
                    "questionId": 6896,
                    "name": "Основной класс защиты Бр1 (класс защиты 1 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80023,
                    "questionId": 6896,
                    "name": "Основной класс защиты Бр2 (класс защиты 2 по старой классификации).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80024,
                    "questionId": 6896,
                    "name": "Основной класс защиты Бр3 (класс защиты 3 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6895,
            "name": "В какой модели наручников, из числа разрешенных в частной охранной деятельности, используется соединительная цепочка?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80019,
                    "questionId": 6895,
                    "name": "БКС-1.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80020,
                    "questionId": 6895,
                    "name": "БОС.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80021,
                    "questionId": 6895,
                    "name": "БР-2М.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6882,
            "name": "Палка резиновая ПР-73М, разрешенная для использования в частной охранной деятельности, имеют в своей конструкции:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79980,
                    "questionId": 6882,
                    "name": "Боковую ручку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79981,
                    "questionId": 6882,
                    "name": "Выступающий кольцевой элемент (мини-гарду) рукоятки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79982,
                    "questionId": 6882,
                    "name": "Металлический наконечник.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6891,
            "name": "Какой способ применяется для включения фиксатора, имеющегося в конструкции браслетов наручников, используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80007,
                    "questionId": 6891,
                    "name": "Нажатие (утопление) штифта фиксатора, расположенного на боковой стороне браслета с помощью ключа от наручников.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80008,
                    "questionId": 6891,
                    "name": "Пальцевое нажатие (утопление) выступа фиксатора, выполненного в виде шляпки одной из заклепок браслета наручников (а именно - расположенной в непосредственной близости от замочной скважины).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80009,
                    "questionId": 6891,
                    "name": "Поворот ключа от наручников в замочной скважине в необходимую для включения фиксатора сторону.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6888,
            "name": "Бронеодежда (жилеты защитные) и бронешлемы (шлемы защитные), за исключением изготовленных специально для особых условий эксплуатации, могут терять свои свойства:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79998,
                    "questionId": 6888,
                    "name": "При воздействии ультрафиолетового излучения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79999,
                    "questionId": 6888,
                    "name": "При намокании.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80000,
                    "questionId": 6888,
                    "name": "При температуре +30°С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6879,
            "name": "Чистка и смазка наручников, используемых в частной охранной деятельности, производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79971,
                    "questionId": 6879,
                    "name": "В порядке, установленном соответствующим Постановление Правительства РФ.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79972,
                    "questionId": 6879,
                    "name": "Произвольно, по решению охранника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79973,
                    "questionId": 6879,
                    "name": "Согласно инструкции предприятия-изготовителя.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6865,
            "name": "К основному назначению специального средства «палка резиновая», используемого в частной охранной деятельности, можно отнести:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79929,
                    "questionId": 6865,
                    "name": "Активное нападение на лиц, не выполняющих прямое указание охранника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79930,
                    "questionId": 6865,
                    "name": "Активную защиту при нападении (сопротивлении) правонарушителя.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79931,
                    "questionId": 6865,
                    "name": "Предупреждение правонарушителя перед применением огнестрельного оружия, входящего в перечень видов вооружения охранников. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6871,
            "name": "Какие типы бронежилетов (жилетов защитных) не выпускаются отечественными производителями?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79947,
                    "questionId": 6871,
                    "name": "Бронежилеты с положительной плавучестью.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79948,
                    "questionId": 6871,
                    "name": "Бронежилеты скрытого ношения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79949,
                    "questionId": 6871,
                    "name": "Бронежилеты со специальной подсветкой.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6890,
            "name": "Каково назначение фиксатора, имеющегося в конструкции браслетов наручников, используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80004,
                    "questionId": 6890,
                    "name": "Блокировка доступа к замочной скважине браслета наручников.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80005,
                    "questionId": 6890,
                    "name": "Блокировка механизма зацепления подвижной запирающей дужки браслета наручников.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80006,
                    "questionId": 6890,
                    "name": "Фиксация ключа от наручников к одному из браслетов (во избежание его утери).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6870,
            "name": "Как меняется время непрерывного ношения бронежилета (жилета защитного) при понижении температуры воздуха:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79944,
                    "questionId": 6870,
                    "name": "Остается неизменным.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79945,
                    "questionId": 6870,
                    "name": "Увеличивается.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79946,
                    "questionId": 6870,
                    "name": "Уменьшается.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6877,
            "name": "Каков минимальный предел температуры окружающего воздуха, при котором обеспечивается надлежащее техническое состояние (исправность) наручников, используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 79965,
                    "questionId": 6877,
                    "name": "-20°С",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 79966,
                    "questionId": 6877,
                    "name": "-30°С",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 79967,
                    "questionId": 6877,
                    "name": "-40°С ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6889,
            "name": "Какие вещества (материалы) запрещается хранить совместно с бронеодеждой (жилетами защитными) и бронешлемами (шлемами защитными)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80001,
                    "questionId": 6889,
                    "name": "Гидросорбенты (влагопоглотители).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80002,
                    "questionId": 6889,
                    "name": "Масла и кислоты.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80003,
                    "questionId": 6889,
                    "name": "Резиновые изделия (резину).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6899,
            "name": "Перед надеванием наручников на правонарушителя необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80031,
                    "questionId": 6899,
                    "name": "Освободить запястья от одежды.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80032,
                    "questionId": 6899,
                    "name": "Подложить на запястья в тех местах, на которые будут надеваться наручники, ткань, салфетку или платок.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80033,
                    "questionId": 6899,
                    "name": "Получить на применение наручников разрешение руководителя частной охранной организации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6969,
            "name": "В чем состоит особенность действий охранника 4 разряда в ходе противодействия террористическим угрозам?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80040,
                    "questionId": 6969,
                    "name": "В связи с возможным наличием у охранника специальных средств необходимо дополнительно прогнозировать эффективность, а также возможные положительные и отрицательные последствия от их применения с учетом опасности террористической угрозы.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80041,
                    "questionId": 6969,
                    "name": "В связи с возможным наличием у охранника специальных средств, необходимо понимать, что в ситуации противодействия террористическим угрозам их применять категорически запрещается.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80042,
                    "questionId": 6969,
                    "name": "Каких-либо особенностей действий для охранника 4 разряда в ходе противодействия террористическим угрозам не усматривается.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        }
    ],
    "periodicnypename": "Плановая",
    "guardcatname": "4 разряд"
}