import { QuizConfig } from './quiz-config';
import { Question } from './question';

export class Quiz {
    id: number;
    name: string;
    takerfio: string;
    description: string;
    printresult: boolean;
    showCorrectAnswer: boolean;
    directionmodeid: number;
    config: QuizConfig;
    questions: Question[];

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.takerfio = data.takerfio;
            this.description = data.description;
            this.printresult = data.printresult;
            this.showCorrectAnswer = data.showCorrectAnswer;
            this.directionmodeid = data.directionmodeid;
            this.config = new QuizConfig(data.config);
            this.questions = [];
            data.questions.forEach(q => {
                this.questions.push(new Question(q));
            });
        }
    }
}
