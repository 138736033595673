
export const generateQuestions = (quiz: any, questionsCount) => {
  let currQuestionCount = 0;
  const originalQuestions = [...quiz.questions];
  const totalQuestions = [];
  const indexes = [];
  while(currQuestionCount < questionsCount) {
    const randIndex = Math.floor(Math.random() * originalQuestions.length);
    if (!indexes.includes(randIndex)) {
      console.log()
      currQuestionCount++;
      totalQuestions.push(originalQuestions[randIndex]);
      indexes.push(randIndex);
    }
  }
  return totalQuestions; 
};