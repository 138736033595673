import { Injectable, InjectionToken } from '@angular/core';
import { Http, Request, Response, Headers, RequestOptionsArgs, RequestMethod, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Quiz } from 'app/models/quiz';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { GRADE_MAP } from 'quiz/grade-all';

export const API_BASE_URL = new InjectionToken('API_BASE_URL');

@Injectable()
export class QuizService {
  error: any;
  data: any;

  constructor(private http: HttpClient) { }

  get(gradeParam: string) {
    const currentGrade = GRADE_MAP[gradeParam];
    return Observable.of(currentGrade);
    // return this.http
    //   .get(url)
    //   .map(this.extractData)
    //   .catch(this.handleError);
  }


  public extractData(res: Response) {
    // res => res.text().length > 0 ? res.json() : null

    let body = res.json();
    return body || {};
  }

  public handleError(error: any) {
    let errMsg = (error.message)
      ? error.message
      :error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
    console.error(errMsg);
    return Observable.throw(errMsg);
  }

  //apiUrl: string = 'http://192.168.0.20/quiz/api';
  //apiUrl: string = 'http://server03/quiz/api';
  //apiUrl: string = 'http://localhost:53417/api';
  apiUrl = 'http://localhost:21021/api/services/app';


  getAll() {
    const urls = new Array();
    for (let i = 1; i <= 10; i++) {
      urls.push({ id: i, url: `${this.apiUrl}/QuizService/Get?workplaceID=${i}`, name: `${i} место` });
    }
    return urls;
  }

  postReload(paramd2e: number) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8' });

    let params = {
        direction2examID: paramd2e
      };
      let body = JSON.stringify(params);

    return this.http.post(this.apiUrl + '/Quiz/ReloadTestQuiz?direction2examID=' + paramd2e, body, { headers: headers })
    // return this.http.post(this.apiUrl + '/Quiz/ReloadTestQuiz', null, options)
      // .map((resp: Response) => resp.json())
      // .subscribe(() => re)
      .catch((error: any) => { return Observable.throw(error); });
  }

  postAnswers(quiz: Quiz) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8' });
    const body = JSON.stringify(quiz);

    // if (quiz.directionmodeid === 1) {
    //   // let headers = new Headers({'Content-Type': 'application/json'});
    //   let options = new RequestOptions({headers: headers});
    //   let params = {
    //     direction2examID: quiz.id
    //   };
    //   let body = JSON.stringify(params);

    //   return this.http.post(this.apiUrl + '/Quiz/ReloadTestQuiz/', body, options )
    //                     .map((resp: Response) => resp.json())
    //                     .catch((error: any) => {return Observable.throw(error); });

    // } else {
    return this.http.post(this.apiUrl + '/Quiz/', body, { headers: headers })
      .map((resp: Response) => resp.json())
      .catch((error: any) => { return Observable.throw(error); });
  }
}
