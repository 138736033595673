export const QUIZ_GRADES_NAMES = {
    grade4: 'Периодическая проверка 4 разряд',
    grade6: 'Периодическая проверка 6 разряд',
    special: 'Лица с особыми уставными задачами',
    grade4qe: 'Квалификационный экзамен 4 разряд',
    grade6qe: 'Квалификационный экзамен 6 разряд',
    gro: 'Гражданское оружие'
};

export const QUIZ = 
{
    "id": 235847,
    "directionmodeid": 1,
    "name": "Экзамен на месте 2",
    "takerfio": "Иванко Д.В.",
    "autorid": 4183,
    "description": "--",
    "questions": [
        {
            "id": 6120,
            "name": "Причинение вреда, менее значительного, чем предотвращенный вред, является обязательным условием правомерности действий:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60279,
                    "questionId": 6120,
                    "name": "В состоянии необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60280,
                    "questionId": 6120,
                    "name": "Как в состоянии необходимой обороны, так и в состоянии крайней необходимости.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60281,
                    "questionId": 6120,
                    "name": "состоянии крайней необходимости.",
                    "isAnswer": true,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6140,
            "name": "Действия по временному изъятию орудия преступления (до прибытия на место происшествия сотрудников правоохранительных органов) могут производиться частным охранником:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60282,
                    "questionId": 6140,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в случае совершения любого преступления).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60283,
                    "questionId": 6140,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в случае совершения тяжкого преступления).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60284,
                    "questionId": 6140,
                    "name": "При отсутствии права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в условиях крайней необходимости, когда иным способом невозможно устранить опасность охраняемым законом интересам).",
                    "isAnswer": true,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6153,
            "name": "Согласно Перечню видов вооружения охранников, утвержденному Правительством РФ, огнестрельное гладкоствольное длинноствольное оружие отечественного производства включено в указанный перечень:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60285,
                    "questionId": 6153,
                    "name": "Как сертифицированное в установленном порядке в качестве гражданского оружия, и как сертифицированное в установленном порядке в качестве служебного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60286,
                    "questionId": 6153,
                    "name": "Только как сертифицированное в установленном порядке в качестве гражданского оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60287,

"questionId": 6153,
                    "name": "Только как сертифицированное в установленном порядке в качестве служебного оружия.",
                    "isAnswer": true,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6156,
            "name": "Какие действия охранника, вынужденного передвигаться под огнем противника, не помогают избежать поражения противником:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60291,
                    "questionId": 6156,
                    "name": "Передвигаться кратчайшим путем, не меняя направление движения.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 60292,
                    "questionId": 6156,
                    "name": "Передвигаться, каждые 3-5 секунд производя выстрелы в направлении противника (если охранник вооружен и противник виден охраннику).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60293,
                    "questionId": 6156,
                    "name": "Передвигаться, каждые 3-5 секунд укрываясь за имеющимися укрытиями; при отсутствии укрытий - каждые 3-5 секунд резко менять направление движения.",
                    "isAnswer": false,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6155,
            "name": "На охраняемом объекте у одного из двух вооруженных охранников случился сердечный приступ. Какие действия второго охранника будут оптимальными: ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60288,
                    "questionId": 6155,
                    "name": "Вызвать «скорую помощь», сообщить о случившемся дежурному охранного предприятия; в случае госпитализации заболевшего забрать у него оружие (убрать его в сейф либо держать при себе) и по прибытии лица, ответственного за сохранность оружия в предприятии, передать ему оружие.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 60289,
                    "questionId": 6155,
                    "name": "Вызвать «скорую помощь», сообщить о случившемся дежурному охранного предприятия; в случае госпитализации заболевшего, не забирая у него оружие, продолжить исполнение должностных обязанностей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60290,
                    "questionId": 6155,
                    "name": "Сообщить о случившемся дежурному охранного предприятия, дождаться замены охранника, после чего вызвать «скорую помощь».",
                    "isAnswer": false,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6190,
            "name": "Что надо сделать для определения наличия дыхания при бессознательном состоянии пострадавшего?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60294,
                    "questionId": 6190,
                    "name": "Поднести зеркальце или птичье перо к носу пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {


"id": 60295,
                    "questionId": 6190,
                    "name": "Поднести к носу пострадавшего внутреннюю сторону своего запястья или щеку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 60296,
                    "questionId": 6190,
                    "name": "Приложить ухо к груди пострадавшего и прослушать дыхание.",
                    "isAnswer": false,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6201,
            "name": "К основному назначению специального средства «жилет защитный», используемого в частной охранной деятельности, можно отнести:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60297,
                    "questionId": 6201,
                    "name": "Обеспечение индивидуальной защиты туловища человека от средств поражения (пуль, осколков, холодного оружия).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 60298,
                    "questionId": 6201,
                    "name": "Обеспечение индивидуальной защиты туловища, конечностей, стоп ног и кистей рук человека от средств поражения (пуль, осколков, холодного оружия).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60299,
                    "questionId": 6201,
                    "name": "Прекращение буйства и бесчинства задержанных лиц.",
                    "isAnswer": false,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6272,
            "name": "По своему назначению выбрасыватель пистолета служит:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60303,
                    "questionId": 6272,
                    "name": "Для извлечения магазина из рукоятки пистолета.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60304,
                    "questionId": 6272,
                    "name": "Для отвода неизрасходованной части пороховых газов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60305,
                    "questionId": 6272,
                    "name": "Для удержания гильзы (патрона) в чашечке затвора до встречи с отражателем.",
                    "isAnswer": true,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6262,
            "name": "Безопасное использование оружия предполагает в период непосредственного применения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60300,
                    "questionId": 6262,
                    "name": "Контролировать направления ствола оружия при досылке патрона в патронник только в ситуациях близости несовершеннолетних или ценного имущества.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60301,
                    "questionId": 6262,


"name": "Не отвлекаться на контроль направления ствола оружия при досылке патрона в патронник.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60302,
                    "questionId": 6262,
                    "name": "Обязательный контроль направления ствола оружия при досылке патрона в патронник для исключения возможного вреда посторонним лицам.",
                    "isAnswer": true,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 6299,
            "name": "В чем состоит особенность действий охранника 6 разряда в ходе противодействия террористическим угрозам",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 60306,
                    "questionId": 6299,
                    "name": "В связи с возможным наличием у охранника служебного и/или гражданского оружия, а также специальных средств, необходимо дополнительно прогнозировать эффективность, а также возможные положительные и отрицательные последствия от их применения, учитывая опасность террористической угрозы.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 60307,
                    "questionId": 6299,
                    "name": "В связи с возможным наличием у охранника служебного и/или гражданского оружия, а также специальных средств, необходимо понимать, что в ситуации противодействия террористическим угрозам их применять категорически запрещается.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 60308,
                    "questionId": 6299,
                    "name": "Каких-либо особенностей действий для охранника 6 разряда в ходе противодействия террористическим угрозам не усматривается.",
                    "isAnswer": false,
                    "selected": true
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        }
    ],
    "periodicnypename": "--",
    "guardcatname": "6 разряд"
}