import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import "rxjs/add/operator/map";
import { LocalStorageModule } from 'angular-2-local-storage';

import { AppComponent } from './app.component';
import { ChannelService, ChannelConfig, SignalrWindow } from './services/channel.service';
import { QuizComponent } from './quiz/quiz.component';
import { QuizProgressbarComponent } from './quiz/quiz-progressbar/quiz-progressbar.component';
import { FirmaService } from "app/services/firma.service";
import { ExamFormSettingsComponent } from './exam-form-settings/exam-form-settings.component';
import { AppRoutingModule } from './app-routing.module';
import {APP_BASE_HREF} from '@angular/common';
import { ReadingComponent } from './reading/reading.component';
import { MyMetrikaModule } from './metrika.module';


let channelConfig = new ChannelConfig();  
channelConfig.url = "http://localhost:9123/signalr";  
// channelConfig.url = "http://localhost:9123/signalr";  
channelConfig.hubName = "EventHub";


@NgModule({
  declarations: [
    AppComponent,
    QuizComponent,
    QuizProgressbarComponent,
    ExamFormSettingsComponent,
    ReadingComponent,
    // ChannelService,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MyMetrikaModule,
    AppRoutingModule,
    LocalStorageModule.withConfig({
            prefix: 'my-app',
            storageType: 'localStorage'
        })
  ],
  providers: [
    FirmaService,
    {provide: APP_BASE_HREF, useValue : '/' }
    // ChannelService, 
    // { provide: SignalrWindow, useValue: window },
    // { provide: 'channel.config', useValue: channelConfig }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
