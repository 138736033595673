export const QUIZ_GRADE4QE = {
    "id": 237002,
    "directionmodeid": 1,
    "name": "Экзамен на месте 1",
    "takerfio": "Иванов Иван Иванович",
    "autorid": 4183,
    "description": "--",
    "questions": [
        {
            "id": 7317,
            "name": "В соответствии с Уголовным кодексом Российской Федерации под хищением понимаются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80991,
                    "questionId": 7317,
                    "name": "Совершенные с корыстной целью безвозмездное изъятие и (или) обращение чужого имущества в пользу виновного или других лиц, причинившие ущерб собственнику или иному владельцу этого имущества",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80992,
                    "questionId": 7317,
                    "name": "Совершенные с корыстной целью противоправные безвозмездное изъятие и (или) обращение чужого имущества в пользу виновного или других лиц, причинившие ущерб собственнику или иному владельцу этого имущества.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80993,
                    "questionId": 7317,
                    "name": "Совершенные, независимо от наличия корыстной цели, противоправные безвозмездное изъятие и (или) обращение чужого имущества в пользу виновного или других лиц, причинившие ущерб собственнику или иному владельцу этого имущества.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7320,
            "name": "В соответствии с Уголовным кодексом Российской Федерации под разбоем понимается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81000,
                    "questionId": 7320,
                    "name": "Нападение в целях хищения чужого имущества, совершенное с применением насилия, опасного для жизни или здоровья, либо с угрозой применения такого насилия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81001,
                    "questionId": 7320,
                    "name": "Нападение, совершенное с применением насилия, опасного для жизни или здоровья, либо с угрозой применения такого насилия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81002,
                    "questionId": 7320,
                    "name": "Открытое хищение чужого имущества.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7326,
            "name": "Тарифно-квалификационные характеристики профессии рабочего «Охранник» для 4 разряда содержат следующую формулировку: ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81018,
                    "questionId": 7326,
                    "name": "При охране помещений, территорий объектов, имущества в процессе его транспортировки с использованием при необходимости специальных средств и гражданского оружия, разрешенных в частной охранной деятельности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81019,
                    "questionId": 7326,
                    "name": "При охране помещений, территорий объектов, имущества в процессе его транспортировки с использованием при необходимости специальных средств, гражданского и служебного оружия, разрешенных в частной охранной деятельности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81020,
                    "questionId": 7326,
                    "name": "При охране помещений, территорий объектов, имущества в процессе его транспортировки с использованием при необходимости специальных средств, разрешенных в частной охранной деятельности.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7323,
            "name": "В соответствии с Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» правовую основу частной детективной и охранной деятельности составляют:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81009,
                    "questionId": 7323,
                    "name": "«Конституция Российской Федерации, настоящий Закон, другие законы и иные правовые акты Российской Федерации, правовые акты органов внутренних дел по субъектам Российской Федерации».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81010,
                    "questionId": 7323,
                    "name": "«Конституция Российской Федерации, настоящий Закон, другие законы и иные правовые акты Российской Федерации».",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81011,
                    "questionId": 7323,
                    "name": "«Конституция Российской Федерации, настоящий Закон, другие законы Российской Федерации и законы субъектов Российской Федерации».",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7306,
            "name": "Удостоверение частного охранника:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80958,
                    "questionId": 7306,
                    "name": "Это документ, дающий право частному охраннику работать по трудовому договору с охранной организацией на должности, связанной непосредственно с оказанием охранных услуг.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80959,
                    "questionId": 7306,
                    "name": "Это документ, дающий право частному охраннику работать с охранной организацией по договору подряда.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80960,
                    "questionId": 7306,
                    "name": "Это документ, дающий право частному охраннику самостоятельно оказывать охранные услуги.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7266,
            "name": "В каких случаях факт того, что гражданин ранее был осужден за преступление, не является препятствием для получения им удостоверения охранника?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80838,
                    "questionId": 7266,
                    "name": "В обоих указанных выше случаях.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80839,
                    "questionId": 7266,
                    "name": "В случае, если гражданин имеет судимость за преступление, совершенное по неосторожности, либо в случае погашения или снятия судимости.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80840,
                    "questionId": 7266,
                    "name": "В случае, если гражданин имеет судимость за преступление, совершенное по неосторожности, либо осужден условно.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7215,
            "name": "Вред, причиненный в состоянии крайней необходимости:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80736,
                    "questionId": 7215,
                    "name": "Во всех случаях подлежит возмещению в полном объеме лицом, причинившим вред.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80737,
                    "questionId": 7215,
                    "name": "Не подлежит возмещению.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80738,
                    "questionId": 7215,
                    "name": "Подлежит возмещению по решению суда.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7311,
            "name": "Не вправе претендовать на приобретение правового статуса частного охранника лица, имеющие судимость за совершение преступления:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80973,
                    "questionId": 7311,
                    "name": "По легкомыслию.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80974,
                    "questionId": 7311,
                    "name": "По небрежности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80975,
                    "questionId": 7311,
                    "name": "С косвенным умыслом.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7248,
            "name": "Согласно Типовым упражнениям практического применения специальных средств наручники считаются надетыми правильно:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80805,
                    "questionId": 7248,
                    "name": "Если в надетом состоянии наручники не могут проворачиваться на конечности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80806,
                    "questionId": 7248,
                    "name": "Если в надетом состоянии наручники свободно проворачиваются (каких-либо требований о надежности фиксации конечности не предъявляется).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80807,
                    "questionId": 7248,
                    "name": "Если в надетом состоянии наручники свободно проворачиваются и надежно фиксируют конечность.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7316,
            "name": "Может ли согласно Трудового кодекса РФ в трудовом договоре предусматриваться обучение работника за счет работодателя:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80988,
                    "questionId": 7316,
                    "name": "Может, в том числе с установлением запрета работнику вступать в трудовые отношения с другими работодателями.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80989,
                    "questionId": 7316,
                    "name": "Может, в том числе с установлением обязанности работника отработать в организации определенный срок.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80990,
                    "questionId": 7316,
                    "name": "Не может.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7302,
            "name": "Срок действия удостоверения частного охранника продлевается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80946,
                    "questionId": 7302,
                    "name": "На 1 год.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80947,
                    "questionId": 7302,
                    "name": "На 3 года.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80948,
                    "questionId": 7302,
                    "name": "На 5 лет.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7276,
            "name": "Право частных охранников задерживать на месте правонарушения лицо, совершившее противоправное посягательство на охраняемое имущество либо нарушающее внутриобъектовый и (или) пропускной режимы закреплено:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80868,
                    "questionId": 7276,
                    "name": "В статье 12 Закона «О частной детективной и охранной деятельности в Российской Федерации».",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80869,
                    "questionId": 7276,
                    "name": "В статье 27.3 Кодекса Российской Федерации об административных правонарушениях.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80870,
                    "questionId": 7276,
                    "name": "В статье 91 Уголовно-процессуального кодекса Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7312,
            "name": "Частная охранная деятельность не распространяется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80976,
                    "questionId": 7312,
                    "name": "На любые объекты, в отношении которых установлены обязательные для выполнения требования к антитеррористической защищенности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80977,
                    "questionId": 7312,
                    "name": "На объекты государственной охраны и охраняемые объекты (предусмотренные Федеральным законом «О государственной охране»), а также на объекты, перечень которых утверждается Правительством Российской Федерации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80978,
                    "questionId": 7312,
                    "name": "На объекты, расположенные на территориях закрытых административно-территориальных образований.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7218,
            "name": "Имеют ли право на необходимую оборону лица, имеющие возможность избежать общественно опасного посягательства или обратиться за помощью к другим лицам или органам власти?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80745,
                    "questionId": 7218,
                    "name": "Да, имеют.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80746,
                    "questionId": 7218,
                    "name": "Имеют, если посягательство сопряжено с насилием, опасным для жизни обороняющегося.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80747,
                    "questionId": 7218,
                    "name": "Нет, не имеют.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7211,
            "name": "При необходимой обороне субъектом посягательства, отражаемого обороняющимся, является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80724,
                    "questionId": 7211,
                    "name": "Источник повышенной опасности (оружие, автомобиль и пр.).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80725,
                    "questionId": 7211,
                    "name": "Стихия (силы природы).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80726,
                    "questionId": 7211,
                    "name": "Человек (физическое лицо).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7217,
            "name": "При необходимой обороне причинение посягающему лицу любого вреда правомерно:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80742,
                    "questionId": 7217,
                    "name": "В случае группового посягательства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80743,
                    "questionId": 7217,
                    "name": "Если посягательство сопряжено с насилием, опасным для здоровья обороняющегося.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80744,
                    "questionId": 7217,
                    "name": "Если это посягательство сопряжено с насилием, опасным для жизни обороняющегося или другого лица, либо с непосредственной угрозой применения такого насилия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7319,
            "name": "В соответствии с Уголовным кодексом Российской Федерации под грабежом понимается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80997,
                    "questionId": 7319,
                    "name": "Нападение в целях хищения чужого имущества, совершенное с применением насилия, опасного для жизни или здоровья, либо с угрозой применения такого насилия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80998,
                    "questionId": 7319,
                    "name": "Открытое хищение чужого имущества.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80999,
                    "questionId": 7319,
                    "name": "Хищение чужого имущества или приобретение права на чужое имущество путем обмана или злоупотребления доверием.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7265,
            "name": "Что считается прогулом в соответствии с Трудовым кодексом Российской Федерации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80835,
                    "questionId": 7265,
                    "name": "Отсутствие на рабочем месте без уважительной причины более одного часа.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80836,
                    "questionId": 7265,
                    "name": "Отсутствие на рабочем месте без уважительной причины более четырех часов подряд.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80837,
                    "questionId": 7265,
                    "name": "Отсутствие на рабочем месте без уважительной причины от двух до четырех часов.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7292,
            "name": "Частным охранникам запрещается препятствовать:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80916,
                    "questionId": 7292,
                    "name": "Действиям должностных   лиц   правоохранительных   и   контролирующих органов, независимо от того, осуществляют ли они свою служебную деятельность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80917,
                    "questionId": 7292,
                    "name": "Законным действиям   должностных   лиц   правоохранительных   и   контролирующих органов при осуществлении указанными должностными лицами своей деятельности.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80918,
                    "questionId": 7292,
                    "name": "Любым действиям   должностных   лиц   правоохранительных   и   контролирующих органов при осуществлении указанными должностными лицами своей деятельности.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7278,
            "name": "Какое деяние признается административным правонарушением?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80874,
                    "questionId": 7278,
                    "name": "Общественно опасное действие (бездействие), за которое не предусмотрено уголовное наказание.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80875,
                    "questionId": 7278,
                    "name": "Противоправное действие (бездействие) физического или юридического лица, за которое Кодексом РФ об административных правонарушениях или законами субъектов Российской Федерации об административных правонарушениях установлена административная ответственность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80876,
                    "questionId": 7278,
                    "name": "Противоправное, виновное действие (бездействие) физического или юридического лица, за которое Кодексом РФ об административных правонарушениях или законами субъектов Российской Федерации об административных правонарушениях установлена административная ответственность.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7271,
            "name": "Действия по просмотру частными охранниками документов посетителей объекта охраны (а также осмотру вносимого и выносимого ими имущества, регистрации и обработке их персональных данных) могут осуществляться:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80853,
                    "questionId": 7271,
                    "name": "В качестве мер принуждения, применяемых независимо от воли посетителей объекта охраны, ознакомившихся с правилами внутриобъектового и пропускного режимов на объекте.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80854,
                    "questionId": 7271,
                    "name": "На добровольной основе, когда посетители ознакомились с соответствующими правилами внутриобъектового и пропускного режимов, установленными клиентом или заказчиком охранных услуг, и согласились их выполнять.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80855,
                    "questionId": 7271,
                    "name": "На основании Кодекса Российской Федерации об административных правонарушениях, независимо от наличия каких-либо правил, установленных клиентом или заказчиком охранных услуг. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7309,
            "name": "Незаконное осуществление частной охранной деятельности влечет:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80967,
                    "questionId": 7309,
                    "name": "Дисквалификацию руководителя юридического лица.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80968,
                    "questionId": 7309,
                    "name": "Наложение административного штрафа на граждан или должностных лиц; или административный арест на срок до пятнадцати суток.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80969,
                    "questionId": 7309,
                    "name": "Наложение административного штрафа на граждан; на должностных лиц; на юридических лиц.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7212,
            "name": "В соответствии с действующим законодательством при необходимой обороне допускается причинение вреда:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80727,
                    "questionId": 7212,
                    "name": "Любым лицам.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80728,
                    "questionId": 7212,
                    "name": "Посягающему лицу.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80729,
                    "questionId": 7212,
                    "name": "Третьим лицам.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7299,
            "name": "Документы для выдачи (продления срока действия) удостоверения частного охранника могут представляться:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80937,
                    "questionId": 7299,
                    "name": "По месту жительства либо по месту нахождения охранной организации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80938,
                    "questionId": 7299,
                    "name": "Только по месту жительства. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80939,
                    "questionId": 7299,
                    "name": "Только по месту нахождения охранной организации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7318,
            "name": "В соответствии с Уголовным кодексом Российской Федерации под кражей понимается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80994,
                    "questionId": 7318,
                    "name": "Открытое хищение чужого имущества.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80995,
                    "questionId": 7318,
                    "name": "Тайное хищение чужого имущества.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80996,
                    "questionId": 7318,
                    "name": "Хищение чужого имущества или приобретение права на чужое имущество путем обмана или злоупотребления доверием.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7245,
            "name": "Согласно Типовым упражнениям практического применения специальных средств положительным результатом при выполнении упражнения «Применение наручников» признается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80799,
                    "questionId": 7245,
                    "name": "Правильное надевание наручников (независимо от установленного времени).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80800,
                    "questionId": 7245,
                    "name": "Правильное надевание наручников в пределах установленного времени (независимо от того, смог ли проверяемый снять наручники).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80801,
                    "questionId": 7245,
                    "name": "Правильное надевание наручников в пределах установленного времени и последующее их снятие.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7285,
            "name": "По достижении какого возраста гражданин вправе претендовать на приобретение статуса частного охранника?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80895,
                    "questionId": 7285,
                    "name": "По достижении 18 лет.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80896,
                    "questionId": 7285,
                    "name": "По достижении 21 года.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80897,
                    "questionId": 7285,
                    "name": "По достижении 25 лет.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7308,
            "name": "Оказание частных охранных услуг, не предусмотренных законом, либо с нарушением установленных законом требований, влечет наложение административного штрафа: ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80964,
                    "questionId": 7308,
                    "name": "На граждан; на руководителей частных охранных организаций.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80965,
                    "questionId": 7308,
                    "name": "На частных охранников; на руководителей частных охранных организаций.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80966,
                    "questionId": 7308,
                    "name": "На юридическое лицо.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7304,
            "name": "Хищение чужого имущества классифицируется как мелкое и влечет уголовную ответственность:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80952,
                    "questionId": 7304,
                    "name": "Если оно совершенно лицом, не считающимся подвергнутым административному наказанию за мелкое хищение чужого имущества стоимостью более одной тысячи рублей, но не более двух тысяч пятисот рублей путем кражи, мошенничества, присвоения или растраты (при отсутствии признаков иных преступлений против собственности). ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80953,
                    "questionId": 7304,
                    "name": "Если оно совершенно лицом, подвергнутым административному наказанию за мелкое хищение чужого имущества стоимость которого не превышает одну тысячу рублей путем кражи, мошенничества, присвоения или растраты (при отсутствии признаков иных преступлений против собственности).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80954,
                    "questionId": 7304,
                    "name": "Если оно совершенно лицом, подвергнутым административному наказанию за мелкое хищение чужого имущества стоимостью более одной тысячи рублей, но не более двух тысяч пятисот рублей путем кражи, мошенничества, присвоения или растраты (при отсутствии признаков иных преступлений против собственности).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7222,
            "name": "К уголовно наказуемым деяниям относится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80757,
                    "questionId": 7222,
                    "name": "Причинение тяжкого или средней тяжести вреда здоровью по неосторожности, совершенное при превышении мер, необходимых для задержания лица, совершившего преступление.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80758,
                    "questionId": 7222,
                    "name": "Умышленное причинение легкого вреда здоровью, совершенное при превышении мер, необходимых для задержания лица, совершившего преступление.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80759,
                    "questionId": 7222,
                    "name": "Умышленное причинение тяжкого или средней тяжести вреда здоровью, совершенное при превышении мер, необходимых для задержания лица, совершившего преступление.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7279,
            "name": "В каких пределах работник несет материальную ответственность за причиненный работодателю ущерб?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80877,
                    "questionId": 7279,
                    "name": "В пределах своего среднего месячного заработка, если иное не предусмотрено Трудовым кодексом Российской Федерации или иными федеральными законами.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80878,
                    "questionId": 7279,
                    "name": "В размере не более двух средних месячных заработков, если иное не предусмотрено Трудовым кодексом Российской Федерации или иными федеральными законами.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80879,
                    "questionId": 7279,
                    "name": "В размере не более половины своего среднего месячного заработка, если иное не предусмотрено Трудовым кодексом Российской Федерации или иными федеральными законами.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7272,
            "name": "Имеет ли право гражданин Российской Федерации, имеющий удостоверение охранника, осуществлять охранную деятельность самостоятельно, без трудоустройства в частной охранной организации:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80856,
                    "questionId": 7272,
                    "name": "Имеет, при обязательном условии заключения договора с собственником охраняемого имущества.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80857,
                    "questionId": 7272,
                    "name": "Имеет.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80858,
                    "questionId": 7272,
                    "name": "Не имеет.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7297,
            "name": "В соответствии с Федеральным законом «Об образовании в Российской Федерации» квалификационный экзамен, проводимый при реализации программ профессионального обучения, включает в себя:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80931,
                    "questionId": 7297,
                    "name": "Практическую квалификационную работу и проверку теоретических знаний в пределах квалификационных требований, указанных в квалификационных справочниках, и (или) профессиональных стандартов по соответствующим профессиям рабочих, должностям служащих",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80932,
                    "questionId": 7297,
                    "name": "Практическую квалификационную работу и проверку теоретических знаний по всем дисциплинам программы профессионального обучения для работы в качестве частного охранника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80933,
                    "questionId": 7297,
                    "name": "Проверку навыков специальной физической подготовки и проверку теоретических знаний в пределах квалификационных требований, указанных в квалификационных справочниках, и (или) профессиональных стандартов, по соответствующим профессиям рабочих (должностям служащих).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7263,
            "name": "В соответствии с Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» одним из случаев аннулирования удостоверения частного охранника является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80829,
                    "questionId": 7263,
                    "name": "Непрохождение частным охранником повторной периодической проверки на пригодность к действиям в условиях, связанных с применением огнестрельного оружия и специальных средств.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80830,
                    "questionId": 7263,
                    "name": "Окончание срока действия удостоверения частного охранника.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80831,
                    "questionId": 7263,
                    "name": "Утрата или приведение его в негодность по вине частного охранника.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7313,
            "name": "Посетитель магазина, спрятавший под одеждой товар, выложенный в торговом зале магазина самообслуживания (супермаркета), считается совершившим кражу и может быть задержан частным охранником:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80979,
                    "questionId": 7313,
                    "name": "В любом месте торгового зала - до пересечения кассовой линии (как только посетитель спрятал товар под одеждой).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80980,
                    "questionId": 7313,
                    "name": "Непосредственно на кассовой линии (когда посетитель не оплатил спрятанный товар).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80981,
                    "questionId": 7313,
                    "name": "После пересечения таким лицом кассовой линии (когда посетитель получил реальную возможность пользоваться или распоряжаться похищенным по своему усмотрению).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7205,
            "name": "Какие меры принуждения могут применять частные охранники?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80718,
                    "questionId": 7205,
                    "name": "Задержание на месте правонарушения лиц, совершивших противоправное посягательство на охраняемое имущество либо нарушающих внутриобъектовый и (или) пропускной режимы, применение физической силы, специальных средств и огнестрельного оружия, разрешенных в частной охранной деятельности.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80719,
                    "questionId": 7205,
                    "name": "Изъятие предметов, досмотр транспорта, применение огнестрельного и холодного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80720,
                    "questionId": 7205,
                    "name": "Проверка документов, досмотр переносимых вещей, применение физической силы, специальных средств и огнестрельного оружия, разрешенных в частной охранной деятельности.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7298,
            "name": "Документы для выдачи (продления срока действия) удостоверения частного охранника могут представляться:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80934,
                    "questionId": 7298,
                    "name": "Гражданином либо руководителем или уполномоченным представителем охранной организации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80935,
                    "questionId": 7298,
                    "name": "Гражданином либо руководителем охранной организации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80936,
                    "questionId": 7298,
                    "name": "Только самим гражданином. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7230,
            "name": "Федеральным законом «О полиции» установлено следующее ограничение на применение специальных средств - не допускается нанесение человеку ударов палкой специальной:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80769,
                    "questionId": 7230,
                    "name": "По голове, шее, ключичной области, животу, половым органам, в область проекции сердца.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80770,
                    "questionId": 7230,
                    "name": "По рукам, ногам, ягодицам, по спине в области проекции почек и печени.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80771,
                    "questionId": 7230,
                    "name": "Только по голове, шее, ключичной области, животу.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7301,
            "name": "Для внесения изменений в удостоверение частного охранника в связи с изменением его места жительства заявление и иные установленные Правительством Российской Федерации документы предоставляются в территориальный орган Федеральной службы войск национальной гвардии Российской Федерации по месту нахождения учетного дела:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80943,
                    "questionId": 7301,
                    "name": "В течение 15 календарных дней со дня получения документов, подтверждающих такие изменения.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80944,
                    "questionId": 7301,
                    "name": "В течение 30 календарных дней со дня получения документов, подтверждающих такие изменения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80945,
                    "questionId": 7301,
                    "name": "В течение 45 календарных дней со дня получения документов, подтверждающих такие изменения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7282,
            "name": "Регистрация и обработка персональных данных прибывающих на охраняемый объект лиц (без подтверждения согласия на это посетителями в форме подписания специального документа или соответствующей подписи в журнале) может производиться охранником при следующих условиях:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80886,
                    "questionId": 7282,
                    "name": "Охранник предоставил посетителям охраняемого объекта возможность лично ознакомиться с текстом своей должностной инструкции, в которой предусмотрена обязанность посетителей предоставлять свои персональные данные для регистрации и обработки (этого достаточно, поскольку его должностная инструкция утверждена руководителем частной охранной организации и согласована с заказчиком охранных услуг).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80887,
                    "questionId": 7282,
                    "name": "Охранник устно довел до посетителей охраняемого объекта установленную его должностной инструкцией обязанность посетителей предоставлять свои персональные данные для регистрации и обработки (этого достаточно, поскольку его должностная инструкция утверждена руководителем частной охранной организации и согласована с заказчиком охранных услуг). ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80888,
                    "questionId": 7282,
                    "name": "Перед входом на охраняемую территорию размещена информация о наличии на объекте правил, установленных заказчиком охранных услуг и предусматривающих регистрацию и обработку охранником персональных данных посетителей (этого достаточно, поскольку лица, ознакомившиеся с правилами и подтвердившие согласие на их выполнение фактом прохода, согласно ГК РФ считаются одной из сторон письменного договора прохода на объект).  ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7236,
            "name": "Действия по временному изъятию орудия преступления (до прибытия на место происшествия сотрудников правоохранительных органов) могут производиться частным охранником:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80778,
                    "questionId": 7236,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в случае совершения любого преступления).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80779,
                    "questionId": 7236,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в случае совершения тяжкого преступления).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80780,
                    "questionId": 7236,
                    "name": "При отсутствии права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в условиях крайней необходимости, когда иным способом невозможно устранить опасность охраняемым законом интересам).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7260,
            "name": "Согласно Закону РФ «О частной детективной и охранной деятельности в РФ» решение об аннулировании удостоверения частного охранника принимает:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80820,
                    "questionId": 7260,
                    "name": "Руководитель частной охранной организации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80821,
                    "questionId": 7260,
                    "name": "Суд.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80822,
                    "questionId": 7260,
                    "name": "Федеральный орган исполнительной власти, уполномоченный в сфере частной охранной деятельности, или его территориальный орган.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7314,
            "name": "В соответствии с Трудовым кодексом Российской Федерации за совершение работником дисциплинарного проступка работодатель имеет право применить следующие дисциплинарные взыскания:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80982,
                    "questionId": 7314,
                    "name": "Замечание, выговор, строгий выговор, неполное служебное соответствие, перевод на нижестоящую должность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80983,
                    "questionId": 7314,
                    "name": "Замечание, выговор, строгий выговор, штраф, лишение премии.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80984,
                    "questionId": 7314,
                    "name": "Замечание, выговор, увольнение по соответствующим основаниям.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7305,
            "name": "В каком из перечисленных случаев на работника возлагается материальная ответственность перед работодателем в полном размере причиненного ущерба (в соответствии с Трудовым кодексом Российской Федерации)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80955,
                    "questionId": 7305,
                    "name": "В случае возбуждения уголовного дела в связи с действиями работника, причинившими ущерб (независимо от решения суда, принятого по делу).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80956,
                    "questionId": 7305,
                    "name": "В случае причинения ущерба в результате административного правонарушения, установленного соответствующим государственным органом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80957,
                    "questionId": 7305,
                    "name": "При недостаче ценностей, вверенных работнику, в том числе, при отсутствии специального письменного договора или разового документа.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7257,
            "name": "Удостоверение частного охранника выдается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80811,
                    "questionId": 7257,
                    "name": "На 3 года.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80812,
                    "questionId": 7257,
                    "name": "На 4 года.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80813,
                    "questionId": 7257,
                    "name": "На 5 лет.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7240,
            "name": "На какой срок частный охранник признается непригодным к действиям в условиях, связанных с применением огнестрельного оружия и (или) специальных средств (в связи с непрохождением им повторной периодической проверки либо неявкой без уважительных причин на повторную периодическую проверку)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80784,
                    "questionId": 7240,
                    "name": "До даты очередного прохождения периодической проверки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80785,
                    "questionId": 7240,
                    "name": "На срок не более трех месяцев.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80786,
                    "questionId": 7240,
                    "name": "На срок не более шести месяцев.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7321,
            "name": "В соответствии с Уголовным кодексом Российской Федерации под присвоением или растратой понимается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81003,
                    "questionId": 7321,
                    "name": "Тайное хищение чужого имущества.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81004,
                    "questionId": 7321,
                    "name": "Хищение чужого имущества или приобретение права на чужое имущество путем обмана или злоупотребления доверием.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81005,
                    "questionId": 7321,
                    "name": "Хищение чужого имущества, вверенного виновному.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7275,
            "name": "Может ли быть работником частной охранной организации должностное лицо организации, с которой данной частной охранной организацией заключен договор на оказание охранных услуг?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80865,
                    "questionId": 7275,
                    "name": "Может в случае, если это предусмотрено договором на оказание охранных услуг данной организации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80866,
                    "questionId": 7275,
                    "name": "Может.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80867,
                    "questionId": 7275,
                    "name": "Не может.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7281,
            "name": "На какой разряд вправе претендовать гражданин, сдающий квалификационный экзамен на охранника в соответствии с Единым тарифно-квалификационным справочником работ и профессий рабочих?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80883,
                    "questionId": 7281,
                    "name": "На первый, второй и третий разряды.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80884,
                    "questionId": 7281,
                    "name": "На четвертый, пятый и шестой разряды.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80885,
                    "questionId": 7281,
                    "name": "На шестой, седьмой и восьмой разряды.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7238,
            "name": "Согласно нормативным правовым актам Правительства Российской Федерации ношение специальных средств на каждом объекте охраны осуществляется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80781,
                    "questionId": 7238,
                    "name": "В соответствии с должностной инструкцией частного охранника.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80782,
                    "questionId": 7238,
                    "name": "В соответствии с инструкцией предприятия-производителя соответствующего специального средства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80783,
                    "questionId": 7238,
                    "name": "В соответствии с Положением о пропускном и внутриобъектовом режимах, утвержденным Заказчиком охранных услуг.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7267,
            "name": "Должен ли охранник беспрепятственно допустить на охраняемый объект лиц, представившихся работниками правоохранительных органов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80841,
                    "questionId": 7267,
                    "name": "Должен в любом случае, если предъявлены удостоверения, сходные с удостоверениями работников правоохранительных органов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80842,
                    "questionId": 7267,
                    "name": "Должен в случаях, когда законом предусмотрен беспрепятственный допуск, после того как убедится, что указанные лица являются работниками правоохранительных органов.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80843,
                    "questionId": 7267,
                    "name": "Не должен.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7291,
            "name": "Частные охранники при обеспечении внутриобъектового и пропускного режимов ставшую им известной информацию о готовящихся либо совершенных преступлениях, а также о действиях, об обстоятельствах, создающих на объектах охраны угрозу безопасности людей обязаны незамедлительно сообщать:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80913,
                    "questionId": 7291,
                    "name": "В соответствующие правоохранительные органы.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80914,
                    "questionId": 7291,
                    "name": "Представителю клиента или заказчика и в соответствующие правоохранительные органы.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80915,
                    "questionId": 7291,
                    "name": "Руководителю частной охранной организации и в соответствующие правоохранительные органы.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7214,
            "name": "Допускается ли причинение вреда третьим лицам в состоянии необходимой обороны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80733,
                    "questionId": 7214,
                    "name": "Да, при вооруженном нападении.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80734,
                    "questionId": 7214,
                    "name": "Да, при групповом нападении.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80735,
                    "questionId": 7214,
                    "name": "Нет.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7269,
            "name": "Какова минимальная продолжительность ежегодного отпуска, предусмотренная Трудовым кодексом РФ?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80847,
                    "questionId": 7269,
                    "name": "Не менее 28 календарных дней.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80848,
                    "questionId": 7269,
                    "name": "Не менее 28 рабочих дней.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80849,
                    "questionId": 7269,
                    "name": "По усмотрению администрации, но не менее 14 календарных дней.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7274,
            "name": "При увольнении трудовая книжка выдается работнику:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80862,
                    "questionId": 7274,
                    "name": "В день увольнения.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80863,
                    "questionId": 7274,
                    "name": "За одни сутки до увольнения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80864,
                    "questionId": 7274,
                    "name": "Не позднее трех рабочих дней, следующих за днем увольнения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7315,
            "name": "В соответствии с Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» частным охранником признается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80985,
                    "questionId": 7315,
                    "name": "«Гражданин Российской Федерации, достигший восемнадцати лет, прошедший профессиональное обучение для работы в качестве частного охранника и сдавший квалификационный экзамен, при условии прохождениям им периодической проверки на пригодность к действиям в условиях, связанных с применением огнестрельного оружия и специальных средств».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80986,
                    "questionId": 7315,
                    "name": "«Гражданин Российской Федерации, достигший восемнадцати лет, прошедший профессиональное обучение для работы в качестве частного охранника, сдавший квалификационный экзамен, получивший в установленном настоящим Законом порядке удостоверение частного охранника и работающий по трудовому договору с охранной организацией».",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80987,
                    "questionId": 7315,
                    "name": "«Гражданин Российской Федерации, достигший двадцати одного года, прошедший профессиональное обучение для работы в качестве частного охранника, сдавший квалификационный экзамен, получивший в установленном настоящим Законом порядке удостоверение частного охранника и работающий по трудовому договору с охранной организацией».",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7235,
            "name": "Действия по охране места происшествия, связанные с ограничением передвижения людей и транспортных средств, могут производиться частным охранником:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80775,
                    "questionId": 7235,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (для действий на месте совершения любого правонарушения).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80776,
                    "questionId": 7235,
                    "name": "В силу соответствующего права, закрепленного в законодательстве, регулирующем частную охранную деятельность (для действий на месте совершения тяжкого преступления).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80777,
                    "questionId": 7235,
                    "name": "При отсутствии права, закрепленного в законодательстве, регулирующем частную охранную деятельность (в условиях крайней необходимости, когда иным способом невозможно устранить опасность охраняемым законом интересам, а также сохранить следы преступления до прибытия сотрудников правоохранительных органов).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7229,
            "name": "В каких случаях частному охраннику не запрещается применять специальные средства в отношении женщин с видимыми признаками беременности, лиц с явными признаками инвалидности и несовершеннолетних, возраст которых охраннику очевиден или известен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80766,
                    "questionId": 7229,
                    "name": "В случае оказания ими вооруженного сопротивления, совершения группового либо иного нападения, угрожающего жизни и здоровью частного охранника или охраняемому имуществу.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80767,
                    "questionId": 7229,
                    "name": "В случае отказа нарушителя подчиниться требованию охранника проследовать в помещение охраны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80768,
                    "questionId": 7229,
                    "name": "В случаях оказания указанными лицами группового сопротивления.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7294,
            "name": "В соответствии с Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» частные охранники не имеют права производить осмотр на объектах охраны, на которых установлен пропускной режим, въезжающих на объекты охраны (выезжающих с объектов охраны) транспортных средств:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80922,
                    "questionId": 7294,
                    "name": "Администрации охраняемого объекта и частной охранной организации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80923,
                    "questionId": 7294,
                    "name": "Любых органов, осуществляющих государственный и муниципальный контроль.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80924,
                    "questionId": 7294,
                    "name": "Оперативных служб государственных военизированных организаций.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7243,
            "name": "Частные охранники имеют право применять физическую силу:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80793,
                    "questionId": 7243,
                    "name": "В случаях, если Законом РФ «О частной детективной и охранной деятельности в РФ» им разрешено применение специальных средств или огнестрельного оружия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80794,
                    "questionId": 7243,
                    "name": "Только в случаях, если Законом РФ «О частной детективной и охранной деятельности в РФ» им разрешено применение огнестрельного оружия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80795,
                    "questionId": 7243,
                    "name": "Только в случаях, если Законом РФ «О частной детективной и охранной деятельности в РФ» им разрешено применение специальных средств.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7206,
            "name": "Какие виды специальных средств разрешается использовать в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80721,
                    "questionId": 7206,
                    "name": "Защитные шлемы, защитные жилеты, наручники и резиновые палки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80722,
                    "questionId": 7206,
                    "name": "Резиновые палки, наручники, средства для принудительной остановки транспорта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80723,
                    "questionId": 7206,
                    "name": "Резиновые палки, слезоточивые вещества, служебных собак.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7286,
            "name": "В соответствии с Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» под объектами охраны понимаются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80898,
                    "questionId": 7286,
                    "name": "Любые объекты, в отношении которых осуществляются охранные мероприятия в связи с возможными и возникающими для них угрозами. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80899,
                    "questionId": 7286,
                    "name": "Недвижимые вещи (включая здания, строения, сооружения), движимые вещи (включая транспортные средства, грузы, денежные средства, ценные бумаги), в том числе при их транспортировке, а также физические лица.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80900,
                    "questionId": 7286,
                    "name": "Недвижимые вещи (включая здания, строения, сооружения), движимые вещи (включая транспортные средства, грузы, денежные средства, ценные бумаги), в том числе при их транспортировке.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7268,
            "name": "Будет ли отвечать всем условиям закона задержание частным охранником лица, нарушающего внутриобъектовый и (или) пропускной режимы, в случае если в действиях по нарушению указанных режимов нет состава административного правонарушения или преступления (либо отсутствуют иные основания, по которым полиция имеет право на доставление в орган внутренних дел)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80844,
                    "questionId": 7268,
                    "name": "Будет, при условии, что задержание такого лица предусмотрено положением о внутриобъектовом и (или) пропускном режиме на объекте.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80845,
                    "questionId": 7268,
                    "name": "Будет.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80846,
                    "questionId": 7268,
                    "name": "Не будет, поскольку в отсутствие оснований для доставления в полицию орган внутренних дел не примет задержанного от частного охранника (а его незамедлительная передача в орган внутренних дел – обязательное условие задержания).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7241,
            "name": "В каких случаях ненадлежащее исполнение обязанностей лицом, которому была поручена охрана огнестрельного оружия, боеприпасов, взрывчатых веществ или взрывных устройств влечет уголовную ответственность:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80787,
                    "questionId": 7241,
                    "name": "Если это повлекло их хищение или уничтожение либо наступление иных тяжких последствий.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80788,
                    "questionId": 7241,
                    "name": "Независимо от последствий неисполнения указанных обязанностей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80789,
                    "questionId": 7241,
                    "name": "Только в случае их хищения или уничтожения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7277,
            "name": "Какое деяние признается преступлением? ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80871,
                    "questionId": 7277,
                    "name": "Виновно совершенное общественно опасное деяние, запрещенное Уголовным кодексом Российской Федерации под угрозой наказания.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80872,
                    "questionId": 7277,
                    "name": "Виновно совершенное общественно опасное деяние.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80873,
                    "questionId": 7277,
                    "name": "Общественно опасное деяние, запрещенное Уголовным кодексом Российской Федерации под угрозой наказания, независимо от наличия вины.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7216,
            "name": "Причинение вреда, менее значительного, чем предотвращенный вред, является обязательным условием правомерности действий:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80739,
                    "questionId": 7216,
                    "name": "В состоянии крайней необходимости.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80740,
                    "questionId": 7216,
                    "name": "В состоянии необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80741,
                    "questionId": 7216,
                    "name": "Как в состоянии необходимой обороны, так и в состоянии крайней необходимости.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7221,
            "name": "К уголовно наказуемым деяниям относится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80754,
                    "questionId": 7221,
                    "name": "Причинение тяжкого вреда здоровью по неосторожности, совершенное при превышении пределов необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80755,
                    "questionId": 7221,
                    "name": "Умышленное причинение средней тяжести вреда здоровью, совершенное при превышении пределов необходимой обороны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80756,
                    "questionId": 7221,
                    "name": "Умышленное причинение тяжкого вреда здоровью, совершенное при превышении пределов необходимой обороны.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7300,
            "name": "В случае утраты удостоверения частного охранника, приведения его в негодность, изменения фамилии (имени, отчества) гражданин обязан сообщить об этом в территориальный орган Федеральной службы войск национальной гвардии Российской Федерации по месту нахождения учетного дела:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80940,
                    "questionId": 7300,
                    "name": "Не позднее 10 дней со дня наступления таких событий.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80941,
                    "questionId": 7300,
                    "name": "Не позднее 15 дней со дня наступления таких событий.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80942,
                    "questionId": 7300,
                    "name": "Не позднее 5 дней со дня наступления таких событий.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7256,
            "name": "Могут ли иностранные граждане принимать участие в частной охранной деятельности на территории Российской Федерации? ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80808,
                    "questionId": 7256,
                    "name": "Могут на основаниях и в рамках, предусмотренных международными договорами Российской Федерации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80809,
                    "questionId": 7256,
                    "name": "Могут.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80810,
                    "questionId": 7256,
                    "name": "Не могут.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7307,
            "name": "Для получения удостоверения частного охранника гражданином, работающим в частной охранной организации и получающим удостоверение по месту ее нахождения, дополнительно к основным документам (заявлению о выдаче удостоверения, копии паспорта гражданина Российской Федерации, медицинскому заключению об отсутствии медицинских противопоказаний к исполнению обязанностей частного охранника, копии документа о квалификации, со дня выдачи которого прошло не более пяти лет, двум фотографиям 4 x 6 см и анкете, форма которой утверждается Федеральной службой войск национальной гвардии Российской Федерации), в территориальный орган Федеральной службы войск национальной гвардии Российской Федерации представляется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80961,
                    "questionId": 7307,
                    "name": "Выписка из протокола профсоюзной организации по месту работы гражданина.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80962,
                    "questionId": 7307,
                    "name": "Выписка из трудовой книжки, заверенная подписью должностного лица и печатью организации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80963,
                    "questionId": 7307,
                    "name": "Характеристика с места работы гражданина.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7258,
            "name": "Обязан ли частный охранник иметь личную карточку охранника?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80814,
                    "questionId": 7258,
                    "name": "Да, обязан.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80815,
                    "questionId": 7258,
                    "name": "Нет, не обязан.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80816,
                    "questionId": 7258,
                    "name": "Обязан только в случае выполнения трудовой функции со служебным оружием.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7322,
            "name": "В соответствии с Уголовным кодексом Российской Федерации под мошенничеством понимается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81006,
                    "questionId": 7322,
                    "name": "Тайное хищение чужого имущества.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81007,
                    "questionId": 7322,
                    "name": "Хищение чужого имущества или приобретение права на чужое имущество путем обмана или злоупотребления доверием.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81008,
                    "questionId": 7322,
                    "name": "Хищение чужого имущества, вверенного виновному.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7261,
            "name": "В каких случаях Закон РФ «О частной детективной и охранной деятельности в РФ» предусматривает обязательное информирование персонала и посетителей объекта охраны путем размещения соответствующей информации до входа на охраняемую территорию?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80823,
                    "questionId": 7261,
                    "name": "В случае оказания охранных услуг на особо важных и режимных объектах.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80824,
                    "questionId": 7261,
                    "name": "В случае оказания охранных услуг с использованием видеонаблюдения, а также оказания охранных услуг в виде обеспечения внутриобъектового и (или) пропускного режимов.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80825,
                    "questionId": 7261,
                    "name": "В случае оказания услуг по охране имущества.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7273,
            "name": "Допускается ли расторжение трудового договора по инициативе работодателя в случае однократного появления работника на рабочем месте в состоянии алкогольного или наркотического опьянения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80859,
                    "questionId": 7273,
                    "name": "Допускается только при наличии уровня алкоголя в крови не менее 4 промилле.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80860,
                    "questionId": 7273,
                    "name": "Допускается.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80861,
                    "questionId": 7273,
                    "name": "Не допускается.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7329,
            "name": "Согласно статьям 7 и 12 Закона Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» при осуществлении охранной деятельности запрещается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81021,
                    "questionId": 7329,
                    "name": "Оказывать содействие правоохранительным органам в решении возложенных на них задач в рабочее время.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81022,
                    "questionId": 7329,
                    "name": "Скрывать от правоохранительных органов ставшие известными факты готовящихся, совершаемых или совершенных преступлений (за исключением случаев, определенных заказчиком охранных услуг).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81023,
                    "questionId": 7329,
                    "name": "Скрывать от правоохранительных органов ставшие известными факты готовящихся, совершаемых или совершенных преступлений.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7293,
            "name": "Частные охранники имеют право требовать от персонала и посетителей объектов охраны соблюдения внутриобъектового и пропускного режимов:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80919,
                    "questionId": 7293,
                    "name": "При обеспечении внутриобъектового и пропускного режимов в пределах объекта охраны, а также при транспортировке охраняемых грузов, денежных средств и иного имущества.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80920,
                    "questionId": 7293,
                    "name": "При обеспечении любых охранных услуг, предусмотренных законом.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80921,
                    "questionId": 7293,
                    "name": "При осуществлении обязанностей по защите жизни и здоровья граждан. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7325,
            "name": "Журналы (реестры, книги), содержащие персональные данные посетителей, необходимые для однократного пропуска на территорию охраняемых объектов:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81015,
                    "questionId": 7325,
                    "name": "Вводятся соответствующими актами органов внутренних дел по месту нахождения охраняемых объектов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81016,
                    "questionId": 7325,
                    "name": "Вводятся соответствующими актами органов местного самоуправления, на территории которых находятся объекты.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81017,
                    "questionId": 7325,
                    "name": "Должны быть предусмотрены соответствующими актами заказчиков охранных услуг.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7213,
            "name": "Могут ли действия охранника по защите жизни и здоровья другого лица расцениваться как действия в состоянии необходимой обороны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80730,
                    "questionId": 7213,
                    "name": "Могут, если соблюдены условия необходимой обороны, предусмотренные законом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80731,
                    "questionId": 7213,
                    "name": "Могут, только если при указанном лице находилось охраняемое имущество.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80732,
                    "questionId": 7213,
                    "name": "Не могут ни при каких условиях.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7289,
            "name": "Частные охранники при обеспечении внутриобъектового и пропускного режимов обязаны предъявлять удостоверение частного охранника:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80907,
                    "questionId": 7289,
                    "name": "По просьбе любых посетителей объектов охраны, независимо от их должностного положения и гражданства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80908,
                    "questionId": 7289,
                    "name": "По требованию сотрудников правоохранительных органов, других граждан.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80909,
                    "questionId": 7289,
                    "name": "Только по требованию руководства частной охранной организации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7310,
            "name": "Страхование граждан, занимающихся частной охранной деятельностью, на случай их гибели, получения увечья или иного повреждения здоровья в связи с оказанием охранных услуг осуществляется (в порядке, установленном законодательством Российской Федерации): ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80970,
                    "questionId": 7310,
                    "name": "За счет государственных средств. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80971,
                    "questionId": 7310,
                    "name": "За счет средств гражданина, занимающегося частной охранной деятельностью.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80972,
                    "questionId": 7310,
                    "name": "За счет средств соответствующей охранной организации.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7244,
            "name": "Согласно Типовым упражнениям практического применения специальных средств при выполнении упражнения «Применение наручников» снятие наручников производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80796,
                    "questionId": 7244,
                    "name": "В пределах времени, установленного для выполнения упражнения (25 секунд).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80797,
                    "questionId": 7244,
                    "name": "В пределах времени, установленного для выполнения упражнения или за его пределами (по усмотрению проверяющего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80798,
                    "questionId": 7244,
                    "name": "За пределами времени, установленного для выполнения упражнения (после завершения надевания наручников, доклада проверяемого «Наручники надеты» и проверки правильности надевания наручников проверяющим).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7296,
            "name": "С какой периодичностью частные охранники обязаны проходить медицинское освидетельствование на наличие или отсутствие заболеваний, препятствующих исполнению обязанностей частного охранника?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80928,
                    "questionId": 7296,
                    "name": "Ежегодно.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80929,
                    "questionId": 7296,
                    "name": "Ежеквартально.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80930,
                    "questionId": 7296,
                    "name": "Один раз в пять лет, при продлении срока действия удостоверения частного охранника.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7283,
            "name": "Оказание охранных услуг в специальной форменной одежде в соответствии с законом является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80889,
                    "questionId": 7283,
                    "name": "Обязанностью работников частной охранной организации (не зависимо от каких бы то ни было условий).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80890,
                    "questionId": 7283,
                    "name": "Правом работников частной охранной организации (если иное не оговорено в договоре с заказчиком).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80891,
                    "questionId": 7283,
                    "name": "Правом работников частной охранной организации (не зависимо от каких бы то ни было условий).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7234,
            "name": "В каких случаях охраннику дозволяется не предупреждать о намерении использовать физическую силу, специальные средства и огнестрельное оружие?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80772,
                    "questionId": 7234,
                    "name": "Когда имеется угроза применения насилия, опасного для жизни охранника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80773,
                    "questionId": 7234,
                    "name": "Когда может возникнуть угроза жизни и здоровью охраняемых граждан.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80774,
                    "questionId": 7234,
                    "name": "Когда промедление в применении специальных средств или огнестрельного оружия создает непосредственную опасность его жизни и здоровью или может повлечь за собой иные тяжкие последствия.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7324,
            "name": "Копирование информации, содержащейся в журналах (реестрах, книгах), содержащих персональные данные, необходимые для однократного пропуска субъекта персональных данных на территорию оператора (охраняемую территорию объекта Заказчика охранных услуг):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81012,
                    "questionId": 7324,
                    "name": "Допускается в случае, если указание на это дано представителем Заказчика охранных услуг.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81013,
                    "questionId": 7324,
                    "name": "Допускается в случае, если указание на это дано руководителем частной охранной организации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81014,
                    "questionId": 7324,
                    "name": "Не допускается.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7290,
            "name": "В соответствии с Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» частные охранники при обеспечении внутриобъектового и пропускного режимов обязаны руководствоваться:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80910,
                    "questionId": 7290,
                    "name": "Должностной инструкцией частного охранника.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80911,
                    "questionId": 7290,
                    "name": "Указаниями представителей любых государственных органов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80912,
                    "questionId": 7290,
                    "name": "Устными указаниями представителей клиента или заказчика.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7242,
            "name": "В каких случаях небрежное хранение огнестрельного оружия, создавшее условия для его использования другим лицом, не влечет уголовную ответственность:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80790,
                    "questionId": 7242,
                    "name": "Если это не повлекло тяжких последствий. ",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80791,
                    "questionId": 7242,
                    "name": "Если это повлекло смерть двух или более лиц.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80792,
                    "questionId": 7242,
                    "name": "Если это повлекло смерть человека или иные тяжкие последствия.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7284,
            "name": "Какие требования предъявляет Закон «О частной детективной и охранной деятельности в Российской Федерации» к транспортным средствам, используемым в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80892,
                    "questionId": 7284,
                    "name": "На них в обязательном порядке наносится специальная раскраска.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80893,
                    "questionId": 7284,
                    "name": "На них запрещено наносить информационные надписи и знаки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80894,
                    "questionId": 7284,
                    "name": "Специальная раскраска, информационные надписи и знаки на транспортных средствах частных охранных организаций подлежат согласованию с органами внутренних дел в порядке, определяемом Правительством Российской Федерации.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7287,
            "name": "В соответствии с Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» пропускной и внутриобъектовый режимы на объектах охраны устанавливаются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80901,
                    "questionId": 7287,
                    "name": "Клиентом или заказчиком.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80902,
                    "questionId": 7287,
                    "name": "Совместным решением заказчика (клиента) и частной охранной организации. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80903,
                    "questionId": 7287,
                    "name": "Частной охранной организацией.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7225,
            "name": "Нарушение охранниками правил ношения оружия и патронов к нему влечет:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80760,
                    "questionId": 7225,
                    "name": "Административную ответственность.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80761,
                    "questionId": 7225,
                    "name": "Уголовную и административную ответственность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80762,
                    "questionId": 7225,
                    "name": "Уголовную ответственность.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7295,
            "name": "Осмотр частными охранниками на объектах охраны, на которых установлен пропускной режим, въезжающих на объекты охраны (выезжающих с объектов охраны) транспортных средств и вносимого (выносимого) имущества должен производиться в присутствии:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80925,
                    "questionId": 7295,
                    "name": "Водителей указанных транспортных средств и лиц, сопровождающих указанные транспортные средства и имущество.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80926,
                    "questionId": 7295,
                    "name": "Представителя клиента или заказчика, водителей указанных транспортных средств.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80927,
                    "questionId": 7295,
                    "name": "Старшего объекта охраны, сопровождающих указанные транспортные средства и имущество лиц, а также понятых.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7264,
            "name": "Частный охранник привлекается к уголовной ответственности за совершение действий:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80832,
                    "questionId": 7264,
                    "name": "Выразившихся в оказании частных охранных услуг, либо не предусмотренных законом, либо с нарушением установленных законом требований.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80833,
                    "questionId": 7264,
                    "name": "Выходящих за пределы полномочий, установленных законодательством Российской Федерации, регламентирующим осуществление частной охранной и детективной деятельности, и повлекших любое нарушение прав и законных интересов граждан и (или) организаций либо охраняемых законом интересов общества или государства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80834,
                    "questionId": 7264,
                    "name": "Выходящих за пределы полномочий, установленных законодательством Российской Федерации, регламентирующим осуществление частной охранной и детективной деятельности, и повлекших существенное нарушение прав и законных интересов граждан и (или) организаций либо охраняемых законом интересов общества или государства.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7330,
            "name": "Имеют ли право на приобретение правового статуса частного охранника граждане, не прошедшие обязательной государственной дактилоскопической регистрации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81024,
                    "questionId": 7330,
                    "name": "Да, имеют.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81025,
                    "questionId": 7330,
                    "name": "Имеют, если не используют в процессе осуществления трудовой функции служебное оружие.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81026,
                    "questionId": 7330,
                    "name": "Нет, не имеют.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7228,
            "name": "Кого и в какой срок в соответствии с законом обязан уведомить частный охранник в случаях, когда при применении специальных средств и огнестрельного оружия граждане получили телесные повреждения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80763,
                    "questionId": 7228,
                    "name": "Незамедлительно уведомить заказчика частной охранной услуги.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80764,
                    "questionId": 7228,
                    "name": "Немедленно уведомить органы здравоохранения, орган внутренних дел и руководителя охранной организации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80765,
                    "questionId": 7228,
                    "name": "Немедленно уведомить прокурора и в возможно короткий срок органы здравоохранения и внутренних дел, территориальный орган федерального органа исполнительной власти, уполномоченного в сфере частной охранной деятельности ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7220,
            "name": "Превышение мер, необходимых для задержания лица, совершившего преступление (их явное несоответствие характеру и степени общественной опасности совершенного задерживаемым лицом преступления и обстоятельствам задержания), влечет за собой уголовную ответственность:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80751,
                    "questionId": 7220,
                    "name": "Во всех случаях причинения вреда здоровью задерживаемого (независимо от наличия или отсутствия умысла).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80752,
                    "questionId": 7220,
                    "name": "Только в случаях умышленного причинения смерти, тяжкого или средней тяжести вреда здоровью задерживаемого.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80753,
                    "questionId": 7220,
                    "name": "Только в случаях умышленного причинения смерти, тяжкого, средней тяжести или легкого вреда здоровью задерживаемого.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7280,
            "name": "В какой срок охранник (руководитель или уполномоченный представитель охранной организации) представляет заявление о продлении срока действия удостоверения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80880,
                    "questionId": 7280,
                    "name": "Не менее чем за 15 дней до окончания срока его действия.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80881,
                    "questionId": 7280,
                    "name": "Не менее чем за 30 дней до окончания срока его действия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80882,
                    "questionId": 7280,
                    "name": "Не менее чем за 45 дней до окончания срока его действия.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7247,
            "name": "При прибытии частного охранника на периодическую проверку без документа, удостоверяющий личность гражданина Российской Федерации на территории Российской Федерации, удостоверения частного охранника и оригинала или заверенной печатью (штампом) ЧОО либо подписью уполномоченного лица ЧОО копии уведомления о проверке комиссией принимается решение о переносе даты периодической проверки:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80802,
                    "questionId": 7247,
                    "name": "На срок не более 14 календарных дней с учетом графика работы комиссии без изменения места проведения периодической проверки; в случае повторного в течение 1 года непредставления названных документов частный охранник признается не явившимся на периодическую проверку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80803,
                    "questionId": 7247,
                    "name": "На срок не более 14 календарных дней с учетом графика работы комиссии с возможностью изменения места проведения периодической проверки; в случае повторного в течение 1 года непредставления названных документов частный охранник признается не явившимся на периодическую проверку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80804,
                    "questionId": 7247,
                    "name": "На срок не более 30 календарных дней с учетом графика работы комиссии без изменения места проведения периодической проверки; количество случаев переноса даты периодической проверки не ограничено.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7303,
            "name": "Хищение чужого имущества путем кражи, мошенничества, присвоения или растраты влечет уголовную ответственность (независимо от наличия или отсутствия иных признаков преступлений) при сумме ущерба:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80949,
                    "questionId": 7303,
                    "name": "До одной тысячи рублей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80950,
                    "questionId": 7303,
                    "name": "От одной тысячи, но не более двух тысяч пятисот рублей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80951,
                    "questionId": 7303,
                    "name": "Превышающей две тысячи пятьсот рублей.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7259,
            "name": "Какое требование предъявляет Закон РФ «О частной детективной и охранной деятельности в РФ» к частному охраннику при продлении удостоверения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80817,
                    "questionId": 7259,
                    "name": "Пройти повторную дактилоскопическую регистрацию в органах внутренних дел.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80818,
                    "questionId": 7259,
                    "name": "Пройти профессиональное обучение по программе повышения квалификации в организациях, осуществляющих образовательную деятельность по основным программам профессионального обучения и дополнительным профессиональным программам.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80819,
                    "questionId": 7259,
                    "name": "Пройти профессиональное обучение по программе профессиональной подготовки в организациях, осуществляющих образовательную деятельность по основным программам профессионального обучения и дополнительным профессиональным программам. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7270,
            "name": "При охране объектов с осуществлением работ по проектированию, монтажу и эксплуатационному обслуживанию технических средств охраны предусмотрено использование следующих технических средств:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80850,
                    "questionId": 7270,
                    "name": "Любые виды технических средств охраны по усмотрению руководителя частной охранной организации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80851,
                    "questionId": 7270,
                    "name": "Технические средства охраны, перечень видов которых устанавливается Правительством Российской Федерации. ",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80852,
                    "questionId": 7270,
                    "name": "Технические средства охраны, произведенные в Российской Федерации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7219,
            "name": "Подлежит ли возмещению вред, причиненный посягающему лицу в состоянии необходимой обороны, если при этом не было допущено превышения пределов необходимой обороны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80748,
                    "questionId": 7219,
                    "name": "Да, подлежит.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80749,
                    "questionId": 7219,
                    "name": "Не подлежит.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80750,
                    "questionId": 7219,
                    "name": "Подлежит частично на основании судебного решения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7288,
            "name": "В соответствии с Законом Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации» пропускной и внутриобъектовый режимы должны быть доведены:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80904,
                    "questionId": 7288,
                    "name": "До сведения органа внутренних дел по месту нахождения объекта охраны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80905,
                    "questionId": 7288,
                    "name": "До сведения органов внутренних дел и государственной противопожарной службы по месту нахождения объекта охраны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80906,
                    "questionId": 7288,
                    "name": "До сведения персонала и посетителей объекта охраны.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7262,
            "name": "В каком нормативном акте содержатся правила ношения специальной форменной одежды частными охранниками при оказании различных видов охранных услуг?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 80826,
                    "questionId": 7262,
                    "name": "В Законе Российской Федерации «О частной детективной и охранной деятельности в Российской Федерации».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 80827,
                    "questionId": 7262,
                    "name": "В соответствующем постановлении Правительства Российской Федерации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 80828,
                    "questionId": 7262,
                    "name": "В Федеральном законе «Об оружии».",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7361,
            "name": "Какие из перечисленных ниже действий охранника наиболее целесообразны в целях предотвращения угрозы персоналу и посетителям при обнаружении на объекте предмета с признаками взрывного устройства?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81108,
                    "questionId": 7361,
                    "name": "Вызвать специально подготовленных охранников из числа назначенных приказом руководителя частной охранной организации для перемещения подозрительного предмета в безопасное для персонала и посетителей место.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81109,
                    "questionId": 7361,
                    "name": "Не допускать прикосновения к подозрительному предмету каких-либо лиц, кроме уполномоченных сотрудников правоохранительных органов и МЧС России.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81110,
                    "questionId": 7361,
                    "name": "Незамедлительно лично переместить подозрительный предмет в безопасное для персонала и посетителей место. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7366,
            "name": "Третье действие (третий этап) при просмотре (проверке) документов на стационарных постах охраны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81123,
                    "questionId": 7366,
                    "name": "Провести проверку документа на действительность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81124,
                    "questionId": 7366,
                    "name": "Провести проверку документа на подлинность.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81125,
                    "questionId": 7366,
                    "name": "Сличить внешность человека, изображенного на фото­карточке с внешностью предъявителя.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7351,
            "name": "Наиболее правильным вариантом действий охранника в случае срабатывании рамки металлодетектора при проходе посетителя на охраняемый объект (если правилами прохода предусмотрено предъявление всех металлических предметов) является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81078,
                    "questionId": 7351,
                    "name": "Немедленное задержание посетителя для передачи его в органы внутренних дел.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81079,
                    "questionId": 7351,
                    "name": "Предложение посетителю предъявить все предметы, содержащие металл, а при отказе - недопущение его на объект.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81080,
                    "questionId": 7351,
                    "name": "Предложение посетителю предъявить все предметы, содержащие металл, а при отказе – проведение принудительного осмотра посетителя.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7348,
            "name": "При просмотре документов, предъявляемых посетителями в соответствии с правилами, установленными администрацией охраняемых объектов, дополнительное внимание следует уделять:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81069,
                    "questionId": 7348,
                    "name": "Наличию у посетителей иных документов (помимо требуемых правилами прохода). ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81070,
                    "questionId": 7348,
                    "name": "Психологическому состоянию проверяемых.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81071,
                    "questionId": 7348,
                    "name": "Уточнению личных данных посетителей, не связанных с реквизитами просматриваемого документа.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7368,
            "name": "Что из ниже перечисленного проверяется при проверке подлинности документа:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81129,
                    "questionId": 7368,
                    "name": "Входит ли предъявленный документ в перечень документов, установленных правилами пропускного режима для предъявления на данном посту охраны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81130,
                    "questionId": 7368,
                    "name": "Разборчивость подписи лица, уполномоченного подписывать документ, отсутствие факсимильных подписей для продления действия документа.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81131,
                    "questionId": 7368,
                    "name": "Фоновая сетка; фактура бумаги; объем, метод и содержание заполнения; соответствие оттиска печати и подписи уполномоченного лица; отсутствие следов и признаков подделки (подчисток, подклеек, следов травления, замены элементов и др.); соответствие формы предъявленного документа образцу.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7349,
            "name": "Наиболее эффективным способом обеспечения безопасности при просмотре (проверке) документов у посетителей охраняемых объектов является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81072,
                    "questionId": 7349,
                    "name": "Проведение проверки с передачей документа для просмотра охраной через специальное защищенное окно (мини-шлюз).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81073,
                    "questionId": 7349,
                    "name": "Проведение проверки с приведенным в готовность оружием и специальными средствами.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81074,
                    "questionId": 7349,
                    "name": "Проведение проверки с расположением проверяющего относительно проверяемого (либо соответствующим построением группы охраны), обеспечивающим безопасность проверяющего (взаимную страховку охранников).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7362,
            "name": "В населенном пункте при нахождении охранников на маршруте по охране имущества (денежных средств, перевозимых на автомобиле и в сопровождении работников коммерческого банка), сотрудником органов внутренних дел было предъявлено требование об остановке автомобиля. Старшему группы охраны целесообразно действовать следующим образом:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81111,
                    "questionId": 7362,
                    "name": "Дать команду водителю заглушить двигатель, выйти из автомобиля, подойти к сотруднику органов внутренних дел, представиться, предъявить документы, доложить о нахождении на маршруте по охране денежных средств и спросить о причине остановки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81112,
                    "questionId": 7362,
                    "name": "На требование работников ОВД не останавливаться, сообщить руководителю (дежурному) охранной организации и следовать до ближайшего органа внутренних дел.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81113,
                    "questionId": 7362,
                    "name": "Остановиться, обеспечив блокирование дверей автомобиля, немедленно сообщить об остановке руководителю (дежурному) охранной организации, через переговорное устройство или приоткрытое окно доложить о нахождении на маршруте по охране денежных средств и спросить о причине остановки. Не выходя из автомобиля, действовать с учетом ситуации (связаться с дежурной частью ОВД, дождаться прибытия группы реагирования охранной организации, а при необходимости – сменного автомобиля и представителей банка).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7355,
            "name": "Охранники ГБР (группы быстрого реагирования) частного охранного предприятия прибыли на сработку сигнализации на охраняемый имущественный объект. Какой из вариантов оснащения и действий охранников наиболее правилен:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81090,
                    "questionId": 7355,
                    "name": "Охранники используют жилеты, шлемы защитные, служебное и гражданское оружие, входящие в перечни специальных средств и вооружения охранников, средства связи, служебных собак; часть группы охраняет автомобиль, а другая заходит в охраняемое помещение (на охраняемую территорию) для проверки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81091,
                    "questionId": 7355,
                    "name": "Охранники используют жилеты, шлемы защитные, служебное и гражданское оружие, входящие в перечни специальных средств и вооружения охранников; средства связи; охранники применяют взаимную страховку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81092,
                    "questionId": 7355,
                    "name": "Охранники используют служебное оружие, средства связи; группа в полном составе (включая водителя) заходит в охраняемое помещение (на охраняемую территорию) для проверки.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7331,
            "name": "Охранник, находящийся на посту в офисном помещении, услышал звуки выстрелов в соседней комнате. Какой из вариантов действий ему следует избрать?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81027,
                    "questionId": 7331,
                    "name": "Открыть дверь и войти в соседнюю комнату, чтобы оценить обстановку",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81028,
                    "questionId": 7331,
                    "name": "Принять меры к оповещению правоохранительных органов, приготовить к применению имеющееся оружие (специальные средства), и далее используя обстановку офиса для укрытия, выяснить причину стрельбы",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81029,
                    "questionId": 7331,
                    "name": "Укрыться и, не производя других действий, ждать развития ситуации",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7342,
            "name": "Наиболее эффективными мерами по обеспечению безопасной охраны имущества при его транспортировке (в отношении денежных средств, инкассируемых с объекта работниками коммерческих банков) являются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81051,
                    "questionId": 7342,
                    "name": "Наличие у охранников служебного огнестрельного оружия и средств бронезащиты. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81052,
                    "questionId": 7342,
                    "name": "Предварительный осмотр подходов к объекту, подъезд автомобиля для перевозки денежных средств на минимальное расстояние, взаимная страховка сотрудников охраны.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81053,
                    "questionId": 7342,
                    "name": "Проведение постоянных занятий с сотрудниками охраны по огневой и физической подготовке. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7336,
            "name": "Какая из приводимых ниже классификаций наиболее широко охватывает возможные виды охраняемых объектов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81033,
                    "questionId": 7336,
                    "name": "Охраняемые объекты делятся на наземные и подземные. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81034,
                    "questionId": 7336,
                    "name": "Охраняемые объекты делятся на складские и производственные.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81035,
                    "questionId": 7336,
                    "name": "Охраняемые объекты делятся на стационарные и подвижные.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7369,
            "name": "Какой дополнительный способ применяется для уточнения фактической принадлежности документа и его подлинности при просмотре (проверке) документов:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81132,
                    "questionId": 7369,
                    "name": "Контрольные вопросы по сведениям, внесенным в документ.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81133,
                    "questionId": 7369,
                    "name": "Требование представить дополнительные документы, не предусмотренные для предъявления правилами пропускного режима.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81134,
                    "questionId": 7369,
                    "name": "Уточнение личных данных предъявителя, не связанных с реквизитами просматриваемого документа.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7343,
            "name": "Оптимальными действиями охранника по прекращению агрессии толпы в отношении объекта охраны являются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81054,
                    "questionId": 7343,
                    "name": "Вступление сотрудников охраны в физическое противоборство с толпой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81055,
                    "questionId": 7343,
                    "name": "Переключение внимания толпы; выделение в толпе лидеров и переговоры с ними с целью снижения агрессии.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81056,
                    "questionId": 7343,
                    "name": "Применение специальных средств или оружия на поражение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7359,
            "name": "Эффективные тактические действия охранников по обеспечению безопасности охраняемого объекта предполагают: ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81102,
                    "questionId": 7359,
                    "name": "Ликвидация угроз безопасности объекта по мере их возникновения (в рамках полномочий и тактических возможностей охранников).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81103,
                    "questionId": 7359,
                    "name": "Обнаружение, а затем - пресечение угроз безопасности объекта (в рамках полномочий и тактических возможностей охранников).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81104,
                    "questionId": 7359,
                    "name": "Предупреждение, обнаружение, а затем - пресечение угроз безопасности объекта (в рамках полномочий и тактических возможностей охранников).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7338,
            "name": "Какой способ оптимален для информирования посетителей о правилах пропускного режима, установленных на охраняемом объекте:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81039,
                    "questionId": 7338,
                    "name": "Ознакомление посетителей с текстом инструкции по охране объекта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81040,
                    "questionId": 7338,
                    "name": "Размещение информации об установленных заказчиком правилах перед входом на охраняемую территорию.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81041,
                    "questionId": 7338,
                    "name": "Устное разъяснение со стороны охранника.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7357,
            "name": "Решение охранника о допуске на охраняемый объект посетителей в ночное время (даже если такой случай не предусмотрен инструкцией) будет целесообразным, если:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81096,
                    "questionId": 7357,
                    "name": "Лица, представившиеся работниками органов внутренних дел, попросились переночевать (при условии уведомления дежурного местного ОВД).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81097,
                    "questionId": 7357,
                    "name": "Посетителю нужно срочно позвонить по телефону.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81098,
                    "questionId": 7357,
                    "name": "Работникам Федеральной службы охраны необходимо организовать пост наблюдения на пути следования объекта государственной охраны (при условии разрешения администрации охраняемого объекта и ответственного лица ЧОП).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7352,
            "name": "Какое из перечисленных условий задержания, осуществляемого охранниками, является тактическим:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81081,
                    "questionId": 7352,
                    "name": "Необходимость незамедлительной передачи задерживаемых в органы внутренних дел.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81082,
                    "questionId": 7352,
                    "name": "Необходимость удержания инициативы в ходе задержания.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81083,
                    "questionId": 7352,
                    "name": "Необходимость учета правового иммунитета к задержанию определенных категорий лиц.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7337,
            "name": "На охраняемом объекте транспортной инфраструктуры неизвестные лица, ссылаясь на производственную либо служебную необходимость, пытаются получить доступ к местам, пригодным для скрытой закладки взрывного устройства (технические полости помещений, электрощиты, досмотровые рентгеновские установки, рамки металлодетекторов, платежные терминалы и т.п.). Какие действия охранника позволяют наиболее надежно предотвратить возможный террористический акт?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81036,
                    "questionId": 7337,
                    "name": "Регистрация персональных данных всех прибывших лиц, отслеживание силами охраны и сохранение видеозаписей их действий, немедленное информирование представителей правоохранительных органов на объекте и дежурных служб при подозрении на возможную закладку (исключая случаи, когда прибывшие представились работниками электросетей, служб связи, других служб обеспечения, сотрудниками контролирующих или правоохранительных органов).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81037,
                    "questionId": 7337,
                    "name": "Регистрация персональных данных всех прибывших лиц, отслеживание силами охраны и сохранение видеозаписей их действий, немедленное информирование представителей правоохранительных органов на объекте и дежурных служб при подозрении на возможную закладку (исключая случаи, когда прибывшие представились сотрудниками контролирующих или правоохранительных органов).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81038,
                    "questionId": 7337,
                    "name": "Регистрация персональных данных всех прибывших лиц, отслеживание силами охраны и сохранение видеозаписей их действий, немедленное информирование представителей правоохранительных органов на объекте и дежурных служб при подозрении на возможную закладку (независимо от статуса прибывших лиц). ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7345,
            "name": "При пресечении попытки проникновения группы правонарушителей на охраняемый объект наиболее эффективным и рациональным средством противодействия является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81060,
                    "questionId": 7345,
                    "name": "Использование инженерно-технических средств.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81061,
                    "questionId": 7345,
                    "name": "Применение оружия и специальных средств.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81062,
                    "questionId": 7345,
                    "name": "Применение охранниками физической силы.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7365,
            "name": "Второе действие (второй этап) при просмотре (проверке) документов на стационарных постах охраны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81120,
                    "questionId": 7365,
                    "name": "Провести проверку документа на действительность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81121,
                    "questionId": 7365,
                    "name": "Провести проверку документа на подлинность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81122,
                    "questionId": 7365,
                    "name": "Сличить внешность человека, изображенного на фото­карточке с внешностью предъявителя.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7344,
            "name": "Процедура заступления охранника на пост по охране стационарного объекта начинается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81057,
                    "questionId": 7344,
                    "name": "С внесения записи в журнал приема-сдачи дежурств.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81058,
                    "questionId": 7344,
                    "name": "С доклада администрации охраняемого объекта о заступлении на дежурство.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81059,
                    "questionId": 7344,
                    "name": "С осмотра объекта и прилегающей территории.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7341,
            "name": "На охраняемый объект пытаются пройти лица, представившиеся работниками вневедомственной охраны Росгвардии, прибывшими на сработку сигнализации. Охранник должен:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81048,
                    "questionId": 7341,
                    "name": "Не допускать прибывших на территорию объекта без распоряжения администрации объекта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81049,
                    "questionId": 7341,
                    "name": "Незамедлительно пропустить прибывших на объект.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81050,
                    "questionId": 7341,
                    "name": "Пропустить прибывших на объект после проверочного звонка дежурному по подразделению вневедомственной охраны.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7354,
            "name": "На ПЦН (пульт централизованного наблюдения) частного охранного предприятия, имеющего договорные обязательства о выезде своих сотрудников на охраняемые объекты, поступил сигнал с объекта о срабатывании сигнализации. Какой из вариантов действий дежурного ПЦН в этом случае является правильным:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81087,
                    "questionId": 7354,
                    "name": "Записать в журнал дежурного по ПЦН данные о сработке сигнализации, самому выехать на охраняемый объект.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81088,
                    "questionId": 7354,
                    "name": "Направить на объект ГБР (группу быстрого реагирования) - дежурную группу охранников, записать в журнал дежурного по ПЦН данные о сработке сигнализации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81089,
                    "questionId": 7354,
                    "name": "Оповестить органы внутренних дел, записать в журнал дежурного по ПЦН данные о сработке сигнализации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7367,
            "name": "Четвертое действие (четвертый этап) при просмотре (проверке) документов на стационарных постах охраны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81126,
                    "questionId": 7367,
                    "name": "Провести проверку документа на действительность.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81127,
                    "questionId": 7367,
                    "name": "Провести проверку документа на подлинность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81128,
                    "questionId": 7367,
                    "name": "Сличить внешность человека, изображенного на фото­карточке с внешностью предъявителя.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7347,
            "name": "На что обращается приоритетное внимание при обеспечении охраны в местах проведения массовых мероприятий?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81066,
                    "questionId": 7347,
                    "name": "Возможность возникновения массовых беспорядков. Признаки террористической угрозы.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81067,
                    "questionId": 7347,
                    "name": "Возможность посягательства на имущество участвующих в мероприятии.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81068,
                    "questionId": 7347,
                    "name": "Нарушение правил, установленных организаторами мероприятия (безбилетный проход, нахождение в нетрезвом состоянии и т.п.).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7339,
            "name": "Первоначальные действия охранника при обнаружении предмета с признаками взрывного устройства:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81042,
                    "questionId": 7339,
                    "name": "Действовать по указанию администрации охраняемого объекта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81043,
                    "questionId": 7339,
                    "name": "Зафиксировать время обнаружения, принять меры к ограждению и охране подходов к опасной зоне, проинформировать правоохранительные органы.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81044,
                    "questionId": 7339,
                    "name": "Осмотреть подозрительный предмет и перенести его в безопасное место, проинформировать правоохранительные органы.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7333,
            "name": "Какие действия охранника, вынужденного передвигаться под огнем противника, не помогают избежать поражения противником:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81030,
                    "questionId": 7333,
                    "name": "Передвигаться кратчайшим путем, не меняя направление движения.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81031,
                    "questionId": 7333,
                    "name": "Передвигаться, каждые 3-5 секунд производя выстрелы в направлении противника (если охранник вооружен и противник виден охраннику). ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81032,
                    "questionId": 7333,
                    "name": "Передвигаться, каждые 3-5 секунд укрываясь за имеющимися укрытиями; при отсутствии укрытий - каждые 3-5 секунд резко менять направление движения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7360,
            "name": "Эффективные тактические действия охранников по осмотру автомобиля на предмет возможной установки взрывных устройств начинаются: ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81105,
                    "questionId": 7360,
                    "name": "С внешнего осмотра автомобиля, а затем – осмотра его салона и внутренних полостей (включая багажник, подкапотное пространство и т.д.).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81106,
                    "questionId": 7360,
                    "name": "С осмотра окружающей территории, а затем – с проверки наличия связей между автомобилем и окружающими предметами (включая покрытие дороги).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81107,
                    "questionId": 7360,
                    "name": "С осмотра салона автомобиля, а затем – внутренних полостей автомобиля (включая багажник, подкапотное пространство и т.д.). ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7370,
            "name": "Какое отличие имеется в последовательности действий при обнаружении предметов, предположительно содержащих отравляющие вещества (ОВ), по сравнению с действиями при обнаружении взрывчатых веществ (ВВ) и взрывных устройств (ВУ):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81135,
                    "questionId": 7370,
                    "name": "Одно из первых действий – обильно залить обнаруженный предмет пеной из воздушно-пенного или углекислотного огнетушителя. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81136,
                    "questionId": 7370,
                    "name": "Одно из первых действий - открыть окна помещения, в котором обнаружен подозрительный предмет.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81137,
                    "questionId": 7370,
                    "name": "Одно из первых действий - приготовить и надеть средства индивидуальной защиты (противогазы или защитные капюшоны, резиновые перчатки и т.п.).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7358,
            "name": "В нерабочее время на охраняемый объект обратились лица, с просьбой оказать помощь пострадавшему от ДТП, случившегося напротив входа на объект. Какой из вариантов действий охранника на объекте наиболее правилен:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81099,
                    "questionId": 7358,
                    "name": "Вызвать сотрудников органов внутренних дел и скорую помощь, в обязательном порядке выйти для оказания первой помощи пострадавшему.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81100,
                    "questionId": 7358,
                    "name": "Не открывая дверей объекта, вызвать сотрудников органов внутренних дел и скорую помощь.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81101,
                    "questionId": 7358,
                    "name": "Рекомендовать обратившимся самим вызвать соответствующие службы. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7356,
            "name": "Решение о незапланированной остановке автомобиля, на котором следует группа охраны имущества, перевозимого автомобильным транспортом, является обоснованным в случае, если остановка произведена:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81093,
                    "questionId": 7356,
                    "name": "Для ожидания прибытия сотрудников ГИБДД на место ДТП с участием водителя автомобиля, на котором следует группа охраны - при условии принятия неотложных мер по усилению охраны, а при необходимости, и по вызову запасного автомобиля.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81094,
                    "questionId": 7356,
                    "name": "Для оказания помощи водителю другой автомашины в неотложном ремонте автомобиля – при условии организации охраны места остановки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81095,
                    "questionId": 7356,
                    "name": "Для подбора пассажира, следующего в попутном направлении – при условии, что пассажир один, а в автомобиле, на котором следует группа охраны, имеется свободное место.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7353,
            "name": "Дополнительным тактическим действием при задержании, осуществляемом охранниками, может быть:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81084,
                    "questionId": 7353,
                    "name": "Использование служебных собак.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81085,
                    "questionId": 7353,
                    "name": "Наличие у охраны оружия и специальных средств.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81086,
                    "questionId": 7353,
                    "name": "Подача сигналов свистком, принятых в органах внутренних дел.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7364,
            "name": "Первое действие (первый этап) при просмотре (проверке) документов на стационарных постах охраны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81117,
                    "questionId": 7364,
                    "name": "Проверить, входит ли предъявленный документ в перечень документов, установленных правилами пропускного режима для предъявления на данном посту охраны.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81118,
                    "questionId": 7364,
                    "name": "Провести проверку документа на подлинность. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81119,
                    "questionId": 7364,
                    "name": "Сличить внешность человека, изображенного на фото­карточке с внешностью предъявителя.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7340,
            "name": "Первоначальные действия охранника в случае срабатывания взрывного устройства на охраняемом объекте:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81045,
                    "questionId": 7340,
                    "name": "Зафиксировать время взрыва, обеспечить организованную и быструю эвакуацию сотрудников охраняемого объекта на безопасное удаление, проинформировать правоохранительные органы, организовать оказание помощи пострадавшим.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81046,
                    "questionId": 7340,
                    "name": "Незамедлительно сообщить руководству охранного предприятия о случившемся, принять меры по повышению эффективности охраны, проинформировать правоохранительные органы.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81047,
                    "questionId": 7340,
                    "name": "Отключить на объекте электричество и газоснабжение, проинформировать правоохранительные органы, организовать охрану места происшествия и оказание помощи пострадавшим.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7350,
            "name": "Выделение среди посетителей объектов лиц с нестандартным поведением и их дальнейший контроль является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81075,
                    "questionId": 7350,
                    "name": "Действием, выходящим за рамки функциональных обязанностей охранников.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81076,
                    "questionId": 7350,
                    "name": "Одним из эффективных способов обеспечения антитеррористической защиты и охраны объектов.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81077,
                    "questionId": 7350,
                    "name": "Тактическим действием, осуществляемым исключительно по специальному поручению Заказчика.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7346,
            "name": "Какие признаки, применяемые при составлении словесного портрета, позволяют наиболее быстро и достоверно выделить описываемое лицо в толпе?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81063,
                    "questionId": 7346,
                    "name": "Анатомические признаки (описание головы, лица, волос, иных частей тела).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81064,
                    "questionId": 7346,
                    "name": "Сопутствующие элементы и признаки (одежда, украшения, используемые предметы).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81065,
                    "questionId": 7346,
                    "name": "Функциональные признаки (осанка, походка, жестикуляция, мимика, голос и т.п.).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7363,
            "name": "При нахождении на посту по охране стационарного объекта охранник заметил драку, происходящую в непосредственной близости к объекту. Какой из вариантов действий охранника на объекте наиболее правилен:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81114,
                    "questionId": 7363,
                    "name": "Выйти и разнять дерущихся граждан, так как могут быть повреждены ограждающие конструкции охраняемого объекта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81115,
                    "questionId": 7363,
                    "name": "Не открывая дверей объекта, сообщить в органы внутренних дел.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81116,
                    "questionId": 7363,
                    "name": "Не предпринимать никаких действий, так как правонарушение происходит вне пределов охраняемого объекта.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7378,
            "name": "Какие действия, предусмотренные в составе первой помощи, завершают ее оказание?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81159,
                    "questionId": 7378,
                    "name": "Передача пострадавшего бригаде скорой медицинской помощи.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81160,
                    "questionId": 7378,
                    "name": "Проведение мероприятий первичной медико-санитарной помощи.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81161,
                    "questionId": 7378,
                    "name": "Проведение мероприятий специализированной медицинской помощи.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7396,
            "name": "При попадании слезоточивых и раздражающих веществ в глаза необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81213,
                    "questionId": 7396,
                    "name": "Промыть глаза обильной струей теплой воды, затем 2% раствором бикарбоната натрия (соды).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81214,
                    "questionId": 7396,
                    "name": "Протереть глаза масляным тампоном.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81215,
                    "questionId": 7396,
                    "name": "Протереть глаза сухой ветошью.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7371,
            "name": "Каково содержание информации, сообщаемой при вызове скорой медицинской помощи?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81138,
                    "questionId": 7371,
                    "name": "Сообщить, кто вызывает, телефон вызывающего, что случилось, кто пострадал/заболел (пол, возраст), адрес с указанием подъездного пути, дома, подъезда, этажа, кода на входной двери.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81139,
                    "questionId": 7371,
                    "name": "Сообщить, что случилось, кто вызывает, телефон вызывающего, кто пострадал/заболел (пол, возраст), адрес с указанием подъездного пути, дома, подъезда, этажа, кода на входной двери.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81140,
                    "questionId": 7371,
                    "name": "Сообщить, что случилось, кто пострадал/заболел (пол, возраст), адрес с указанием подъездных путей, дома, подъезда, этажа, кода на входной двери, кто вызывает, телефон вызывающего.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7395,
            "name": "При попадании слезоточивых и раздражающих веществ на кожу следует:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81210,
                    "questionId": 7395,
                    "name": "Промокнуть сухой ветошью.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81211,
                    "questionId": 7395,
                    "name": "Промыть кожу холодной водой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81212,
                    "questionId": 7395,
                    "name": "Протереть последовательно тремя тампонами - с 40% раствором этилового спирта, с 3% раствором бикарбоната натрия (соды), с мыльным раствором.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7397,
            "name": "Что необходимо сделать при ожоговой ране?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81216,
                    "questionId": 7397,
                    "name": "Наложить чистую увлажненную повязку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81217,
                    "questionId": 7397,
                    "name": "Очистить рану и промыть ее холодной водой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81218,
                    "questionId": 7397,
                    "name": "Смазать рану маслом, наложить повязку.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7405,
            "name": "Положение пострадавшего при проведении сердечно-легочной реанимации:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81240,
                    "questionId": 7405,
                    "name": "В том положении, в котором был обнаружен пострадавший (колени реанимирующего выше уровня спины пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81241,
                    "questionId": 7405,
                    "name": "На спине на кровати (колени реанимирующего ниже уровня спины пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81242,
                    "questionId": 7405,
                    "name": "На спине, на ровной жесткой поверхности (колени реанимирующего на уровне спины пострадавшего). ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7374,
            "name": "Какие из мероприятий по оценке обстановки и обеспечению безопасных условий для оказания первой помощи совершаются в порядке осмотра места происшествия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81147,
                    "questionId": 7374,
                    "name": "Определение угрожающих факторов для собственной жизни и здоровья; определение угрожающих факторов для жизни и здоровья пострадавшего; оценка количества пострадавших.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81148,
                    "questionId": 7374,
                    "name": "Придание правильного транспортного положения и организация транспортировки пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81149,
                    "questionId": 7374,
                    "name": "Устранение угрожающих факторов для жизни и здоровья; прекращение действия повреждающих факторов на пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7388,
            "name": "Действия по помощи пострадавшему при попадании инородного тела в дыхательные пути:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81189,
                    "questionId": 7388,
                    "name": "Нагнуть туловище пострадавшего вперед, нанести несколько интенсивных ударов ладонью между лопаток, при отсутствии эффекта -  провести прием «Хемлика»",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81190,
                    "questionId": 7388,
                    "name": "Нанести пострадавшему, стоящему прямо, несколько интенсивных ударов ладонью между лопаток",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81191,
                    "questionId": 7388,
                    "name": "Положить пострадавшего на бок и вызвать интенсивную рвоту",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7411,
            "name": "Промывание желудка при отравлении в порядке первой помощи (немедицинским персоналом и без желудочного зонда) запрещено:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81258,
                    "questionId": 7411,
                    "name": "При отравлениях кислотами, щелочами, нефтепродуктами, при судорогах, в случае потери сознания пострадавшим.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81259,
                    "questionId": 7411,
                    "name": "При отравлениях у лиц, не имеющих при себе документов, удостоверяющих личность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81260,
                    "questionId": 7411,
                    "name": "При отравлениях у несовершеннолетних детей.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7377,
            "name": "Третьим действием (третьим этапом) при оказании первой помощи является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81156,
                    "questionId": 7377,
                    "name": "Правильная транспортировка пострадавшего (производимая с учетом обстановки на месте происшествия, состояния и характера повреждений пострадавшего).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81157,
                    "questionId": 7377,
                    "name": "Предотвращение возможных осложнений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81158,
                    "questionId": 7377,
                    "name": "Прекращение воздействия травмирующего фактора.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7406,
            "name": "При проведении ИВЛ (искусственной вентиляции легких) методом «рот ко рту» необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81243,
                    "questionId": 7406,
                    "name": "Зажимать нос пострадавшего только в случае, если носовые ходы свободны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81244,
                    "questionId": 7406,
                    "name": "Нос пострадавшему не зажимать.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81245,
                    "questionId": 7406,
                    "name": "Свободной рукой плотно зажимать нос пострадавшего.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7380,
            "name": "Если пострадавший находится в сознании и задыхается, или у него имеется ранение грудной клетки, в какое положение до прибытия скорой помощи он должен быть переведен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81165,
                    "questionId": 7380,
                    "name": "В положении на спине.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81166,
                    "questionId": 7380,
                    "name": "В положении полусидя.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81167,
                    "questionId": 7380,
                    "name": "В устойчивое боковое положение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7375,
            "name": "Первым действием (первым этапом) при оказании первой помощи является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81150,
                    "questionId": 7375,
                    "name": "Правильная транспортировка пострадавшего (производимая с учетом обстановки на месте происшествия, состояния и характера повреждений пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81151,
                    "questionId": 7375,
                    "name": "Предотвращение возможных осложнений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81152,
                    "questionId": 7375,
                    "name": "Прекращение воздействия травмирующего фактора.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7404,
            "name": "В каком объеме проводятся мероприятия при прекращении сердечной деятельности и дыхания у пострадавшего? ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81237,
                    "questionId": 7404,
                    "name": "Освобождение дыхательных путей, проведение ИВЛ (искусственной вентиляции легких) и НМС (непрямого массажа сердца).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81238,
                    "questionId": 7404,
                    "name": "Освобождение дыхательных путей, проведение ИВЛ (искусственной вентиляции легких).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81239,
                    "questionId": 7404,
                    "name": "Проведение НМС (непрямого массажа сердца).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7402,
            "name": "Как проверяется пульс при бессознательном состоянии пострадавшего и при травмах?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81231,
                    "questionId": 7402,
                    "name": "Приложив ухо к груди прослушивается сердцебиение.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81232,
                    "questionId": 7402,
                    "name": "Пульс проверяется на запястье.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81233,
                    "questionId": 7402,
                    "name": "Пульс проверяется на сонной артерии.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7389,
            "name": "Что надо сделать при возникновении не проходящих в покое острых болей за грудиной (в области сердца)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81192,
                    "questionId": 7389,
                    "name": "Немедленно вызвать «Скорую помощь», обеспечить пострадавшему полный покой в полусидячем положении, обеспечить приток воздуха.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81193,
                    "questionId": 7389,
                    "name": "Положить пострадавшего на спину, укутать одеялом, вызвать «Скорую помощь».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81194,
                    "questionId": 7389,
                    "name": "Посадить пострадавшего, обеспечить приток свежего воздуха, положить на грудь холод, вызвать «Скорую помощь».",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7400,
            "name": "Порядок оказания первой помощи при открытых переломах.",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81225,
                    "questionId": 7400,
                    "name": "Наложить шину и обезболить (по возможности).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81226,
                    "questionId": 7400,
                    "name": "Наложить шину, наложить повязку на рану.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81227,
                    "questionId": 7400,
                    "name": "Обезболить (по возможности), наложить повязку, наложить шину.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7399,
            "name": "При повреждении костей плеча или бедра шину накладывают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81222,
                    "questionId": 7399,
                    "name": "С захватом только верхнего (по отношению к месту перелома) сустава.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81223,
                    "questionId": 7399,
                    "name": "С захватом только двух суставов (выше и ниже места перелома).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81224,
                    "questionId": 7399,
                    "name": "С захватом трех суставов (двух ниже и одного выше места перелома).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7391,
            "name": "В каком порядке проводятся мероприятия первой помощи при ранении?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81198,
                    "questionId": 7391,
                    "name": "Обеззараживание раны (при возможности), наложение повязки, остановка кровотечения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81199,
                    "questionId": 7391,
                    "name": "Остановка кровотечения, наложение повязки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81200,
                    "questionId": 7391,
                    "name": "Остановка кровотечения, обеззараживание раны (при возможности), наложение повязки.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7387,
            "name": "При вынужденном длительном наложении кровоостанав­ли­вающий жгут необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81186,
                    "questionId": 7387,
                    "name": "Периодически ослаблять, и затем переносить ниже прежнего места наложения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81187,
                    "questionId": 7387,
                    "name": "Периодически ослаблять, применяя на это время пальцевое прижатие, затем накладывать на прежнее место.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81188,
                    "questionId": 7387,
                    "name": "Периодически ослаблять, применяя на это время пальцевое прижатие, затем переносить выше прежнего места наложения. ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7386,
            "name": "Время наложения кровоостанавливающего жгута:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81183,
                    "questionId": 7386,
                    "name": "Летом – не более чем на 1 час, зимой – не более чем на 30 минут.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81184,
                    "questionId": 7386,
                    "name": "Летом – не более чем на 30 минут, зимой – не более чем на 1 час.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81185,
                    "questionId": 7386,
                    "name": "Не более чем на 30 минут, независимо от окружающей температуры.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7398,
            "name": "При повреждении костей предплечья или голени шину накладывают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81219,
                    "questionId": 7398,
                    "name": "С захватом двух суставов (выше и ниже места перелома).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81220,
                    "questionId": 7398,
                    "name": "С захватом только верхнего (по отношению к месту перелома) сустава.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81221,
                    "questionId": 7398,
                    "name": "С захватом трех суставов.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7376,
            "name": "Вторым действием (вторым этапом) при оказании первой помощи является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81153,
                    "questionId": 7376,
                    "name": "Правильная транспортировка пострадавшего (производимая с учетом обстановки на месте происшествия, состояния и характера повреждений пострадавшего).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81154,
                    "questionId": 7376,
                    "name": "Предотвращение возможных осложнений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81155,
                    "questionId": 7376,
                    "name": "Устранение состояния, угрожающего жизни и здоровью пострадавшего.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7407,
            "name": "При проведении ИВЛ (искусственной вентиляции легких) методом «рот к носу» необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81246,
                    "questionId": 7407,
                    "name": "Не проводить никаких манипуляций с нижней челюстью пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81247,
                    "questionId": 7407,
                    "name": "Свободной рукой открывать рот пострадавшего для обеспечения выдоха.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81248,
                    "questionId": 7407,
                    "name": "Свободной рукой плотно удерживать нижнюю челюсть пострадавшего, чтобы его рот был закрыт.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7394,
            "name": "Что надо делать при нахождении ножа или другого ранящего предмета в ране?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81207,
                    "questionId": 7394,
                    "name": "Вытащить нож и быстро, без обработки раны антисептиком, наложить повязку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81208,
                    "questionId": 7394,
                    "name": "Оставить ранящий предмет в ране, зафиксировать предмет в ране, наложив вокруг него повязку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81209,
                    "questionId": 7394,
                    "name": "Применить пальцевое прижатие, наложить жгут выше места ранения, вытащить ранящий предмет, наложить повязку.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7379,
            "name": "Если пострадавший находится без сознания, в какое положение до прибытия скорой помощи он должен быть переведен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81162,
                    "questionId": 7379,
                    "name": "В положении на спине.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81163,
                    "questionId": 7379,
                    "name": "В положении полусидя.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81164,
                    "questionId": 7379,
                    "name": "В устойчивое боковое положение.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7381,
            "name": "Что надо делать в случае, если у пострадавшего развился приступ эпилепсии (судорожный приступ)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81168,
                    "questionId": 7381,
                    "name": "Придерживать пострадавшего за голову, не давая ее разбить, по окончанию приступа перевести в устойчивое боковое положение.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81169,
                    "questionId": 7381,
                    "name": "Расслабить (расстегнуть) стягивающую одежду, убрать предметы, которые могут травмировать пострадавшего, подложить под голову мягкий, плоский предмет (например, подушку или свернутую одежду), зафиксировать время начала и конца приступа, по окончанию приступа перевести в устойчивое боковое положение.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81170,
                    "questionId": 7381,
                    "name": "Удерживать пострадавшего за руки и ноги, для предотвращения укуса языка и его западения ввести в рот ложку, по окончанию приступа перевести в устойчивое боковое положение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7372,
            "name": "Каков порядок действий при встрече медицинских работников, прибывающих по вызову?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81141,
                    "questionId": 7372,
                    "name": "Направить кого-нибудь встречать «Скорую помощь», при сложном маршруте обозначить его дополнительными опознавательными знаками, самому ожидать у места происшествия и оказывать первую помощь.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81142,
                    "questionId": 7372,
                    "name": "Направить кого-нибудь встречать «Скорую помощь», самому ожидать у места происшествия и оказывать первую помощь.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81143,
                    "questionId": 7372,
                    "name": "Ожидать встречи «Скорой помощи» на месте происшествия, объясняя по телефону диспетчеру «03», как поехать к месту происшествия.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7385,
            "name": "Техника наложения кровоостанавливающего жгута предусматривает:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81180,
                    "questionId": 7385,
                    "name": "Наложение жгута на одежду выше места кровотечения (с указанием времени наложения в записке).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81181,
                    "questionId": 7385,
                    "name": "Наложение жгута на одежду ниже места кровотечения (с указанием времени наложения в записке).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81182,
                    "questionId": 7385,
                    "name": "Наложение жгута под одежду выше места кровотечения.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7410,
            "name": "Ритм сердечно-легочной реанимации, выполняемой при оказании первой помощи:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81255,
                    "questionId": 7410,
                    "name": "15 надавливаний на грудную клетку – 2 вдувания воздуха.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81256,
                    "questionId": 7410,
                    "name": "30 надавливаний на грудную клетку – 2 вдувания воздуха. ",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81257,
                    "questionId": 7410,
                    "name": "5 надавливаний на грудную клетку – 1 вдувание воздуха.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7393,
            "name": "Какие правила оказания первой помощи соблюдаются при проникающем ранении в брюшную полость?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81204,
                    "questionId": 7393,
                    "name": "Не давать пострадавшему пить жидкость, извлечь инородное тело, накрыть рану стерильной салфеткой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81205,
                    "questionId": 7393,
                    "name": "Не давать пострадавшему пить жидкость, не извлекать инородное тело, прикрыть рану стерильным перевязочным материалом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81206,
                    "questionId": 7393,
                    "name": "Приподнять голову, дать сладкое теплое питье, накрыть стерильной салфеткой и положить холод на рану.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7408,
            "name": "Особенности проведения ИВЛ (искусственной вентиляции легких) детям:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81249,
                    "questionId": 7408,
                    "name": "Увеличивается частота вдуваний воздуха с обязательным уменьшением объема вдуваемого воздуха.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81250,
                    "questionId": 7408,
                    "name": "Уменьшается частота вдуваний воздуха с обязательным уменьшением объема вдуваемого воздуха.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81251,
                    "questionId": 7408,
                    "name": "Частота вдуваний воздуха и объем вдуваемого воздуха, по сравнению со взрослыми пострадавшими, не меняется. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7403,
            "name": "Что надо сделать для определения наличия дыхания при бессознательном состоянии пострадавшего?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81234,
                    "questionId": 7403,
                    "name": "Поднести зеркальце или птичье перо к носу пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81235,
                    "questionId": 7403,
                    "name": "Поднести к носу пострадавшего внутреннюю сторону своего запястья или щеку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81236,
                    "questionId": 7403,
                    "name": "Приложить ухо к груди пострадавшего и прослушать дыхание.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7383,
            "name": "Способы временной остановки кровотечения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81174,
                    "questionId": 7383,
                    "name": "Пальцевое прижатие, максимальное сгибание конечности, наложение жгута (закрутки), наложение давящей повязки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81175,
                    "questionId": 7383,
                    "name": "Придание возвышенного положения конечности, наложение асептической повязки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81176,
                    "questionId": 7383,
                    "name": "Частичное сгибание конечности, наложение пластыря, наложение давящей повязки.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7401,
            "name": "При каких действиях достигается наибольшая эффективность оказания помощи при выведении пострадавшего из обморока?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81228,
                    "questionId": 7401,
                    "name": "При нажатии на точку в центре носогубного треугольника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81229,
                    "questionId": 7401,
                    "name": "При поднятии ног пострадавшего выше уровня тела, при скручивающем нажатии на середину грудины, при наличии нашатырного спирта -  при поднесении к носу и смазывании висков ваткой, смоченной нашатырным спиртом.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81230,
                    "questionId": 7401,
                    "name": "При укутывании пострадавшего в одеяло, приведения его в боковое устойчивое положение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7409,
            "name": "Частота вдуваний воздуха в минуту при проведении ИВЛ (искусственной вентиляции легких) составляет:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81252,
                    "questionId": 7409,
                    "name": "20-24 вдуваний в минуту для взрослых, 30-36 для детей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81253,
                    "questionId": 7409,
                    "name": "6-8 вдуваний в минуту для взрослых, 8-10 для детей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81254,
                    "questionId": 7409,
                    "name": "8-10 вдуваний в минуту для взрослых, 12-20 для детей.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7373,
            "name": "Входят ли в состав аптечки первой помощи медицинские препараты?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81144,
                    "questionId": 7373,
                    "name": "Входят медицинские препараты, отпускаемые в аптеках без рецепта (йод, нашатырный спирт, валидол, нитроглицерин и т.п.), а также препараты для проведения комплексной противошоковой терапии (кордиамин, дексаметазон, кеторолака трометамин или баралгин и т.п.).  ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81145,
                    "questionId": 7373,
                    "name": "Входят медицинские препараты, отпускаемые в аптеках без рецепта (йод, нашатырный спирт, валидол, нитроглицерин и т.п.).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81146,
                    "questionId": 7373,
                    "name": "Не входят.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7382,
            "name": "Правильная транспортировка пострадавшего, находящегося без сознания (за исключением случаев, когда в связи с подозрением на травму позвоночника менять положение тела не рекомендуется) производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81171,
                    "questionId": 7382,
                    "name": "В положении на боку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81172,
                    "questionId": 7382,
                    "name": "В положении на спине.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81173,
                    "questionId": 7382,
                    "name": "В положении с приподнятыми нижними конечностями.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7384,
            "name": "Какова правильная последовательность действий при остановке артериального кровотечения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81177,
                    "questionId": 7384,
                    "name": "Накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута. ",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81178,
                    "questionId": 7384,
                    "name": "Проводится пальцевая остановка кровотечения, накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81179,
                    "questionId": 7384,
                    "name": "Проводится пальцевая остановка кровотечения, накладывается чистая повяз­ка, накладывается жгут (скрутка, ремень), указывается время наложения жгута",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7390,
            "name": "Что в первую очередь может помочь при возникновении не проходящих в покое острых болей за грудиной (в области сердца)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81195,
                    "questionId": 7390,
                    "name": "Измерение давления и частоты пульса.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81196,
                    "questionId": 7390,
                    "name": "Обеспечение физической нагрузки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81197,
                    "questionId": 7390,
                    "name": "Прием нитроглицерина под язык (только если пострадавший знает о своей болезни и имеет его при себе). ",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7392,
            "name": "Какие действия проводятся при проникающем ранении грудной клетки (с выходом воздуха в плевральную полость)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81201,
                    "questionId": 7392,
                    "name": "Придание возвышенного положения, первоначальное закрытие раны ладонью, закрытие раны повязкой, обеспечивающей фиксацию грудной клетки пострадавшего.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81202,
                    "questionId": 7392,
                    "name": "Придание возвышенного положения, первоначальное закрытие раны ладонью, затем закрытие раны повязкой, не пропускающей воздух - с использованием индивидуального перевязочного пакета, иного полиэтиленового пакета и т.п.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81203,
                    "questionId": 7392,
                    "name": "Придание пострадавшему положения «на спине» первоначальное закрытие раны ладонью, затем закрытие раны повязкой, не пропускающей воздух - с использованием индивидуального перевязочного пакета, иного полиэтиленового пакета и т.п.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7450,
            "name": "Какой класс защитной структуры бронеодежды (жилетов защитных) является минимально достаточным для защиты от огня из охотничьего ружья 12-го калибра 18,5 мм охотничьим патроном со свинцовым сердечником?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81375,
                    "questionId": 7450,
                    "name": "Основной класс защиты Бр1 (класс защиты 1 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81376,
                    "questionId": 7450,
                    "name": "Основной класс защиты Бр2 (класс защиты 2 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81377,
                    "questionId": 7450,
                    "name": "Специальный класс защиты С1 (класс защиты 2а по старой классификации).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7442,
            "name": "Что необходимо сделать для выключения (разблокировки) фиксатора, имеющегося в конструкции браслетов наручников, используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81351,
                    "questionId": 7442,
                    "name": "Вставить ключ от наручников в замочную скважину и повернуть его в необходимую для разблокировки сторону.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81352,
                    "questionId": 7442,
                    "name": "Вставить ключ от наручников в отверстие на боковой стороне браслета и повернуть его в необходимую для разблокировки сторону.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81353,
                    "questionId": 7442,
                    "name": "Резко потянуть запирающую дужку браслета наручников в сторону отпирания.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7439,
            "name": "Какие вещества (материалы) запрещается хранить совместно с бронеодеждой (жилетами защитными) и бронешлемами (шлемами защитными)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81342,
                    "questionId": 7439,
                    "name": "Гидросорбенты (влагопоглотители).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81343,
                    "questionId": 7439,
                    "name": "Масла и кислоты.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81344,
                    "questionId": 7439,
                    "name": "Резиновые изделия (резину).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7417,
            "name": "Какой класс защиты бронежилета (жилета защитного) позволяет защититься от огня из автоматов АК-74, АКМ патронами, имеющими стальной термоупрочненный сердечник?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81276,
                    "questionId": 7417,
                    "name": "Основной класс защиты Бр2 (класс защиты 2 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81277,
                    "questionId": 7417,
                    "name": "Основной класс защиты Бр3 (класс защиты 3 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81278,
                    "questionId": 7417,
                    "name": "Основной класс защиты Бр4 (класс защиты 4 и 5 по старой классификации).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7448,
            "name": "Какие из перечисленных ниже наручников не используются в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81369,
                    "questionId": 7448,
                    "name": "Наручники конвойные с соединительной цепочкой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81370,
                    "questionId": 7448,
                    "name": "Наручники, предназначенные для стационарного крепления к стенам зданий.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81371,
                    "questionId": 7448,
                    "name": "Пальцевые наручники.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7440,
            "name": "Каково назначение фиксатора, имеющегося в конструкции браслетов наручников, используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81345,
                    "questionId": 7440,
                    "name": "Блокировка доступа к замочной скважине браслета наручников.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81346,
                    "questionId": 7440,
                    "name": "Блокировка механизма зацепления подвижной запирающей дужки браслета наручников.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81347,
                    "questionId": 7440,
                    "name": "Фиксация ключа от наручников к одному из браслетов (во избежание его утери).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7423,
            "name": "Каким дополнительным элементом не комплектуются бронешлемы (шлемы защитные)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81294,
                    "questionId": 7423,
                    "name": "Бармицей для защиты шеи.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81295,
                    "questionId": 7423,
                    "name": "Встроенной радиогарнитурой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81296,
                    "questionId": 7423,
                    "name": "Шейно-плечевой накладкой.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7432,
            "name": "Палка резиновая ПР-73М, разрешенная для использования в частной охранной деятельности, имеют в своей конструкции:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81321,
                    "questionId": 7432,
                    "name": "Боковую ручку.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81322,
                    "questionId": 7432,
                    "name": "Выступающий кольцевой элемент (мини-гарду) рукоятки.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81323,
                    "questionId": 7432,
                    "name": "Металлический наконечник.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7434,
            "name": "Каков минимальный предел температуры окружающего воздуха, установленный в качестве допустимого при эксплуатации палок резиновых ПУС-1, ПУС-2, ПУС-3 (изготовленных из высокомолекулярного полиэтилена), используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81327,
                    "questionId": 7434,
                    "name": "-30°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81328,
                    "questionId": 7434,
                    "name": "-45°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81329,
                    "questionId": 7434,
                    "name": "-60°С.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7421,
            "name": "Какие типы бронежилетов (жилетов защитных) не выпускаются отечественными производителями?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81288,
                    "questionId": 7421,
                    "name": "Бронежилеты с положительной плавучестью.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81289,
                    "questionId": 7421,
                    "name": "Бронежилеты скрытого ношения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81290,
                    "questionId": 7421,
                    "name": "Бронежилеты со специальной подсветкой.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7418,
            "name": "Защита от какого оружия не обеспечивается используемыми в частной охранной деятельности жилетами защитными 1-5 классов защиты (Бр1, Бр2, С1, Бр3, Бр4 по новой классификации)? ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81279,
                    "questionId": 7418,
                    "name": "АКМ с боеприпасом, имеющим стальной термоупрочненный сердечник.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81280,
                    "questionId": 7418,
                    "name": "СВД с боеприпасом, имеющим легкоплавкий сердечник.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81281,
                    "questionId": 7418,
                    "name": "СВД с боеприпасом, имеющим стальной термоупрочненный сердечник.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7444,
            "name": "К дополнительным (съемным) элементам бронезащиты, которыми могут комплектоваться все типы жилетов защитных (за исключением некоторых моделей скрытого ношения) относятся: ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81357,
                    "questionId": 7444,
                    "name": "Сменные жесткие позвоночные накладки, маски защитные, перчатки защитные, локтевые (кольцевые) защитные накладки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81358,
                    "questionId": 7444,
                    "name": "Спецрадиостанции бронированные, планшеты защитные (бронированные), сапоги специальные защитные.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81359,
                    "questionId": 7444,
                    "name": "Шейно-плечевые накладки, паховые накладки, сменные жесткие защитные элементы (бронепластины).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7437,
            "name": "Каков максимальный предел температуры окружающего воздуха, установленный в качестве допустимого при эксплуатации палок резиновых ПР-73М, ПР-К, ПР-Т (изготовленных из резиновой смеси), используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81336,
                    "questionId": 7437,
                    "name": "+30°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81337,
                    "questionId": 7437,
                    "name": "+40°С.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81338,
                    "questionId": 7437,
                    "name": "+50°С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7430,
            "name": "Палка резиновая ПУС-3, разрешенная для использования в частной охранной деятельности, выпускается в следующих вариантах:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81315,
                    "questionId": 7430,
                    "name": "Произвольная и штатная.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81316,
                    "questionId": 7430,
                    "name": "Прямая и с боковой ручкой.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81317,
                    "questionId": 7430,
                    "name": "Раскладная и телескопическая.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7446,
            "name": "Какой класс защитной структуры бронеодежды (жилетов защитных) является минимально достаточным для защиты от огня из пистолета СР-1 (9-мм пистолета Сердюкова) и пистолетов ТТ, ПММ, ПСМ?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81363,
                    "questionId": 7446,
                    "name": "Основной класс защиты Бр1 (класс защиты 1 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81364,
                    "questionId": 7446,
                    "name": "Основной класс защиты Бр2 (класс защиты 2 по старой классификации).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81365,
                    "questionId": 7446,
                    "name": "Основной класс защиты Бр3 (класс защиты 3 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7412,
            "name": "К основному назначению специального средства «наручники», используемого в частной охранной деятельности, можно отнести:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81261,
                    "questionId": 7412,
                    "name": "Ограничение физической возможности правонарушителя по оказанию сопротивления.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81262,
                    "questionId": 7412,
                    "name": "Оказание психологического воздействия на правонарушителя.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81263,
                    "questionId": 7412,
                    "name": "Причинение физического ущерба правонарушителю.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7451,
            "name": "При ношении бронежилетов (жилетов защитных) скрытого ношения рекомендуется использовать одежду:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81378,
                    "questionId": 7451,
                    "name": "На 1-2 размера больше той, которую носит использующий бронежилет (жилет защитный) или одежду свободного покроя.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81379,
                    "questionId": 7451,
                    "name": "На 3-4 размера больше той, которую носит использующий бронежилет (жилет защитный) или одежду свободного покроя.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81380,
                    "questionId": 7451,
                    "name": "Совпадающую по размеру с той, которую носит использующий бронежилет (жилет защитный) или одежду свободного покроя.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7427,
            "name": "Каков минимальный предел температуры окружающего воздуха, при котором обеспечивается надлежащее техническое состояние (исправность) наручников, используемых в частной охранной деятельности? ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81306,
                    "questionId": 7427,
                    "name": "-20°С",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81307,
                    "questionId": 7427,
                    "name": "-30°С",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81308,
                    "questionId": 7427,
                    "name": "-40°С",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7428,
            "name": "Каков максимальный предел температуры окружающего воздуха, при котором обеспечивается надлежащее техническое состояние (исправность) наручников, используемых в частной охранной деятельности? ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81309,
                    "questionId": 7428,
                    "name": "+35°С.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81310,
                    "questionId": 7428,
                    "name": "+40°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81311,
                    "questionId": 7428,
                    "name": "+45°С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7416,
            "name": "Какой класс защитной структуры бронеодежды (жилетов защитных) является минимально достаточным для защиты от огня из пистолетов АПС, ПМ и револьвера системы «Наган»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81273,
                    "questionId": 7416,
                    "name": "Основной класс защиты Бр1 (класс защиты 1 по старой классификации).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81274,
                    "questionId": 7416,
                    "name": "Основной класс защиты Бр2 (класс защиты 2 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81275,
                    "questionId": 7416,
                    "name": "Основной класс защиты Бр3 (класс защиты 3 по старой классификации).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7445,
            "name": "В какой модели наручников, из числа разрешенных в частной охранной деятельности, используется соединительная цепочка?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81360,
                    "questionId": 7445,
                    "name": "БКС-1.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81361,
                    "questionId": 7445,
                    "name": "БОС.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81362,
                    "questionId": 7445,
                    "name": "БР-2М.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7424,
            "name": "Каким способом проверяется фиксация замков наручников, не угрожающая нормальному кровообращению у правонарушителя?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81297,
                    "questionId": 7424,
                    "name": "Визуальным осмотром конечностей правонарушителя на предмет посинения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81298,
                    "questionId": 7424,
                    "name": "Периодическим открытием и закрытием замка наручников.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81299,
                    "questionId": 7424,
                    "name": "Проверкой возможности браслетов наручников без затруднений проворачиваться на конечностях правонарушителя (и в то же время надежно фиксировать их).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7420,
            "name": "Как меняется время непрерывного ношения бронежилета (жилета защитного) при понижении температуры воздуха:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81285,
                    "questionId": 7420,
                    "name": "Остается неизменным.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81286,
                    "questionId": 7420,
                    "name": "Увеличивается.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81287,
                    "questionId": 7420,
                    "name": "Уменьшается.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7413,
            "name": "К основному назначению специального средства «шлем защитный», используемого в частной охранной деятельности, можно отнести:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81264,
                    "questionId": 7413,
                    "name": "Обеспечение индивидуальной защиты головы человека от средств поражения (пуль, осколков, холодного оружия) и контузий вследствие ударов.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81265,
                    "questionId": 7413,
                    "name": "Обеспечение индивидуальной защиты головы человека от средств поражения (пуль, осколков, холодного оружия) и нанесения оборонительных ударов правонарушителю.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81266,
                    "questionId": 7413,
                    "name": "Обеспечение индивидуальной защиты головы, шеи и плеч человека от средств поражения (пуль, осколков, холодного оружия) и контузий вследствие ударов.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7431,
            "name": "Палки резиновые ПУС-2 и ПР-Т, разрешенные для использования в частной охранной деятельности, имеют в своей конструкции:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81318,
                    "questionId": 7431,
                    "name": "Боковую ручку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81319,
                    "questionId": 7431,
                    "name": "Выступающий кольцевой элемент (мини-гарду) рукоятки.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81320,
                    "questionId": 7431,
                    "name": "Металлический наконечник.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7449,
            "name": "Перед надеванием наручников на правонарушителя необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81372,
                    "questionId": 7449,
                    "name": "Освободить запястья от одежды.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81373,
                    "questionId": 7449,
                    "name": "Подложить на запястья в тех местах, на которые будут надеваться наручники, ткань, салфетку или платок.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81374,
                    "questionId": 7449,
                    "name": "Получить на применение наручников разрешение руководителя частной охранной организации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7415,
            "name": "К основному назначению специального средства «палка резиновая», используемого в частной охранной деятельности, можно отнести:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81270,
                    "questionId": 7415,
                    "name": "Активное нападение на лиц, не выполняющих прямое указание охранника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81271,
                    "questionId": 7415,
                    "name": "Активную защиту при нападении (сопротивлении) правонарушителя.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81272,
                    "questionId": 7415,
                    "name": "Предупреждение правонарушителя перед применением огнестрельного оружия, входящего в перечень видов вооружения охранников. ",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7441,
            "name": "Какой способ применяется для включения фиксатора, имеющегося в конструкции браслетов наручников, используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81348,
                    "questionId": 7441,
                    "name": "Нажатие (утопление) штифта фиксатора, расположенного на боковой стороне браслета с помощью ключа от наручников.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81349,
                    "questionId": 7441,
                    "name": "Пальцевое нажатие (утопление) выступа фиксатора, выполненного в виде шляпки одной из заклепок браслета наручников (а именно - расположенной в непосредственной близости от замочной скважины).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81350,
                    "questionId": 7441,
                    "name": "Поворот ключа от наручников в замочной скважине в необходимую для включения фиксатора сторону.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7414,
            "name": "К основному назначению специального средства «жилет защитный», используемого в частной охранной деятельности, можно отнести:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81267,
                    "questionId": 7414,
                    "name": "Обеспечение индивидуальной защиты туловища человека от средств поражения (пуль, осколков, холодного оружия).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81268,
                    "questionId": 7414,
                    "name": "Обеспечение индивидуальной защиты туловища, конечностей, стоп ног и кистей рук человека от средств поражения (пуль, осколков, холодного оружия).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81269,
                    "questionId": 7414,
                    "name": "Прекращение буйства и бесчинства задержанных лиц.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7425,
            "name": "Какая модель наручников, используемых в частной охранной деятельности, обладает жесткой системой крепления браслетов между собой?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81300,
                    "questionId": 7425,
                    "name": "БКС-1.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81301,
                    "questionId": 7425,
                    "name": "БОС.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81302,
                    "questionId": 7425,
                    "name": "БР-С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7429,
            "name": "Чистка и смазка наручников, используемых в частной охранной деятельности, производится:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81312,
                    "questionId": 7429,
                    "name": "В порядке, установленном соответствующим Постановление Правительства РФ.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81313,
                    "questionId": 7429,
                    "name": "Произвольно, по решению охранника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81314,
                    "questionId": 7429,
                    "name": "Согласно инструкции предприятия-изготовителя.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7438,
            "name": "Бронеодежда (жилеты защитные) и бронешлемы (шлемы защитные), за исключением изготовленных специально для особых условий эксплуатации, могут терять свои свойства:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81339,
                    "questionId": 7438,
                    "name": "При воздействии ультрафиолетового излучения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81340,
                    "questionId": 7438,
                    "name": "При намокании.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81341,
                    "questionId": 7438,
                    "name": "При температуре +30°С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7426,
            "name": "Какая модель наручников, используемых в частной охранной деятельности, имеет вариант изготовления, предназначенный для стационарного крепления к стенам зданий?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81303,
                    "questionId": 7426,
                    "name": "БКС-1.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81304,
                    "questionId": 7426,
                    "name": "БОС.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81305,
                    "questionId": 7426,
                    "name": "БР-С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7419,
            "name": "Как меняется время непрерывного ношения бронежилета (жилета защитного) при повышении температуры и влажности воздуха:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81282,
                    "questionId": 7419,
                    "name": "Остается неизменным.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81283,
                    "questionId": 7419,
                    "name": "Увеличивается.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81284,
                    "questionId": 7419,
                    "name": "Уменьшается.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7422,
            "name": "Защита от какого оружия не обеспечивается бронешлемами (шлемами защитными) 1-3 классов защиты? ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81291,
                    "questionId": 7422,
                    "name": "АПС.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81292,
                    "questionId": 7422,
                    "name": "СВД.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81293,
                    "questionId": 7422,
                    "name": "ТТ, ПММ, ПСМ.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7436,
            "name": "Каков максимальный предел температуры окружающего воздуха, установленный в качестве допустимого при эксплуатации палок резиновых ПУС-1, ПУС-2, ПУС-3 (изготовленных из высокомолекулярного полиэтилена), используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81333,
                    "questionId": 7436,
                    "name": "+30°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81334,
                    "questionId": 7436,
                    "name": "+40°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81335,
                    "questionId": 7436,
                    "name": "+50°С.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7433,
            "name": "Какой из перечисленных способов надевания одной пары наручников может быть применен и при этом является наиболее эффективным для затруднения сопротивления и предотвращения побега двух задержанных:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81324,
                    "questionId": 7433,
                    "name": "На правую руку одного задержанного и левую руку другого.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81325,
                    "questionId": 7433,
                    "name": "На правую руку одного задержанного и правую руку другого.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81326,
                    "questionId": 7433,
                    "name": "На руку одного задержанного и на ногу другого.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7435,
            "name": "Каков минимальный предел температуры окружающего воздуха, установленный в качестве допустимого при эксплуатации палок резиновых ПР-73М, ПР-К, ПР-Т (изготовленных из резиновой смеси), используемых в частной охранной деятельности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81330,
                    "questionId": 7435,
                    "name": "-30°С.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81331,
                    "questionId": 7435,
                    "name": "-45°С.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81332,
                    "questionId": 7435,
                    "name": "-60°С.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7443,
            "name": "Хранение каких видов специальных средств, используемых в частной охранной деятельности, допускается ближе 1 метра от отопительных приборов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81354,
                    "questionId": 7443,
                    "name": "Жилетов и шлемов защитных.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81355,
                    "questionId": 7443,
                    "name": "Наручников.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81356,
                    "questionId": 7443,
                    "name": "Палок резиновых.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7447,
            "name": "Какая особенность не характерна для применения наручников БОС, имеющих жесткую систему крепления браслетов между собой?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81366,
                    "questionId": 7447,
                    "name": "Возможность мгновенной стыковки и расстыковки браслетов наручников между собой с помощью электромагнитного затвора.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81367,
                    "questionId": 7447,
                    "name": "Снижение вероятности доступа к замкам браслетов и перевода зафиксированных рук правонарушителя из положения «сзади» в положение «спереди».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81368,
                    "questionId": 7447,
                    "name": "Сравнительно малое время надевания браслетов на оказывающего сопротивление правонарушителя.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7537,
            "name": "Какие динамические и эмоциональные признаки могут свидетельствовать о возможной готовности лица к совершению преступления террористической направленности на объектах социальной сферы (в том числе, на объектах образования)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81435,
                    "questionId": 7537,
                    "name": "Общая расслабленность, меланхоличный взгляд, неспешная походка.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81436,
                    "questionId": 7537,
                    "name": "Открытый взгляд, веселый  смех, ходьба «вприпрыжку», активная реакция на отвлекающие факторы на объекте и при приближении к нему, положительный эмоциональный фон.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81437,
                    "questionId": 7537,
                    "name": "Сжатые губы, наклон головы, торопливость и размашистость (не руками) движений, отсутствие реакции на отвлекающие факторы при подходе к объекту и при движении по нему, отрицательный эмоциональный фон.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7528,
            "name": "В процессе формирования идеологии терроризма последствием корыстолюбия в крайнем его проявлении является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81408,
                    "questionId": 7528,
                    "name": "Готовность добросовестно трудиться ради сохранения духовных ценностей, ради своей веры, страны, родных и близких.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81409,
                    "questionId": 7528,
                    "name": "Готовность получить Нобелевскую премию (вне зависимости от реальных заслуг).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81410,
                    "questionId": 7528,
                    "name": "Готовность ради материальной заинтересованности отказаться от любых духовных ценностей, продать и предать свою веру, страну, родных и близких.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7532,
            "name": "В чем особенность действий проверяющих в случае, когда выявленный в ходе осуществления пропускного и внутриобъектового режима предмет (из числа запрещенных к перемещению на объект) на самом деле является тест-предметом (предметом, имитирующим оружие, взрывчатые вещества или иные предметы, запрещенные к перемещению на объект и используемым в целях проверки эффективности охраны)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81420,
                    "questionId": 7532,
                    "name": "Проверяющие лица в целях обеспечения безопасности незамедлительно информируют сотрудника охраны, выявившего (обнаружившего) тест-предмет о проведении проверки с предъявлением служебных удостоверений.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81421,
                    "questionId": 7532,
                    "name": "Проверяющие лица не информируют о проведении проверки сотрудника охраны, выявившего (обнаружившего) тест-предмет в целях контроля развития ситуации «в естественных условиях» (ждут дальнейших действий сотрудника охраны).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81422,
                    "questionId": 7532,
                    "name": "Проверяющие лица провоцируют сотрудника охраны на активные действия (пытаются скрыться, применяют к сотруднику охраны физическую силу, угрожают оружием, прикрываются заложником из числа посетителей и т.п.).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7524,
            "name": "Какой вариант состава и периодичности производства доклада о наличии либо отсутствии признаков террористической угрозы на охраняемом объекте в наибольшей степени соответствует задаче обеспечения безопасности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81396,
                    "questionId": 7524,
                    "name": "Доклад о наличии либо отсутствии признаков террористической угрозы на охраняемом объекте производится при заступлении на пост и при сдаче поста; включает в себя информацию о том, что осмотрено непосредственное место расположения охранника (центр поста), признаки наличия возможной террористической угрозы (обнаружены или не обнаружены).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81397,
                    "questionId": 7524,
                    "name": "Доклад о наличии либо отсутствии признаков террористической угрозы на охраняемом объекте производится при заступлении на пост, при сдаче поста, а также в течение смены (с установленной руководством периодичностью); включает в себя информацию о том, что осмотрена зона ответственности охранника и участки территории, непосредственно к ней прилегающие, в том числе критические элементы,  технические полости, коммуникации, предметы и оборудование в помещениях, в результате чего посторонние предметы, предметы, принадлежность и назначение которых неизвестны, посторонние лица, лица с подозрительным или нестандартным поведением, а также иные признаки наличия возможной террористической угрозы (обнаружены или не обнаружены).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81398,
                    "questionId": 7524,
                    "name": "Доклад о наличии либо отсутствии признаков террористической угрозы на охраняемом объекте производится только при заступлении на пост; включает в себя информацию о том, что осмотрено непосредственное место расположения охранника (центр поста), признаки наличия возможной террористической угрозы (обнаружены или не обнаружены).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7526,
            "name": "Что лежит в основе идеологии терроризма, как идеологии отрицания общечеловеческих ценностей?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81402,
                    "questionId": 7526,
                    "name": "Отрицание основных принципов, заложенных во Всеобщей декларации прав человека и в Уставе ООН.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81403,
                    "questionId": 7526,
                    "name": "Отрицание положительной идеологии (идеологии, основанной на любви).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81404,
                    "questionId": 7526,
                    "name": "Пренебрежение нормами уголовного и административного права.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7527,
            "name": "В процессе формирования идеологии терроризма последствием славолюбия в крайнем его проявлении является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81405,
                    "questionId": 7527,
                    "name": "Готовность жить ради прославления себя, насколько это получится.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81406,
                    "questionId": 7527,
                    "name": "Готовность жить, не стараясь прославиться и тем более, не ища славы за счет причинения горя и вреда другим людям.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81407,
                    "questionId": 7527,
                    "name": "Готовность ради славы жертвовать чужой, а иногда и собственной жизнью.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7533,
            "name": "Как следует поступать после выявления нарушения в случае, когда нарушитель заявил, что он осуществлял проверку эффективности охраны с использованием тест-предметов и/или тест-объектов и предъявил служебное удостоверение сотрудника надзорных или правоохранительных органов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81423,
                    "questionId": 7533,
                    "name": "Исходя из того, что настоящий нарушитель может использовать поддельные документы для прикрытия преступной деятельности, не принимать на веру такое заявление, запросить в установленном порядке усиление охраны и предложить возможному нарушителю оставаться на месте происшествия до момента прибытия территориальных органов МВД или ФСБ и подтверждения ими факта осуществления проверки (при этом контролируя его поведение и действия).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81424,
                    "questionId": 7533,
                    "name": "Незамедлительно задержать возможного нарушителя с применением физической силы, специальных средств и оружия (даже если он по предложению сотрудника охраны остается на месте происшествия, спокойно ожидая прибытия представителей территориальных органов МВД или ФСБ для подтверждения факта осуществления проверки).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81425,
                    "questionId": 7533,
                    "name": "Незамедлительно принять на веру такое заявление и в дальнейшем действовать по указанию лица, предъявившего служебное удостоверение.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7529,
            "name": "В процессе формирования идеологии терроризма последствием самолюбия в крайнем его проявлении является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81411,
                    "questionId": 7529,
                    "name": "Забота об окружающих людях.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81412,
                    "questionId": 7529,
                    "name": "Самосовершенствование.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81413,
                    "questionId": 7529,
                    "name": "Человеконенавистничество.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7521,
            "name": "Что нельзя отнести к общепринятой классификации терроризма?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81387,
                    "questionId": 7521,
                    "name": "Классификация терроризма в зависимости от вида населенного пункта по месту совершения террористического акта (подразделение на городской и поселковый).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81388,
                    "questionId": 7521,
                    "name": "Классификация терроризма в зависимости от преступной мотивации (подразделение на политический, национальный, религиозный, криминальный).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81389,
                    "questionId": 7521,
                    "name": "Классификация терроризма по территориальному признаку (подразделение на международный и внутригосударственный).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7534,
            "name": "Какие действия не входят в примерную общую последовательность действий на месте происшествия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81426,
                    "questionId": 7534,
                    "name": "Организация оцепления (ограждения) места происшествия, а также принятие иных мер к сохранению обстановки и следов на месте происшествия (фотографирование места происшествия и т.п.); регистрация данных прибывающих экстренных служб (государственные или бортовые номера а/машин и т.п.); доклад обстановки старшему следственно-оперативной группы; продолжение выполнения задач по охране объекта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81427,
                    "questionId": 7534,
                    "name": "Прохождение сотрудниками охраны инструктажа о порядке действий на месте происшествия.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81428,
                    "questionId": 7534,
                    "name": "Фиксация точного времени происшествия; вызов на место происшествия сотрудников территориального органа внутренних дел и иных экстренных служб; доклад о происшедшем событии в службу охраны, администрацию объекта; оказание первой помощи пострадавшим; организация эвакуации людей, предотвращение развитие пожаров и иных опасных последствий происшествия; организация усиления охраны; регистрация данных очевидцев происшествия.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7531,
            "name": "Какие эмоции являются последствиями отрицательных нравственных качеств и могут помочь распознать отрицательную идеологию в себе и других людях?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81417,
                    "questionId": 7531,
                    "name": "Никакие, поскольку отрицательная идеология не влечет за собой эмоциональных последствий.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81418,
                    "questionId": 7531,
                    "name": "Радость, свобода, уверенность, ясность, справедливость, спокойствие, мирность, утружденность.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81419,
                    "questionId": 7531,
                    "name": "Страх, зависимость, сомнение, смущение, стыд, раздражение, взбудораженность, напряженность.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7523,
            "name": "Какой вариант ответа более полно отражает необходимые действия в ходе осмотра объекта или части объекта (зоны ответственности охранника) при заступлении на пост в целях обнаружения признаков наличия возможной террористической угрозы?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81393,
                    "questionId": 7523,
                    "name": "Осмотр коммуникаций и критических элементов объекта (или части объекта, входящей в зону ответственности охранника), технических полостей, предметов и оборудования в помещениях с уточнением наличия угрожающих признаков, принадлежности и назначения обнаруживаемых предметов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81394,
                    "questionId": 7523,
                    "name": "Осмотр предметов и оборудования в помещениях с уточнением наличия угрожающих признаков, принадлежности и назначения обнаруживаемых предметов.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81395,
                    "questionId": 7523,
                    "name": "Осмотр прилегающей территории и периметра объекта (либо их участков, непосредственно прилегающих к зоне ответственности охранника), осмотр коммуникаций и критических элементов объекта (или части объекта, входящей в зону ответственности охранника), технических полостей, предметов и оборудования в помещениях с уточнением наличия угрожающих признаков, принадлежности и назначения обнаруживаемых предметов.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7520,
            "name": "Каков полный перечень видов (этапов) противодействия терроризму?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81384,
                    "questionId": 7520,
                    "name": "Предупреждение терроризма (профилактика терроризма), минимизации и ликвидации последствий террористических проявлений.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81385,
                    "questionId": 7520,
                    "name": "Предупреждение терроризма (профилактика терроризма), противодействие террористическим актам (борьба с терроризмом), минимизации и ликвидации последствий террористических проявлений.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81386,
                    "questionId": 7520,
                    "name": "Противодействие террористическим актам (борьба с терроризмом), минимизации и ликвидации последствий террористических проявлений.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7535,
            "name": "Какие характеристики, выявляемые в ходе профайлинга при выяснении конкретных обстоятельств, связанных с наличием или отсутствием вины собеседника, обычно свидетельствуют о виновности опрашиваемого?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81429,
                    "questionId": 7535,
                    "name": "В беседе многословен, нередко дает исчерпывающую информацию со всеми подробностями, даже не имеющими прямого отношения к делу; постоянно старается перебить своими объяснениями.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81430,
                    "questionId": 7535,
                    "name": "Начинает активно защищаться до того, как его в чем-то обвинят или заподозрят либо может выражать подозрительность, недоверие, не оказывая при этом противодействия в выяснении обстоятельств по делу.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81431,
                    "questionId": 7535,
                    "name": "Немногословен, сдерживает себя при ответах на вопросы, может отказаться от объяснений без видимых причин; выражает желание помочь при выяснении тех или иных обстоятельств, указывает на точность мелких деталей в своих объяснениях.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7530,
            "name": "Что делает невозможным подчинение отрицательной идеологии вообще и идеологии терроризма в частности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81414,
                    "questionId": 7530,
                    "name": "Высокий коэффициент IQ.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81415,
                    "questionId": 7530,
                    "name": "Сохранение в душе чувства благодарности.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81416,
                    "questionId": 7530,
                    "name": "Сохранение собственного авторитета в глазах окружающих.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7538,
            "name": "Какие предметы одежды, вещи, аксессуары могут свидетельствовать о возможной направленности лица на совершение преступления террористического характера на объектах социальной сферы (в том числе, на объектах образования)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81438,
                    "questionId": 7538,
                    "name": "Длиннополые плащи, куртки или пальто, иногда не соответствующие сезону (реже – камуфлированная одежда, разгрузочные жилеты), одежда с изображениями или надписями агрессивного и экстремистского содержания, большие сумки и/или рюкзаки (позволяющие разместить предметы длиной 50 см. или более), тубусы, чехлы для теннисных ракеток или музыкальных инструментов, иногда маски, раскраска лица, в отдельных случаях - татуировки, кулоны, браслеты со знаком в виде восьмиконечной звезды, характерные для экстремистов, пытающихся популяризировать уголовный уклад в молодежной среде.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81439,
                    "questionId": 7538,
                    "name": "Одежда обычного (условно «среднего») для посетителей объекта стиля и фасона, практически всегда соответствующая сезону (как правило, без использования камуфлированной расцветки и разгрузочных жилетов), портфели, сумки или рюкзаки минимально необходимых размеров.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81440,
                    "questionId": 7538,
                    "name": "Одежда ярких, привлекающих внимание расцветок, стилей и фасонов, иногда демонстративно несоответствующая сезону, в отдельных случаях «агрессивная» косметика и пирсинг, серьги у мужчин, волосы могут быть окрашены в неестественные цвета.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7536,
            "name": "Какие действия (приемы) обычно способствуют недопущению конфликта или его урегулированию?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81432,
                    "questionId": 7536,
                    "name": "Использование приема защиты, взятие на себя роли младшего, подключение вышестоящего начальства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81433,
                    "questionId": 7536,
                    "name": "Использование приема нападения, взятие на себя роли старшего, демонстрация преобладания своей физической силы или своего морального превосходства.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81434,
                    "questionId": 7536,
                    "name": "Использование приема переговоров, взятие на себя роли партнера, совместное нахождение компромисса, не нарушающего правил и инструкций.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7522,
            "name": "В каких целях организуется взаимодействие частных охранных организаций с оперативными группами в муниципальных образованиях?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81390,
                    "questionId": 7522,
                    "name": "В целях ежедневного совместного осмотра объекта с обязательным участием представителя оперативной группы в муниципальном образовании.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81391,
                    "questionId": 7522,
                    "name": "В целях исключения любых внешних и внутренних (криминальных и террористических) угроз объекту.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81392,
                    "questionId": 7522,
                    "name": "В целях повышения качества работы по профилактике терроризма, минимизации и (или) ликвидации последствий проявлений терроризма.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7525,
            "name": "Что лежит в основе идеологии терроризма, как идеологии насилия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81399,
                    "questionId": 7525,
                    "name": "Желание нарушать законы, принятые в обществе.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81400,
                    "questionId": 7525,
                    "name": "Желание применять насилие к отдельным людям или группам людей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81401,
                    "questionId": 7525,
                    "name": "Желание считать себя «имеющим право» на насилие.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7519,
            "name": "В чем состоит особенность действий охранника 4 разряда в ходе противодействия террористическим угрозам? (4 разряд)",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81381,
                    "questionId": 7519,
                    "name": "В связи с возможным наличием у охранника специальных средств необходимо дополнительно прогнозировать эффективность, а также возможные положительные и отрицательные последствия от их применения с учетом опасности террористической угрозы.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81382,
                    "questionId": 7519,
                    "name": "В связи с возможным наличием у охранника специальных средств, необходимо понимать, что в ситуации противодействия террористическим угрозам их применять категорически запрещается.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81383,
                    "questionId": 7519,
                    "name": "Каких-либо особенностей действий для охранника 4 разряда в ходе противодействия террористическим угрозам не усматривается.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7539,
            "name": "В системах охранно-пожарной сигнализации могут применяться, среди прочих, следующие оповещатели:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81441,
                    "questionId": 7539,
                    "name": "Емкостные.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81442,
                    "questionId": 7539,
                    "name": "Магнитоконтактные.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81443,
                    "questionId": 7539,
                    "name": "Световые.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7567,
            "name": "Какой тип (модель) носимого металлодетектора обеспечивает скрытое распознавание наличия оружия (металлического предмета большой массы) под одеждой посетителя на расстоянии до 70 см.:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81525,
                    "questionId": 7567,
                    "name": "Ручной металлодетектор АКА-7210 МИНИСКАН (с акустическим и световым сигналом оповещения).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81526,
                    "questionId": 7567,
                    "name": "Ручной металлодетектор СФИНКС ВМ-311 (с акустическим и световым сигналом оповещения).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81527,
                    "questionId": 7567,
                    "name": "Скрытоносимый селективный металлодетектор АКА 7220 (с сигналом оповещения, передаваемом на наушники, в том числе по радиоканалу).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7548,
            "name": "Какое понятие определяется, как «совокупность совместно действующих технических средств обнаружения проникновения (попытки проникновения) на охраняемый объект, сбора, обработки, передачи и представления в заданном виде информации о проникновении (попытке проникновения) и другой служебной информации»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81468,
                    "questionId": 7548,
                    "name": "Система охранной сигнализации.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81469,
                    "questionId": 7548,
                    "name": "Система технической безопасности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81470,
                    "questionId": 7548,
                    "name": "Система тревожной сигнализации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7540,
            "name": "В системах охранно-пожарной сигнализации могут применяться, среди прочих, следующие датчики (извещатели):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81444,
                    "questionId": 7540,
                    "name": "Акустические.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81445,
                    "questionId": 7540,
                    "name": "Радиационные.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81446,
                    "questionId": 7540,
                    "name": "Телевизионные.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7550,
            "name": "Система тревожной сигнализации на объекте организуется с использованием принципа:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81474,
                    "questionId": 7550,
                    "name": "«Без права отключения».",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81475,
                    "questionId": 7550,
                    "name": "«С правом отключения охранником объекта».",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81476,
                    "questionId": 7550,
                    "name": "«С правом отключения при падении напряжения».",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7563,
            "name": "Ограждение периметра (отдельных участков территории) охраняемого объекта, в соответствии с техническими нормами подразделяется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81513,
                    "questionId": 7563,
                    "name": "На внутризонное (располагаемое в пределах одной зоны безопасности), внешнезонное и межзонное.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81514,
                    "questionId": 7563,
                    "name": "На основное, дополнительное (располагаемое сверху и/или снизу от основного), предупредительное (располагаемое с внешней и/или с внутренней стороны от основного).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81515,
                    "questionId": 7563,
                    "name": "На электрическое, механическое и электро-механическое (комплексное).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7542,
            "name": "Какие из приведенных ниже сокращенных (полных) наименований используются для обозначения систем спутниковой навигации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81450,
                    "questionId": 7542,
                    "name": "GPRS (Джи-Пи-Эр-Эс), Скайп.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81451,
                    "questionId": 7542,
                    "name": "GPS (Джи-Пи-Эс), Глонасс.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81452,
                    "questionId": 7542,
                    "name": "GSM (Джи-Эс-Эм), Скайлинк.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7568,
            "name": "Какое техническое средство позволяет охраннику незаметно передать на приемно-контрольный прибор сигнализации скрытый сигнал тревоги?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81528,
                    "questionId": 7568,
                    "name": "Акустический датчик (извещатель), включенный в периметр сигнализации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81529,
                    "questionId": 7568,
                    "name": "Переносная тревожная кнопка, использующая радиоканал.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81530,
                    "questionId": 7568,
                    "name": "Радиоволновый датчик (извещатель), включенный в периметр сигнализации.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7554,
            "name": "Для осмотра труднодоступных внутренних полостей различных предметов, устройств и конструкций используется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81486,
                    "questionId": 7554,
                    "name": "Монокуляр.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81487,
                    "questionId": 7554,
                    "name": "Пробоотборник.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81488,
                    "questionId": 7554,
                    "name": "Технический эндоскоп.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7564,
            "name": "Охранные телевизионные системы в соответствии с требованиями государственных стандартов должны быть устойчивы:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81516,
                    "questionId": 7564,
                    "name": "К «ослеплению» каждой отдельно взятой камеры наблюдения лазерным лучом.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81517,
                    "questionId": 7564,
                    "name": "К механическому воздействию.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81518,
                    "questionId": 7564,
                    "name": "К несанкционированному доступу к программному обеспечению.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7547,
            "name": "Какое понятие определяется, как «совокупность совместно действующих технических средств, позволяющих автоматически или вручную выдавать сигналы тревоги на ПЦН (в дежурную часть) при разбойном нападении на объект в период его работы»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81465,
                    "questionId": 7547,
                    "name": "Система охранной сигнализации.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81466,
                    "questionId": 7547,
                    "name": "Система технической безопасности.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81467,
                    "questionId": 7547,
                    "name": "Система тревожной сигнализации.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7566,
            "name": "При использовании технических средств охраны, компьютерной и оргтехники охраннику в части технических требований по их эксплуатации следует руководствоваться:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81522,
                    "questionId": 7566,
                    "name": "Личным усмотрением.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81523,
                    "questionId": 7566,
                    "name": "Требованиями инструкции на посту, а также указаниями администрации охраняемого объекта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81524,
                    "questionId": 7566,
                    "name": "Требованиями инструкции предприятий-производителей указанных средств.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7545,
            "name": "Какой из режимов допускает одновременное открытие обоих дверей (ворот) тамбура безопасности (входного шлюза)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81459,
                    "questionId": 7545,
                    "name": "Режим допуска руководителя объекта.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81460,
                    "questionId": 7545,
                    "name": "Режим утреннего «наплыва» посетителей.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81461,
                    "questionId": 7545,
                    "name": "Режим экстренной эвакуации.",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7543,
            "name": "Какой из приведенных ниже запретов предусмотрен общепринятыми правилами радиообмена, действующими в подразделениях охраны (дисциплиной связи)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81453,
                    "questionId": 7543,
                    "name": "Запрет на использование кодовых обозначений (переговорных таблиц).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81454,
                    "questionId": 7543,
                    "name": "Запрет на передачу открытым текстом сообщений, раскрывающих существо охранных мероприятий.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81455,
                    "questionId": 7543,
                    "name": "Запрет на передачу сведений о метеорологических условиях.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7555,
            "name": "Для обеспечения безопасного поиска ферромагнитных предметов (черных металлов) в условиях возможного наличия взрывных устройств с электронной схемой подрыва используются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81489,
                    "questionId": 7555,
                    "name": "Магнитометрические поисковые приборы.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81490,
                    "questionId": 7555,
                    "name": "Металлодетекторы с собственным зондирующим электромагнитным полем.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81491,
                    "questionId": 7555,
                    "name": "Нелинейные локаторы.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7560,
            "name": "Укажите вариант, в котором наиболее полно и правильно указаны все классы защиты, на которые в соответствии с государственным стандартом подразделяется пулестойкое стекло (бронестекло):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81504,
                    "questionId": 7560,
                    "name": "1; 2; 2а; 3; 4; 5; 5а (с защитой вплоть до АКМ с боеприпасом 7,62, имеющим специальный сердечник).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81505,
                    "questionId": 7560,
                    "name": "1; 2; 2а; 3; 4; 5; 5а; 6 (с защитой вплоть до СВД с боеприпасом, имеющим стальной термоупроченный сердечник).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81506,
                    "questionId": 7560,
                    "name": "1; 2; 2а; 3; 4; 5; 5а; 6; 6а (с защитой вплоть до СВД с боеприпасом 7,62, имеющим специальный сердечник).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7562,
            "name": "Радионаправлением называется способ организации радиосвязи:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81510,
                    "questionId": 7562,
                    "name": "Между двумя корреспондентами, имеющими разные радиоданные (разные рабочие частоты).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81511,
                    "questionId": 7562,
                    "name": "Между двумя корреспондентами, имеющими, одинаковые радиоданные (одинаковые рабочие частоты).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81512,
                    "questionId": 7562,
                    "name": "Между радиостанциями не менее, чем трех корреспондентов (при этом не менее чем у двух из них мощности радиосигнала совпадают).",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7565,
            "name": "В структуре ограждения периметра охраняемого частной охраной объекта могут применяться (использоваться):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81519,
                    "questionId": 7565,
                    "name": "Зона отторжения (участок между основным и внутренним предупредительным ограждением), контрольно-следовая полоса.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81520,
                    "questionId": 7565,
                    "name": "Оголенные провода с током высокого напряжения.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81521,
                    "questionId": 7565,
                    "name": "Устройства автоматического затопления, автоматические стреляющие устройства.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7556,
            "name": "Первое действие охранника при организации передачи информации по каналу радиосвязи:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81492,
                    "questionId": 7556,
                    "name": "Нажать клавишу тонального вызова.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81493,
                    "questionId": 7556,
                    "name": "Нажать на тангенту (клавишу передачи) радиостанции и вызвать корреспондента, назвав его и свой позывной.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81494,
                    "questionId": 7556,
                    "name": "Убедиться, что канал не занят (радиообмен не производится).",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7561,
            "name": "Основные типы огнетушителей, используемые в качестве первичных средств пожаротушения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81507,
                    "questionId": 7561,
                    "name": "Водные, Воздушно-пенные, Порошковые, Углекислотные, Хладоновые",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81508,
                    "questionId": 7561,
                    "name": "Воздушные, Воздушно-капельные, Кислотные, Газонаполненные, Радоновые",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81509,
                    "questionId": 7561,
                    "name": "Высокого давления, Низкого давления, Распылительные, Специальные, Аргоновые",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7551,
            "name": "Основное назначение системы контроля и управления доступом (СКУД):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81477,
                    "questionId": 7551,
                    "name": "Обеспечение санкционированного входа и выхода, а также предотвращение несанкционированного прохода в здания, помещения и зоны ограниченного доступа.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81478,
                    "questionId": 7551,
                    "name": "Передача извещений о срабатывании охранной сигнализации с объекта на ПЦО.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81479,
                    "questionId": 7551,
                    "name": "Ретрансляция сигналов радиосвязи в пределах территории объекта.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7544,
            "name": "Какой принцип закладывается в основу работы тамбура безопасности (шлюза), оборудуемого при входе (въезде) на охраняемый объект?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81456,
                    "questionId": 7544,
                    "name": "Двери (ворота) открываются независимо друг от друга по усмотрению охранника.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81457,
                    "questionId": 7544,
                    "name": "Одна дверь (ворота) не открывается, пока не будет закрыта другая дверь (ворота).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81458,
                    "questionId": 7544,
                    "name": "Первая и вторая дверь (ворота) открываются и закрываются одновременно.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7552,
            "name": "Основное назначение системы охранного телевидения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81480,
                    "questionId": 7552,
                    "name": "Обеспечение передачи визуальной информации о состоянии охраняемых зон, помещений, периметра и территории объекта в помещение охраны.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81481,
                    "questionId": 7552,
                    "name": "Оперативное информирование людей о возникшей или приближающейся внештатной ситуации (аварии, пожаре, стихийном бедствии, нападении, террористическом акте) и координация их действий.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81482,
                    "questionId": 7552,
                    "name": "Ретрансляция сигналов радиосвязи в пределах территории объекта.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7549,
            "name": "Технические требования к воротам с электроприводом и дистанционным управлением предусматривают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81471,
                    "questionId": 7549,
                    "name": "Оборудование ворот устройствами аварийной остановки и открытия вручную на случай неисправности или отключения электропитания.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81472,
                    "questionId": 7549,
                    "name": "Обязательность обучения оператора по 5 классу электрозащиты.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81473,
                    "questionId": 7549,
                    "name": "Установленное время их открытия и закрытия не более 20 секунд в обоих режимах.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7546,
            "name": "Какие из приведенных ниже сведений, согласно общепринятым правилам радиообмена, могут передаваться открытым текстом по радиосвязи?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81462,
                    "questionId": 7546,
                    "name": "Сведения о происшествиях на особорежимных и оборонных объектах.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81463,
                    "questionId": 7546,
                    "name": "Сведения о стихийных бедствиях и несчастных случаях (без указания особо важных объектов и количества жертв).",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81464,
                    "questionId": 7546,
                    "name": "Сведения о фамилиях и должностях работников охранной организации и охраняемого объекта.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7558,
            "name": "В случае наличия на объекте (посту) охраны огнетушителя с сорванной (нарушенной) пломбой охраннику следует:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81498,
                    "questionId": 7558,
                    "name": "Выбросить огнетушитель в место для бытовых отходов, как непригодный, с уведомлением об этом своего руководства (руководства объекта).",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81499,
                    "questionId": 7558,
                    "name": "Доложить своему руководству (руководству объекта) о необходимости его замены, поскольку в соответствии с техническими требованиями такой огнетушитель должен быть отправлен на проверку.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81500,
                    "questionId": 7558,
                    "name": "Постараться закрепить пломбу на прежнее место и продолжить осуществление трудовой функции.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7553,
            "name": "Основное назначение системы оповещения на охраняемом объекте:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81483,
                    "questionId": 7553,
                    "name": "Обеспечение передачи визуальной информации о состоянии охраняемых зон, помещений, периметра и территории объекта в помещение охраны.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81484,
                    "questionId": 7553,
                    "name": "Оперативное информирование людей о возникшей или приближающейся внештатной ситуации (аварии, пожаре, стихийном бедствии, нападении, террористическом акте) и координация их действий.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81485,
                    "questionId": 7553,
                    "name": "Ретрансляция сигналов радиосвязи в пределах территории объекта.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7541,
            "name": "В большинстве систем охранно-пожарной сигнализации сигнал от охранных датчиков (извещателей) передается непосредственно:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81447,
                    "questionId": 7541,
                    "name": "На ПКП (приемно-контрольный прибор), формирующий сигнал тревоги.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81448,
                    "questionId": 7541,
                    "name": "На пульт дежурного территориального органа внутренних дел.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81449,
                    "questionId": 7541,
                    "name": "На ПЦН (пульт централизованного наблюдения) подразделения вневедомственной охраны.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7559,
            "name": "К первичным средствам пожаротушения относятся:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81501,
                    "questionId": 7559,
                    "name": "Переносные или передвижные огнетушители, пожарные краны и средства обеспечения их использования, пожарный инвентарь, покрывала для изоляции очага возгорания.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81502,
                    "questionId": 7559,
                    "name": "Пожарные автомобили.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81503,
                    "questionId": 7559,
                    "name": "Пожарные мотопомпы.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7557,
            "name": "Какой из приведенных примеров диалога охранников по средствам радиосвязи наиболее точно соответствует правилам радиообмена (дисциплине связи):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 81495,
                    "questionId": 7557,
                    "name": "«Волга, Волга», я – «Ока». Прошу на связь. / «Ока», «Волга» - на связи. / «Волга», вариант 11 для 01 / «Ока», я вас понял. Конец связи.",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 81496,
                    "questionId": 7557,
                    "name": "«Волга», я – «Ока». / «Волга» - на связи. / «Волга», сам прибывает. / Понял.",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 81497,
                    "questionId": 7557,
                    "name": "«Волга», я – Петров. Прошу на связь. / Петров, какие проблемы? / «Волга», генеральный прибывает. / Бегу открывать. До связи.",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        }
    ],
    "periodicnypename": "--",
    "guardcatname": "4 разряд"
}