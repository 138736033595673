import { NgModule } from '@angular/core';
import { MetrikaModule } from 'ng-yandex-metrika';

@NgModule({
  imports: [
    MetrikaModule.forRoot(
      {id: 96500286, webvisor: true}, // CounterConfig | CounterConfig[]
      // Можно задать ид счетчика, либо порядковый номер в массиве, необязательный параметрб по умолчанию первый попавшийся.
      96500286, // number | string
    ),
  ]
})
export class MyMetrikaModule {}