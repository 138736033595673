import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exam-form-settings',
  templateUrl: './exam-form-settings.component.html',
  styleUrls: ['./exam-form-settings.component.css']
})
export class ExamFormSettingsComponent implements OnInit {
  settingsForm = new FormGroup({
    grade: new FormControl('grade4'),
    mode: new FormControl('1'),
  });

  constructor(private _router: Router) { }
  

  ngOnInit() {
  }

  redirectToStartTest(): void {
    const { mode, grade} = this.settingsForm.value;
    this._router.navigate([`/quiz/${grade}/${mode}`]);
  }

}
