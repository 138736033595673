import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { ChannelService, ConnectionState } from "app/services/channel.service";

import { QuizComponent } from './quiz/quiz.component'
import { Metrika } from 'ng-yandex-metrika';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{


    constructor(private metrika: Metrika){}

    ngOnInit(){
      // Start the connection up!
      console.log('Starting the channel service');

      // this.channelService.start();
    }
}
