import { Injectable } from '@angular/core';



@Injectable()
export class FirmaService {

  constructor() { }

  getFirma(){
    return [
      { firmaID: 1, name: 'Филиал 1' },
      { firmaID: 2, name: 'Филиал 2' },
      { firmaID: 3, name: 'Филиал 3' },
      { firmaID: 4, name: 'Филиал 4' },
      { firmaID: 5, name: 'Филиал 5' },
      { firmaID: 6, name: 'Филиал 6' },
    ]
  }

}

