import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { QuizService } from '../services/quiz.service';
import { Option, Question, Quiz, QuizConfig } from '../models/index';
import { QuizResponse } from "../models/quiz-response";

import { LocalStorageService } from 'angular-2-local-storage';
import { API_BASE_URL } from "app/services/quiz.service";
import { TestingModes } from './quiz.constants';

import 'rxjs/Rx';
import { FirmaService } from "app/services/firma.service";
import { ActivatedRoute, ParamMap } from '@angular/router';
import { generateQuestions } from 'utils/questions-generation';
import { ModeParams } from 'app/models/mode-params';
import { QUIZ_GRADES_NAMES } from '../../constants';
import { environment } from 'environments/environment';


enum Mode {
  Waiting = 0,
  Quiz = 1,
  Result = 2
};

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
  providers: [
    {
      provide: API_BASE_URL,
      useFactory: () => {
        return 'http://localhost:21021/';
//        return 'http://localhost:53417/';
        // return 'http://server03/quiz/'
        // return "http://192.168.1.40/quiz/" //Сургут
        // return "http://192.168.0.20/quiz/"
      }
    },
    QuizService, LocalStorageService]
})

@Injectable()
export class QuizComponent
  implements OnInit {
  autorID: number;
  firmaID = 1;
  workplaceID = 1;
  gradeParam = '';
  modeParam = '';
  questionsCount = 10;
  isResult = false;
  gradeName = '';
  error: any;
  quizes: any[];
  firmaDict: any[];
  examResultText = '';
  isExamPassed = false;
  quizResponse: QuizResponse = new QuizResponse(null);
  quiz: Quiz; // = new Quiz(null);
  quizID: number;
  modes = Mode;
  mode: Mode = Mode.Waiting; // 'quiz';
  testMode = environment.testMode;
  allAnswerSelected: boolean = false;
  selectedCount = 0;
  isOptionsDisabled = false;
  public ModeParams: typeof ModeParams = ModeParams;



  quizUrl: string;
  config: QuizConfig = {
    'allowBack': true,
    'allowReview': true,
    'autoMove': this.testMode === TestingModes.Simple,  // if true, it will move to next question automatically when answered.
    'duration': 0,  // indicates the time in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': true,
    'showPager': true,
    'theme': 'none'
  };


  done: boolean;
  result: boolean;

  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  isTrainingComplete = false;

  constructor(private quizService: QuizService,
      private localStorageService: LocalStorageService,
      private firmaService: FirmaService,
      private _route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const routeSubscrition = this._route.paramMap.subscribe((params: ParamMap) => {
      this.gradeParam = params.get('testId');
      this.modeParam = params.get('modeId');
    })
    // --------------------------------
    this.pager.index = 0;
    this.quizes = this.quizService.getAll();
    this.firmaDict = this.firmaService.getFirma();
    this.gradeName = QUIZ_GRADES_NAMES[this.gradeParam];

    // let curQuizUrl = this.localStorageService.get<string>('quizUrl');

    let curFirmaID = this.localStorageService.get<number>('curFirmaID');
    let curWorkPlaceID = this.localStorageService.get<number>('curWorkPlaceID');
    // let curAutorID = this.localStorageService.get<number>('curAutorID')
    // let curshowCorrectAnswer = this.localStorageService.get<number>('showCorrectAnswer');
    // var curShowCorrectAnswer = Quiz.arguments.showCorrectAnswer;

    this.firmaID = curFirmaID ? curFirmaID : 1;

    if (curWorkPlaceID) {
      this.workplaceID = curWorkPlaceID;
    }
    else {
      this.workplaceID = 0;
    }


    // if (curshowCorrectAnswer) {
    //   this.showCorrectAnswer = curshowCorrectAnswer;
    // }
    // else {
    //   this.showCorrectAnswer = 0;
    // }

    // this.quizUrl = this.quizes[this.workplaceID - 1].url
    //this.rewriteQuizURL(this.firmaID, this.workplaceID);

    this.loadQuiz(this.gradeParam, this.modeParam);


    //this.refreshByTime();
  }

  refreshByTime(): void {
    Observable.interval(5000)
      // .switchMap(() => mqMessageService.getMQMessage()//http.get('http://jsonplaceholder.typicode.com/users/')).map((data) => data.json())
      .subscribe((data) => {
        //  console.log(data);
        if (this.mode !== Mode.Quiz) {
          // window.location.reload();
          // this.loadQuiz(this.quizUrl);
        }
      });

  }

  firmaChanged(firmaID: number) {
    // this.quizUrl = this.quizes[this.workplaceID - 1].url + '&firmaId='+ firmaID;
    this.firmaID = firmaID;
    this.rewriteQuizURL(this.firmaID, this.workplaceID);

    this.localStorageService.set('curfirmaID', this.firmaID);
    // this.loadQuiz(this.quizUrl);

    //console.log(this.quiz.questions);
  }

  workplaceChanged(workplaceID: number) {
    this.workplaceID = workplaceID;
    this.rewriteQuizURL(this.firmaID, this.workplaceID);

    this.localStorageService.set('curWorkPlaceID', this.workplaceID);
    // this.loadQuiz(this.quizUrl)
      ;

    //console.log(this.quiz.questions);
  }

  rewriteQuizURL(firmaID: number, workplaceID: number) {
    this.quizUrl = this.quizes[workplaceID - 1].url + '&firmaId=' + this.firmaID;
  }

  finishTesting(): void {
    if (+this.modeParam === ModeParams.Exam) {
      const correctAnswersCount = this.quiz.questions.reduce((acc, elem) => {
        if (elem.options.some((el) => (el.selected && el.isAnswer))) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
      if (correctAnswersCount >= 9) {
        this.examResultText = 'Поздравляем, экзамен сдан!';
        this.isExamPassed = true;
      } else {
        this.examResultText = 'Экзамен не сдан';
      }
      this.mode = Mode.Result;
      this.isResult = true;
    } else {
      this.isTrainingComplete = true;
    }
  }

  loadQuiz(gradeParam: string, modeParam: string) {
    this.quizService
      .get(gradeParam)
      .subscribe(
      (res) => {
        // this.quizResponse = new QuizResponse(res);
        // this.quiz = this.quizResponse.quiz;

        if (res) {
          if (+modeParam !== ModeParams.Reading) {
            //console.log(res);
          this.quiz = new Quiz(res);
          if (+modeParam === ModeParams.Exam) {
            this.quiz.directionmodeid = ModeParams.Exam;
            this.quiz.questions = generateQuestions(this.quiz, this.questionsCount);
            //console.log(this.quiz.questions);

          }
          // if(res) this.quizID = res.id;

          this.pager.count = this.quiz.questions.length;

          this.mode = Mode.Quiz;
          } else {
            this.quiz = new Quiz(res);
          }
        }
        else {
          this.mode = Mode.Waiting;
        }
      },
      (err) => this.error = err); // Reach here if fails);
    // this.quizService.get(quizUrl)
    //                 .map(this.extractData)
    //                 .catch(this.handleError);

  }

  get isTest() {
    return this.quiz.directionmodeid === 1;
  }

  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  onStart() {
    this.quiz = new Quiz(null);
    // this.loadQuiz(this.quizUrl);
    this.pager.index = 0;
    // this.mode = Mode.Waiting;//'quiz';
    this.mode = Mode.Quiz;// 'quiz';
  }

  onSelect(question: Question, option: Option) {

    if (question.questionTypeId === 1) {
      question.options.forEach((x) => {
        if (x.id !== option.id) x.selected = false;
      });
    }

    // let selectedCount = 0;
    this.selectedCount = 0;
    this.quiz.questions.forEach((q) => {
      q.options.forEach((o) => {
        if (o.selected) this.selectedCount++;
      });
    })

    this.allAnswerSelected = this.selectedCount >= this.quiz.questions.length;
    if (this.allAnswerSelected) {
      this.finishTesting();
      return;
    }

    if (this.config.autoMove) {
      this.isOptionsDisabled = true;
      const timeOut = +this.modeParam === ModeParams.Training ? 3000 : 200;
      setTimeout(() => {
        this.goTo(this.pager.index + 1);
        this.isOptionsDisabled = false;
      }, timeOut);
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = Mode.Quiz;// 'quiz';
    }
  }

  public isReadingMode(): boolean {
    return +this.modeParam === ModeParams.Reading;
  }

  isAnswered(index) {
    return this.quiz.questions[index].options.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  isCorrect(question: Question) {
    return question.options.every(x => x.selected === x.isAnswer) ? 'правильный' : 'неправильный';
  };

  isCorrectcss(question: Question) {
    return question.options.every(x => x.selected === x.isAnswer) ? 'answered' : 'not-answered ';
  };

  onSubmit() {
    this.finishTesting();
    
//     this.quizService
//       .postAnswers(/*answers*/this.quiz)
//       .subscribe(
//       (res) => {
//         this.result = res;
//         this.done = true;
//         //   var numbers = Observable.timer(10000); // Call after 10 second.. Please set your time
//         //   numbers.subs`cribe(x =>{
//         //     alert("10 second");
//         //   });
// // Observable.interval(15000);
//         // this.postMQMessageExamFinish();

//         this.mode = Mode.Result;
//       });

    // this.mode = Mode.Result;

  }

  onReload() {
    this.quizService
      .postReload(/*answers*/this.quiz.id)
      .subscribe(
      (res) => {
        this.result = res;
        this.done = true;
        // this.loadQuiz(this.quizUrl);
        this.pager.index = 0;
        // this.postMQMessageExamFinish();
      });
  }

  // .subscribe(
  //   (res) => {
  //   // this.quizResponse = new QuizResponse(res);
  //   // this.quiz = this.quizResponse.quiz;
  //   this.quiz = new Quiz(res);
  //   this.pager.count = this.quiz.questions.length;

  //   (err) => this.error = err; // Reach here if fails
  // })
  // ;


  // console.log(`Хелло`);

  // 'quiz';
  // this.mode = 'result';


  // postMQMessageExamFinish() {
  //   let mqmsg = new MQMessage();
  //   mqmsg.channelName = "exam.finish";
  //   mqmsg.author = `${this.workplaceID}`;
  //   mqmsg.receiver = `${this.autorID}`

  // // mqmsg.author = `${this.firmaID}`;
  //   mqmsg.body = `${this.quiz.id}`;


  //   this.mqMessageService.postMQMessage(mqmsg).subscribe(data => {
  //     // process data
  //   }, error => {
  //     // process errors
  //   });

  //   // this.client.getData().subscribe(data => {
  //   // 	// process data
  //   // }, error => {
  //   // 	// process error
  //   // });


  // }

}
