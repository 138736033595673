// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

/*Testing Modes
Control - кнопки управления сохраняются, экзаменуемый регулирует как ему отвечать на вопросы
Simple - кнопки управления не сохраняются, осуществляется автоматический переход к следующему вопросу 
*/

export const environment = {
  production: false,
  testMode: 'Control',
};
