import { Quiz } from './quiz';

export class QuizResponse {
    message: string;
    didError: boolean;
    errorMessage: string;
    quiz: Quiz;

    constructor(data: any) {
        if (data) {
            this.message = data.message;
            this.didError = data.didError;
            this.errorMessage = data.errorMessage;
            this.quiz = new Quiz(data.model);
        }
    }
}
